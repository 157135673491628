import { MembershipSources } from '@Memberships';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SubscriptionSuccessModalDisplay } from './SubscriptionSuccessModal.display';

export function SubscriptionSuccessModal() {
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOnTrial = useSelector((state: RootReducerType) => state.membership.isOnTrial);
  const membershipSource = useSelector((state: RootReducerType) => state.membership.source);
  const isMembershipLoading = !membershipSource || membershipSource === MembershipSources.Loading;
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const expirationDate = new Date(legacyMembership?.expirationDate || '');
  const planTypeTitle = legacyMembership?.title || '';
  const currentDate = new Date();
  const endDate = new Date(expirationDate);
  const diffInTime = endDate.getTime() - currentDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
  const isTrialInfoVisible = Boolean(isOnTrial && diffInDays > 0);

  const onClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const primaryButtonAction = useCallback(() => {
    onClose();
    navigate('/');
  }, [navigate, onClose]);

  const description = isTrialInfoVisible
    ? 'Your payment information has been saved. Here’s what’s next:'
    : 'Your payment has been processed.';

  return (
    <SubscriptionSuccessModalDisplay
      description={description}
      isLoading={isMembershipLoading}
      isOpen={modalType === 'subscriptionSuccess'}
      isTrialInfoVisible={isTrialInfoVisible}
      primaryButtonAction={primaryButtonAction}
      primaryButtonText="LET'S GO!"
      subscriptionInformation={`Your ${planTypeTitle.toLocaleLowerCase()} plan will renew on
      ${expirationDate.toLocaleString('en-us', {
        month: 'long',
      })} ${expirationDate.getDate()}, ${expirationDate.getFullYear()}.`}
      title="Congratulations!"
      trialInformation={`${diffInDays} days`}
      onClose={onClose}
    />
  );
}
