import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Text, TextBold } from '@Cortex';
import { getDynamicMoodActivityPlayerPath } from '../../../../../../utils/getDynamicMoodActivityPlayerPath';
import { useDynamicMentalStateMoods } from '../../../../../../api/modules/DynamicMentalStateMoods';
import { RootReducerType } from '../../../../../../reducers';
import { TrackGenre } from '../TracksByGenres/components/TrackGenre';
import * as S from './styles';

export const Moods = () => {
  const { isLoading, error, data } = useDynamicMentalStateMoods();
  const navigate = useNavigate();
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );

  const handlePlayMoodClick = (mood: string) => {
    if (sessionDynamicActivity) {
      navigate(
        getDynamicMoodActivityPlayerPath({
          mood,
          dynamicMentalStateId: sessionDynamicActivity.mentalState.id,
        }),
      );
    }
  };

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <Text>{error.message}</Text>;
  }

  return (
    <>
      <S.Title>
        <TextBold size="1.25rem">Moods</TextBold>
      </S.Title>

      <S.Container>
        {data.map((mood, i) => {
          return (
            <S.MoodWrapper key={`${mood}_${i}`}>
              <TrackGenre icon="" name={mood} onClick={() => handlePlayMoodClick(mood)} />
            </S.MoodWrapper>
          );
        })}
      </S.Container>
    </>
  );
};
