import { NOOP } from '@Globals';
import { ProfileMenuItemUrlPaths, useMenuItems } from '@User';
import { useState } from 'react';

import { ProfileWidgetDisplay } from './ProfileWidget.display';
import { useWhatsNew } from '../../WhatsNew/lib/useWhatsNew';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { profileScreenLogEvent } from '../../../actions/analytics';

interface Props {
  onOpen?: () => void;
}

export const ProfileWidget = ({ onOpen = NOOP }: Props) => {
  const { hasNewUpdates } = useWhatsNew();
  const [isActive, setActive] = useState(false);
  const menuItems = useMenuItems();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProfileClick = () => {
    if (hasNewUpdates) {
      dispatch(profileScreenLogEvent('profile_whats_new'));
      navigate(ProfileMenuItemUrlPaths.WhatsNew);
      return;
    }

    setActive(!isActive);
    onOpen();
  };

  return (
    <ProfileWidgetDisplay
      hasNotification={hasNewUpdates}
      isActive={isActive}
      menuItems={menuItems}
      onClick={handleProfileClick}
      onOutsideClick={() => setActive(false)}
    />
  );
};
