import React, { useRef, useCallback } from 'react';
import { Transition } from 'react-transition-group';

import { useIsWindowFocused } from '../../../../hooks';
import { useStreaksAnimation } from '../../hooks/useStreaksAnimation';
import { AnimationContainer } from '../AnimationContainer';
import { Streaks } from '../Streaks';
import { TimerContainerDynamic } from '../TimerContainerDynamic';
import * as S from './FocusSectionDynamic.styles';

export const FocusSectionDynamic = () => {
  const isWindowFocused = useIsWindowFocused();
  const { showTimer, showStreakAnimation, setShowTimer, setShowStreakAnimation } =
    useStreaksAnimation();
  const streaksRef = useRef(null);
  const timerRef = useRef(null);

  const onExitedTimer = useCallback(() => {
    setShowStreakAnimation(true);
  }, [setShowStreakAnimation]);

  const onExitedStreaks = useCallback(() => {
    setShowTimer(true);
  }, [setShowStreakAnimation]);

  if (!isWindowFocused) return null;
  return (
    <S.Wrapper>
      <Transition
        in={showTimer}
        mountOnEnter
        nodeRef={timerRef}
        timeout={300}
        unmountOnExit
        onExited={onExitedTimer}
      >
        {state => (
          <AnimationContainer ref={timerRef} animationState={state}>
            <TimerContainerDynamic />
          </AnimationContainer>
        )}
      </Transition>
      <Transition
        in={showStreakAnimation}
        mountOnEnter
        nodeRef={streaksRef}
        timeout={300}
        unmountOnExit
        onExited={onExitedStreaks}
      >
        {state => (
          <AnimationContainer ref={streaksRef} animationState={state}>
            <Streaks />
          </AnimationContainer>
        )}
      </Transition>
    </S.Wrapper>
  );
};
