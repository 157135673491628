export const PROMO_QUERY_PARAM_KEY = 'extended_promo';
export const PROMO_QUERY_PARAM_VALUES = ['30', '60'];
export const DEFAULT_EXTENDED_YEARLY_TRIAL_PERIOD = '30';

export const DEFAULT_BENEFITS = [
  'Find your focus any time, any place',
  'Get more done and have fun doing it',
  'Diverse genres tailored for you',
  'Unlimited listening on web and mobile',
  'New sounds added every week',
];

export const DEFAULT_TRIAL_LENGTH = {
  yearly: 14,
  monthly: 7,
};

export const REVIEWS = [
  {
    id: 1,
    text: 'This app has changed my life.',
    author: 'J.C.',
  },
  {
    id: 2,
    text: 'This app is a true blue tool to leveling yourself up. Couldn’t recommend it more.',
    author: 'R.L.',
  },
  {
    id: 3,
    text: 'I’ve used Brain.fm to get through heavy amounts of deep work sessions. Brain.fm made it feel like a breeze. They do what they say they do.',
    author: 'F.G.',
  },
  {
    id: 4,
    text: 'Helps me focus and get more done! The first session I ever did with Brain.fm was incredible - I was way more focused than before.',
    author: 'P.M.',
  },
  {
    id: 5,
    text: 'It really works!!! OMG, I am incredibly focused and getting much better rest, which is a game-changer for an entrepreneur.',
    author: 'P.G.',
  },
];

export const ERROR_DISPLAY_VALUES: { [key: number]: string } = {
  409: 'You already have an active subscription.',
};
