import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { Serving, Track } from '@Model';
import { useNavigate } from 'react-router-dom';

import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../utils/getDynamicSimilarActivityPlayerPath';
import { getTrackName, getTrackVariationId, getTrackId } from '../../lenses';
import { RecommendedTrackDisplay } from './RecommendedTrack.display';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { addToFavorites, removeFromFavorites } from '../../actions';

type Props = {
  tracks: Serving[];
  onTrackClickMore: (track: Track | Serving) => void;
};

export const RecommendedTrack = ({ tracks, onTrackClickMore }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce(
    (acc, track) => {
      acc[getTrackId(track)] = track;
      return acc;
    },
    {} as { [key: string]: Track | Serving },
  );

  const handleClick = (track: Serving) => {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromRecommendedTrack, {
      trackId: getTrackId(track),
      trackVariationId: getTrackVariationId(track),
      trackName: getTrackName(track),
    } as any);

    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Recommended,
      trackName: getTrackName(track),
    });
  };

  return (
    <RecommendedTrackDisplay
      tracks={tracks.map(data => ({
        ...data,
        isFavorited: Boolean(favoritesDictionary[getTrackId(data)]),
        onAddToFavorites: () =>
          dispatch(addToFavorites({ origin: TrackActionOrigins.Explore, track: data })),
        onClickPlay: () => handleClick(data),
        onClickMore: () => onTrackClickMore(data),
        onRemoveFromFavorites: () => dispatch(removeFromFavorites({ track: data })),
      }))}
      onClick={handleClick}
    />
  );
};
