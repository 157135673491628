import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
  TextBold,
  Text,
  Icon,
  TextSemiBold,
} from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';
import Logo from '../../../../../../assets/images/brand_logo.svg';

import { EnhancedMembershipPlanType } from '../../../../../../types';
import { Assets } from '../../../../../../utils/assets';
import { Testimonials } from './components/Testimonials';
import * as S from './SingleScreenPaywall.styles';
import { Checkmark } from './components/Checkmark';
import CloseIcon from '../../../../../../assets/images/close_icon.svg';
import { OnboardingTestimonials } from '../../../../../Onboarding/types';
import { PlanCard } from './components/PlanCard';
import { CouponCode } from '@Payment';
import { GiftCard } from '../../../GiftCard';
import { useLocation, useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export interface Props {
  billingInterval?: string;
  billingName?: string;
  errorMessage?: string | null;
  onClose: () => void;
  onSubmit?: (value: string) => void;
  selectedPlan: EnhancedMembershipPlanType | null;
  transactionStatus?: PaymentStatus;
  children: ReactNode | undefined;
  paywallBenefits: string[];
  paywallTestimonials: OnboardingTestimonials[];
  plans: EnhancedMembershipPlanType[];
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  isExtendedPromo?: boolean;
}

export type PaymentStatus = 'idle' | 'loading' | 'success' | 'error';

export const SingleScreenPaywallDisplay: React.FC<Props> = ({
  billingName = '',
  children,
  errorMessage = null,
  onClose,
  onSubmit = NOOP,
  onPlanSelect,
  selectedPlan,
  transactionStatus = 'idle',
  paywallBenefits,
  paywallTestimonials,
  plans,
  isExtendedPromo = false,
}) => {
  const [couponSectionVisible, setCouponSectionVisible] = useState(false);
  const [nameInputFocused, setNameInputFocused] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const message = 'Are you sure you want to leave?';
      event.returnValue = message; // This message is not actually returned, but for chrome to show dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  let variantState = ButtonVariantStates.Idle;

  if (transactionStatus === 'loading') {
    variantState = ButtonVariantStates.Loading;
  } else if (transactionStatus === 'error') {
    variantState = ButtonVariantStates.Error;
  } else if (transactionStatus === 'success') {
    variantState = ButtonVariantStates.Success;
  }

  const hasDiscount = Boolean(
    (selectedPlan?.couponId || selectedPlan?.promotionCode) &&
      selectedPlan?.originalCost &&
      selectedPlan?.originalCost !== selectedPlan?.displayCost,
  );
  const discountPercentage =
    hasDiscount && selectedPlan?.originalCost && selectedPlan?.displayCost
      ? Math.round(
          (1 -
            (parseInt(selectedPlan?.displayCost) || 0) /
              (parseInt(selectedPlan?.originalCost) || 1)) *
            100,
        )
      : 0;

  const footnote = `*${selectedPlan?.description} All prices in USD. Applicable VAT, sales
  or other taxes may apply.${
    hasDiscount ? ' Discount applied at trial end.' : ''
  } Cancel your subscription at anytime by going to your
  Account Overview and click on the membership button.`;

  const shouldHideCloseButton = true;

  const shouldOverrideCloseButton = Boolean(location.state?.overrideCloseButton);
  const shouldRedirectBack = Boolean(location.state?.redirectBack);

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.HeaderSpacer />
        <S.HeaderIconContainer>
          <Icon size={56} src={Logo} />
        </S.HeaderIconContainer>
        {shouldHideCloseButton && !shouldOverrideCloseButton ? null : (
          <S.CloseIconContainer
            onClick={shouldRedirectBack ? () => navigate(location.state?.redirectBack) : onClose}
          >
            <S.CloseIcon src={CloseIcon} />
          </S.CloseIconContainer>
        )}
      </S.HeaderWrapper>

      <S.PaymentContainer>
        <S.LeftBoxContainer>
          <S.LeftBoxHeaderIconContainer>
            <Icon size={56} src={Logo} />
          </S.LeftBoxHeaderIconContainer>
          <S.Title size="3rem">
            {selectedPlan?.trialLength ? 'Start listening free' : 'Start listening'}
          </S.Title>
          <S.Checkmarks>
            {paywallBenefits.map(benefit => (
              <Checkmark key={benefit}>{benefit}</Checkmark>
            ))}
          </S.Checkmarks>

          <Testimonials paywallTestimonials={paywallTestimonials} />
          <S.ReviewsLeftBoxWrapper>
            <S.SubscriptionInfoText>{footnote}</S.SubscriptionInfoText>
          </S.ReviewsLeftBoxWrapper>
        </S.LeftBoxContainer>

        <S.RightBoxContainer>
          <form>
            <Formik
              initialValues={{ name: billingName }}
              validationSchema={validationSchema}
              onSubmit={values => onSubmit(values.name)}
            >
              {({ handleChange, handleSubmit, errors, touched, values }) => {
                const hasErrors = !!(touched.name && errors.name);

                return (
                  <>
                    <S.CardBox>
                      <S.CardHeader>
                        {plans.map(plan => (
                          <PlanCard
                            key={plan.id}
                            discountAmount={discountPercentage}
                            hasDiscount={hasDiscount}
                            isExtendedPromo={isExtendedPromo}
                            isSelected={selectedPlan?.id === plan.id}
                            plan={plan}
                            onClick={() => onPlanSelect(plan)}
                          />
                        ))}
                      </S.CardHeader>

                      <S.NameInputContainer>
                        <S.NameInputLabel htmlFor="billingName" isError={hasErrors}>
                          {hasErrors ? errors.name : 'Billing Name'}
                        </S.NameInputLabel>
                        <S.NameInput
                          data-testid="billingName"
                          id="billingName"
                          isFocused={nameInputFocused}
                          name="name"
                          placeholder="Billing Name"
                          value={values.name}
                          onBlur={() => setNameInputFocused(false)}
                          onChange={handleChange}
                          onFocus={() => setNameInputFocused(true)}
                        />
                      </S.NameInputContainer>

                      <S.NameInputLabel htmlFor="billingName">Card Information</S.NameInputLabel>
                      <S.CardContainer data-testid="cardContainer">{children}</S.CardContainer>
                      <S.BelowCardInfo>
                        <S.PoweredByImage src={Assets.images.poweredByStripe.url} />
                        <Checkmark customIcon={Assets.icons.locker.url}>
                          Guaranteed safe and secure checkout
                        </Checkmark>
                        <Checkmark customIcon={Assets.icons.guarantee.url}>
                          60-Day money back guarantee
                        </Checkmark>
                      </S.BelowCardInfo>

                      <S.StartContainer>
                        {selectedPlan?.trialLength ? (
                          <S.StartInfoContainer>
                            <S.PriceInfo
                              data-testid={
                                hasDiscount ? 'discountAdditionalInfo' : 'planAdditionalInfo'
                              }
                            >
                              <Text size="1rem">Due Today</Text>
                              <TextBold size="2rem">$0.00</TextBold>
                            </S.PriceInfo>
                            <S.YearlyPriceTextContainer>
                              <S.YearlyPriceText data-testid="trialLengthInfo">
                                {`After ${selectedPlan?.trialLength} days: $${
                                  hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price
                                }`}
                              </S.YearlyPriceText>
                            </S.YearlyPriceTextContainer>
                          </S.StartInfoContainer>
                        ) : (
                          <S.StartInfoContainer>
                            <S.PriceInfo
                              data-testid={
                                hasDiscount ? 'discountAdditionalInfo' : 'planAdditionalInfo'
                              }
                            >
                              <Text size="1rem">Price</Text>
                              <TextBold size="2rem">
                                ${hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price}
                              </TextBold>
                            </S.PriceInfo>
                          </S.StartInfoContainer>
                        )}
                        <S.ButtonContainer>
                          <Button
                            data-testid="handlePurchase"
                            isFullWidth
                            keepTextCase
                            style={{ padding: '0.2rem' }}
                            type="button"
                            variant={ButtonVariants.Secondary}
                            variantState={variantState}
                            onClick={() => handleSubmit()}
                          >
                            {isExtendedPromo && selectedPlan?.trialLength
                              ? `Get ${selectedPlan.trialLength} Days Free`
                              : selectedPlan?.trialLength
                                ? 'Save Payment Information'
                                : 'Start Subscription'}
                          </Button>
                        </S.ButtonContainer>
                      </S.StartContainer>

                      <S.CouponContainer>
                        {!couponSectionVisible ? (
                          <S.CouponText>
                            Have a gift card or coupon code?
                            <S.CouponButton
                              data-testid="displayCouponForm"
                              type="button"
                              onClick={() => setCouponSectionVisible(true)}
                            >
                              Click here
                            </S.CouponButton>
                          </S.CouponText>
                        ) : null}

                        {couponSectionVisible ? (
                          <S.CouponGiftCardContainer>
                            <S.CouponSection>
                              <S.CouponTextWrapper>
                                <TextSemiBold>Coupon Code</TextSemiBold>
                              </S.CouponTextWrapper>
                              <S.CouponSectionInputWrapper>
                                <CouponCode layout="modern" />
                              </S.CouponSectionInputWrapper>
                            </S.CouponSection>

                            <S.CouponSection>
                              <S.CouponTextWrapper>
                                <TextSemiBold>Gift Card</TextSemiBold>
                              </S.CouponTextWrapper>
                              <S.CouponSectionInputWrapper>
                                <GiftCard layout="modern" />
                              </S.CouponSectionInputWrapper>
                            </S.CouponSection>
                          </S.CouponGiftCardContainer>
                        ) : null}
                      </S.CouponContainer>
                    </S.CardBox>
                    {errorMessage ? (
                      <S.ErrorWrapper>
                        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
                      </S.ErrorWrapper>
                    ) : null}
                  </>
                );
              }}
            </Formik>
          </form>
          <S.ReviewsRightBoxWrapper>
            <S.SubscriptionInfoText>{footnote}</S.SubscriptionInfoText>
          </S.ReviewsRightBoxWrapper>
        </S.RightBoxContainer>
      </S.PaymentContainer>
    </S.Container>
  );
};
