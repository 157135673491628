import { CDN_URL } from '@Globals';

export const MentalStatesWavesData: {
  [key: string]: { primary: string; hover: string; description: string };
} = {
  focus: {
    primary: `${CDN_URL}/images/waves/focus_hover.webp`,
    hover: `${CDN_URL}/images/waves/focus.webp`,
    description: 'Beta Waves',
  },
  relax: {
    primary: `${CDN_URL}/images/waves/relax.webp`,
    hover: `${CDN_URL}/images/waves/relax_hover.webp`,
    description: 'Theta & Alpha Waves',
  },
  sleep: {
    primary: `${CDN_URL}/images/waves/sleep.webp`,
    hover: `${CDN_URL}/images/waves/sleep_hover.webp`,
    description: 'Delta Waves',
  },
  meditate: {
    primary: `${CDN_URL}/images/waves/meditate.webp`,
    hover: `${CDN_URL}/images/waves/meditate_hover.webp`,
    description: 'Theta & Alpha Waves',
  },
};
