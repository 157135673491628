import * as S from './FavoriteShuffleCard.styles';
import { RootReducerType } from '../../../../../../../../reducers';
import { useSelector } from 'react-redux';

export type Props = {
  mentalState: string;
  onClick: () => void;
};

export const FavoriteShuffleCard = ({ mentalState, onClick }: Props) => {
  const mentalStatePlayImageUrl = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.playImageUrl,
  );

  return (
    <S.Container onClick={onClick}>
      <S.InnerContainer>
        <S.TextContainer>
          <S.Title>Play All</S.Title>
          <S.Description>
            Start a listening session that will shuffle all your favorite{' '}
            <S.DescriptionBold>{mentalState}</S.DescriptionBold> tracks
          </S.Description>
          <S.Image src={mentalStatePlayImageUrl} />
        </S.TextContainer>
      </S.InnerContainer>
    </S.Container>
  );
};
