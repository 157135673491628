import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { Text } from '@Cortex';
import { NOOP } from '@Globals';
import { Serving, Track } from '@Model';
import {
  addToFavorites,
  getTrackId,
  getTrackName,
  removeFromFavorites,
  useApplyFiltersToTracks,
} from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTracksByGenre } from '../../../../../../../../api/modules/TracksByGenre';
import { RootReducerType } from '../../../../../../../../reducers';
import { Analytics } from '../../../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { getDynamicGenresActivityPlayerPath } from '../../../../../../../../utils/getDynamicGenresActivityPlayerPath';
import { TracksByGenreDisplay } from './TracksByGenre.display';
import * as S from './TracksByGenre.styles';

interface Props {
  genre: string;
  onGoBack?: () => void;
  onTrackClickMore: (track: Track | Serving) => void;
}

export const TracksByGenre = ({ genre, onGoBack = NOOP, onTrackClickMore }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );
  const { isLoading, error, data } = useTracksByGenre(genre);
  const filteredData = useApplyFiltersToTracks(data);

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <Text>{error.message}</Text>;
  }

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce(
    (acc, track) => {
      acc[getTrackId(track)] = track;
      return acc;
    },
    {} as { [key: string]: Track | Serving },
  );

  const handleClickPlay = (track: Track | Serving) => {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromExploreByGenre, {
      trackId: getTrackId(track),
      trackName: getTrackName(track),
    } as any);

    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Explore,
      trackName: getTrackName(track),
    });
  };

  const handlePlayAllClick = () => {
    if (sessionDynamicActivity) {
      navigate(
        getDynamicGenresActivityPlayerPath({
          genreName: genre,
          dynamicMentalStateId: sessionDynamicActivity.mentalState.id,
        }),
      );
    }
  };

  return (
    <TracksByGenreDisplay
      genreName={genre}
      shouldRenderPlayAllButton={true}
      tracks={filteredData.map(data => ({
        ...data,
        isFavorited: Boolean(favoritesDictionary[getTrackId(data)]),
        onAddToFavorites: () =>
          dispatch(addToFavorites({ origin: TrackActionOrigins.Explore, track: data })),
        onClickPlay: () => handleClickPlay(data),
        onClickMore: () => onTrackClickMore(data),
        onRemoveFromFavorites: () => dispatch(removeFromFavorites({ track: data })),
      }))}
      onClickGoBack={onGoBack}
      onPlayAllClick={handlePlayAllClick}
    />
  );
};
