import styled from 'styled-components';

import MoreIconSVG from './MoreIcon.svg?react';

export const MoreIcon = styled(MoreIconSVG)`
  stroke-width: 1.5;
  transition:
    transform 0.15s ease-in-out,
    fill-opacity 0.15s ease-in-out,
    stroke-width 0.15s ease-in-out;

  &:hover {
    stroke-width: 2;
    transform: scale(1.03);
  }
`;
