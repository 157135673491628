import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 7.75rem); // subtracts roughly all sibling components heights
`;

export const GenreContainer = styled.div<{ slide: boolean }>`
  width: 200%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  transform: ${({ slide }) => (slide ? 'translateX(-50%)' : 'translateX(0)')};
  transition: transform 0.3s ease-in-out;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 0.25rem;
  text-transform: lowercase;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const Page = styled.div<{ fadeIn: boolean }>`
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in;
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
`;

export const TopItemContainer = styled.div`
  margin-bottom: 0.75rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
`;

export const FadeInWrapper = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: calc(
    100% - 2.5rem - 2rem
  ); // roughly subtracts sibling component heights to fix end of scrollview
  animation: fadeInDown 0.3s ease-in-out;
  position: relative;
  visibility: ${({ animationState }) => (animationState == 'entered' ? 'visible' : 'hidden')};

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
