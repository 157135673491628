import { musicActions, Sort } from '@Music';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { useGenreNamesByDynamicMentalState } from '../../../../../../api/modules/GenreNamesByDynamicMentalState';

import { Filter } from '../../../Filter';
import {
  // useActivityFilterHandler,
  useGenreFilterHandler,
  useNeuralEffectFilterHandler,
} from '../../../Filter/hooks';
import { SearchBar, SearchResults } from '../../../SearchBar';
import * as S from './TracksByGenres.styles';
import { TrackGenres } from './components/TrackGenres';
import { TracksByGenre } from './components/TracksByGenre';
import { Moods } from '../Moods';
import { Serving, Track } from '@Model';

type Props = {
  onTrackClickMore: (track: Track | Serving) => void;
};

export const TracksByGenres = (props: Props) => {
  const [selectedGenre, setSelectedGenre] = useState<string | null>(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const { data: allGenres, isLoading } = useGenreNamesByDynamicMentalState();

  const dispatch = useDispatch();
  // TODO - Fix for DA.
  // const activityFilter = useActivityFilterHandler();
  const neuralEffectFilter = useNeuralEffectFilterHandler();
  const genreFilter = useGenreFilterHandler(allGenres);

  const handleGoBack = () => {
    setSelectedGenre(null);
  };

  const onClearSearch = () => {
    dispatch(musicActions.setSearchState({ results: null }));
  };

  const shouldSlide = selectedGenre !== null;
  const filters = [neuralEffectFilter, ...(searchEnabled ? [genreFilter] : [])];

  return (
    <S.Container>
      {!selectedGenre ? (
        <S.TopItemContainer>
          <SearchBar onClearSearch={onClearSearch} onSelect={setSearchEnabled} />
        </S.TopItemContainer>
      ) : null}

      {selectedGenre || searchEnabled ? (
        <S.FiltersContainer>
          <S.FilterWrapper>
            <Filter filters={filters} />
          </S.FilterWrapper>
          <S.FilterWrapper>
            <Sort />
          </S.FilterWrapper>
        </S.FiltersContainer>
      ) : null}

      <Transition in={searchEnabled} mountOnEnter nodeRef={searchRef} timeout={0} unmountOnExit>
        {animationState => (
          <S.FadeInWrapper ref={searchRef} animationState={animationState}>
            <SearchResults onTrackClickMore={props.onTrackClickMore} />
          </S.FadeInWrapper>
        )}
      </Transition>

      <Transition in={!searchEnabled} mountOnEnter nodeRef={searchRef} timeout={0} unmountOnExit>
        {animationState => (
          <S.FadeInWrapper ref={searchRef} animationState={animationState}>
            <S.GenreContainer slide={shouldSlide}>
              <S.Page fadeIn={!shouldSlide}>
                <S.ContentContainer>
                  <TrackGenres
                    genres={allGenres}
                    isLoading={isLoading}
                    onGenreSelect={setSelectedGenre}
                  />

                  <Moods />
                </S.ContentContainer>
              </S.Page>

              <S.Page fadeIn={shouldSlide}>
                {selectedGenre ? (
                  <TracksByGenre
                    genre={selectedGenre}
                    onGoBack={handleGoBack}
                    onTrackClickMore={props.onTrackClickMore}
                  />
                ) : null}
              </S.Page>
            </S.GenreContainer>
          </S.FadeInWrapper>
        )}
      </Transition>
    </S.Container>
  );
};
