import { NOOP } from '@Globals';
import { CardElement } from '@stripe/react-stripe-js';
import { DEFAULT_BENEFITS, REVIEWS } from '../../constants';
import { useCommonPaywallData } from '../../hooks/useCommonPaywallData';
import { useDynamicFontSize } from '../../hooks/useDynamicFontSize';
import { getDefaultCardElementStyles } from '../../lib/getDefaultCardElementStyles';
import { SingleScreenPaywallDisplay } from './SingleScreenPaywall.display';
import { OnboardingTestimonials } from '../../../../../Onboarding/types';
import { useIsExtendedPromoAvailable } from '../../../PaywallModal/hooks/useIsExtendedPromoAvailable';

interface Props {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete?: () => void;
  onClose?: () => void;
}

export const SingleScreenPaywall = ({
  onPaymentComplete = NOOP,
  onClose = NOOP,
  paywallBenefits = DEFAULT_BENEFITS,
  paywallTestimonials = REVIEWS,
}: Props) => {
  const fontSize = useDynamicFontSize();
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();

  const {
    errorMessage,
    selectedPlan,
    setSelectedPlan,
    plans,
    handlePayment,
    paymentStatus,
    defaultBillingName,
  } = useCommonPaywallData({ onPaymentComplete });

  return (
    <SingleScreenPaywallDisplay
      billingName={defaultBillingName}
      errorMessage={errorMessage}
      isExtendedPromo={isExtendedPromoAvailable}
      paywallBenefits={paywallBenefits}
      paywallTestimonials={paywallTestimonials}
      plans={plans}
      selectedPlan={selectedPlan}
      transactionStatus={paymentStatus}
      onClose={onClose}
      onPlanSelect={setSelectedPlan}
      onSubmit={handlePayment}
    >
      <CardElement options={{ ...getDefaultCardElementStyles(fontSize) }} />
    </SingleScreenPaywallDisplay>
  );
};
