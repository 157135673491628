import { ButtonVariantStates } from '@Cortex';
import { NOOP } from '@Globals';
import { CardElement } from '@stripe/react-stripe-js';
import { useRef, RefObject } from 'react';
import { EnhancedMembershipPlanType } from '../../../../../../types';
import { OnboardingTestimonials } from '../../../../../Onboarding/types';
import { getDefaultCardElementStyles } from '../../lib/getDefaultCardElementStyles';
import { SingleScreenSlideUpPaywallPaymentForm } from './components/PaymentFormStep/SingleScreenSlideUpPaywallPaymentForm.display';
import { SingleScreenSlideUpPaywallPlanSelectionDisplay } from './components/PlanSelectionStep/SingleScreenSlideUpPaywallPlanSelection.display';
import { Steps } from './SingleScreenSlideUpPaywall.container';
import * as S from './SingleScreenSlideUpPaywall.styles';

export interface Props {
  billingName?: string;
  currentStep: Steps;
  errorMessage?: string | null;
  fontSize: number;
  hasDiscount: boolean;
  discountPercentage: number;
  isExtendedPromo?: boolean;
  onBack: () => void;
  selectedPlan: EnhancedMembershipPlanType | null;
  variantState: ButtonVariantStates;
  onClose: () => void;
  paywallBenefits: string[];
  paywallTestimonials: OnboardingTestimonials[];
  plans: EnhancedMembershipPlanType[];
  footnote: string;
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  onHandlePayment?: (value: string) => void;
  onNavigateToPayment: () => void;
  paymentRef: RefObject<HTMLDivElement>;
}

export const SingleScreenSlideUpPaywallDisplay = (props: Props) => {
  const {
    billingName,
    currentStep,
    errorMessage,
    hasDiscount,
    discountPercentage,
    isExtendedPromo = false,
    fontSize,
    onBack,
    selectedPlan,
    variantState,
    onClose,
    paywallBenefits,
    paywallTestimonials,
    plans,
    footnote,
    paymentRef,
    onPlanSelect,
    onHandlePayment = NOOP,
    onNavigateToPayment,
  } = props;

  return (
    <S.Container>
      <S.Section isVisible={currentStep === Steps.PlanSelection}>
        <SingleScreenSlideUpPaywallPlanSelectionDisplay
          discountPercentage={discountPercentage}
          hasDiscount={hasDiscount}
          isExtendedPromo={isExtendedPromo}
          paywallBenefits={paywallBenefits}
          paywallTestimonials={paywallTestimonials}
          plans={plans}
          selectedPlan={selectedPlan}
          variantState={variantState}
          onClose={onClose}
          onPlanSelect={onPlanSelect}
          onSubmit={onNavigateToPayment}
        />
      </S.Section>
      <S.Section ref={paymentRef} isVisible={currentStep === Steps.PaymentForm}>
        <SingleScreenSlideUpPaywallPaymentForm
          billingName={billingName}
          errorMessage={errorMessage}
          footnote={footnote}
          hasDiscount={hasDiscount}
          selectedPlan={selectedPlan}
          variantState={variantState}
          onBack={onBack}
          onSubmit={onHandlePayment}
        >
          <CardElement options={{ ...getDefaultCardElementStyles(fontSize) }} />
        </SingleScreenSlideUpPaywallPaymentForm>
      </S.Section>
    </S.Container>
  );
};
