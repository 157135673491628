import { NavigateFunction } from 'react-router-dom';

import { Analytics } from '../../utils/analytics';
import { AnalyticsEvents } from '../../types';
import { RootReducerType } from '../../reducers';

type Params = {
  audioElement: Pick<HTMLAudioElement, 'paused'> | null;
  cohort: string;
  getCurrentTrackMetaData?: () => { [key: string]: string | boolean };
  shouldIncludeDebugInformation?: boolean;
  logger: (eventName: AnalyticsEvents, eventData: any) => void;
};

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export function createTrackListeningMinutes(params: Params): () => void {
  let timerDebugId = Date.now().toString(36);
  let lastCheck = Date.now();
  let lastPublish = Date.now();
  let timePausedInMS = 0;
  let sessionMinutesListened = 0;

  return function trackListeningMinutes() {
    const timeSinceLastCheck = Date.now() - lastCheck;
    const timeSinceLastPublish = Date.now() - lastPublish;
    const timeListeningInMS = timeSinceLastPublish - timePausedInMS;

    const isPaused = Boolean(params.audioElement?.paused);
    const hasListenedForFiveMinutes = timeListeningInMS >= FIVE_MINUTES_IN_MS;

    if (isPaused) {
      timePausedInMS += timeSinceLastCheck;
    } else if (hasListenedForFiveMinutes) {
      const mentalState = params.getCurrentTrackMetaData
        ? params.getCurrentTrackMetaData().mentalState
        : 'unknown';
      Analytics.incrementUserProperty(
        `poc_web_${mentalState}_minutes_listened_since_2024_01_05`.toLowerCase() as any,
        5,
      );
      Analytics.incrementUserProperty(`poc_web_minutes_listened_since_2024-01-05` as any, 5);

      sessionMinutesListened += 5;

      params.logger('poc_minutes_listened', {
        cohort: params.cohort,
        minutes: timeListeningInMS / 1000 / 60,
        poc_session_minutes_listened: sessionMinutesListened,
        ...(params.getCurrentTrackMetaData ? params.getCurrentTrackMetaData() : {}),
        ...(params.shouldIncludeDebugInformation
          ? {
              timeListeningInMS,
              timePausedInMS,
              timeSinceLastCheck,
              timeSinceLastPublish,
              timerDebugId,
            }
          : {}),
      });
      lastPublish = Date.now();
      timePausedInMS = 0;
    }

    lastCheck = Date.now();
  };
}

export function createListeningMinutesForPaywall({
  audioElement,
  navigate,
  userMembership,
  currentRoute,
}: {
  audioElement: Pick<HTMLAudioElement, 'paused'> | null;
  navigate: NavigateFunction;
  userMembership: RootReducerType['user']['membership'];
  currentRoute: string;
}): () => void {
  let lastCheck = Date.now();
  let lastPublish = Date.now();
  let timePausedInMS = 0;
  let sessionMinutesListened = 0;

  const isThreeDayTrial =
    userMembership?.type === 'trial' &&
    userMembership?.interval === 'Days' &&
    userMembership?.length === 3;

  if (!isThreeDayTrial) {
    return function () {};
  }

  return function trackListeningMinutes() {
    const timeSinceLastCheck = Date.now() - lastCheck;
    const timeSinceLastPublish = Date.now() - lastPublish;
    const timeListeningInMS = timeSinceLastPublish - timePausedInMS;

    const isPaused = Boolean(audioElement?.paused);
    const hasListenedForFiveMinutes = timeListeningInMS >= FIVE_MINUTES_IN_MS;

    if (isPaused) {
      timePausedInMS += timeSinceLastCheck;
    } else if (hasListenedForFiveMinutes) {
      sessionMinutesListened += 5;

      if (sessionMinutesListened % 30 === 0 && isThreeDayTrial) {
        Analytics.logEvent('show_paywall_30_minutes_listened');
        navigate('/payment', { state: { overrideCloseButton: true, redirectBack: currentRoute } });
      }

      lastPublish = Date.now();
      timePausedInMS = 0;
    }

    lastCheck = Date.now();
  };
}
