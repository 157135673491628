import styled from 'styled-components';

export const CallToActionWidget = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  gap: 1rem;
  padding: 0.4rem 0.4rem 0.4rem 0.8rem;
  border-radius: 76px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    30deg,
    rgba(60, 80, 120, 1) 0%,
    rgba(60, 80, 120, 1) 45%,
    rgba(126, 77, 140, 1) 100%
  );

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    justify-content: space-between;
    width: 100%;
  }
`;

export const Body = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const TrialLeftNumber = styled.span`
  background: rgba(255, 255, 255, 0.1);
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  border-radius: 4px;
  padding: 2px 5px;
`;
