import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useTracksByGenre = (genreName: string) => {
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );

  const args = {
    dynamicMentalStateId: dynamicMentalStateId as string,
    mentalStateId: undefined,
  };
  const getTracksByGenre = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getTracksByGenre(genreName, dynamicMentalStateId!),
    () => getTracksByGenre(genreName, args),
    {
      onError: () => {
        Logger.error(new Error('Error getting Tracks by Genre'));
      },
      enabled: Boolean(genreName) && Boolean(dynamicMentalStateId),
    },
  );
};
