import { createRef, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import * as uiActions from '../../actions/ui';
import { PaywallManager } from '../../domains/Payment/components/PaywallManager';
import { useAnalyticsPageView } from '../../hooks';
import useImagesPreloader from '../../hooks/useImagesPreloader';
import { Assets } from '../../utils/assets';
import { CoreAnalytics } from '@Analytics';
import { useDispatch } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface HistoryState {
  redirect: boolean;
  from?: string;
}

const ImagesToPreload = [Assets.images.subscriptionSuccess.url];

export const OnboardingPayment = () => {
  useImagesPreloader(ImagesToPreload);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const audioRef = createRef<HTMLMediaElement>();
  const onboardingMusicUrl = location.state?.onboardingMusicUrl;
  const paywallTestimonials = location.state?.paywallTestimonials;
  const paywallBenefits = location.state?.paywallBenefits;
  useAnalyticsPageView('subscription_screen_view', {
    subscribe_screen_from: (location.state as HistoryState)?.from,
  });

  useEffect(() => {
    if (audioRef.current && onboardingMusicUrl) {
      audioRef.current.volume = 0.4;
      audioRef.current.play();
    }
  }, [audioRef, onboardingMusicUrl]);

  const handleOnClose = useCallback(() => {
    if (location.state?.from === 'Onboarding') {
      dispatch(uiActions.setModal('firstSession'));
    }

    if ((location.state as HistoryState)?.redirect) {
      navigate('/welcome', {
        replace: true,
        state: {
          origin: 'subscription_screen',
          isInitial: true,
        },
      });
    } else {
      navigate('/', { replace: true });
    }

    CoreAnalytics.trackSubscriptionPlanLeaveEvent();
  }, [navigate, location]);

  return (
    <PaymentWrapper>
      <audio ref={audioRef} src={onboardingMusicUrl} />
      <Container>
        <PaywallManager
          paywallBenefits={paywallBenefits}
          paywallTestimonials={paywallTestimonials}
          onClose={handleOnClose}
          onPaymentComplete={handleOnClose}
        />
      </Container>
    </PaymentWrapper>
  );
};
