import styled from 'styled-components';
import TimerIconSVG from '../assets/timer.svg?react';
import IntervalIconSVG from '../assets/interval.svg?react';
import InfiniteIconSVG from '../assets/infinite.svg?react';
import { FontColors } from '@Cortex';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Information = styled.div`
  gap: 4px;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.WhiteTransparent};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
`;

const BaseIcon = styled.svg`
  height: 24px;
  width: 24px;
  fill: white;
  flex-shrink: 0;
`;

export const InfiniteIcon = styled(BaseIcon).attrs({ as: InfiniteIconSVG })``;

export const TimerIcon = styled(BaseIcon).attrs({ as: TimerIconSVG })``;

export const IntervalIcon = styled(BaseIcon).attrs({ as: IntervalIconSVG })``;
