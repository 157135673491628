import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const Item = styled.button`
  outline: none;
  border: none;
  background: none;

  flex: 1;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: relative;
  z-index: 2;

  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export const Pill = styled.div`
  position: absolute;
  z-index: 1;
  background: white;
  border-radius: 4.75rem;
  height: 100%;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
`;
