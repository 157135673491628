import { DynamicMentalState } from './DynamicMentalState';
import { Tag } from './Track';

export enum DynamicActivityTypes {
  Normal = 'normal',
}

export enum QueryTagInclusions {
  All = 'all',
  Any = 'any',
  None = 'none',
}

export type QueryTag = {
  id: string;
  dynamicPlaylistId: string;
  type:
    | Tag['type']
    | 'name'
    | 'isNewlyCreated'
    | 'mentalState.type'
    | 'mentalState.id'
    | 'dynamicMentalState.id';
  values: Tag['value'][];
  inclusion: QueryTagInclusions;
};

export type DynamicPlaylist = {
  id: string;
  title: string;
  order: number;
  queryTags: QueryTag[];
};

type DynamicActivityNormal = {
  id: string;
  displayValue: string;
  description: string;
  type: DynamicActivityTypes.Normal;
  playlists: [DynamicPlaylist];
  mentalState: Omit<
    DynamicMentalState,
    | 'defaultActivity'
    | 'yourFirstSessionActivity'
    | 'yourFirstSessionSkipActivity'
    | 'yourFirstSessionFavoritesActivity'
  >;
};

export type DynamicActivity = DynamicActivityNormal;
