import { Icon } from '@Cortex';
import { StreakTypes } from '@User';
import React from 'react';
import { useStreaks } from '../../../../../../hooks';
import { Assets } from '../../../../../../utils/assets';

import * as S from './StreaksWidgetLabel.styles';

type Props = {
  onClick: () => void;
};

export const StreaksWidgetLabel = ({ onClick }: Props) => {
  const { streaks, streakType } = useStreaks();
  if (streakType === StreakTypes.Hidden) return null;
  const value = streaks.data.weekly.currentCount;

  return (
    <S.Wrapper data-testid="streaks" onClick={onClick}>
      <Icon size={24} src={Assets.icons.rocket.url} />
      <S.Text>{`${value} week streak`}</S.Text>
    </S.Wrapper>
  );
};
