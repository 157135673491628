import { useEffect, useState } from 'react';

import { Assets } from '../../utils/assets';
import { Logger } from '../../utils/logger';
import { PomodoroPhases } from './constants';
import { usePomodoroPhase } from './usePomodoroPhase';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';

const CHIME = new Audio();
CHIME.src = Assets.sounds.chime.url;
CHIME.volume = 0.2;

const THREE_CHIMES = new Audio();
THREE_CHIMES.src = Assets.sounds.threeChimes.url;
THREE_CHIMES.volume = 0.5;

const VOICE_WORK_END = new Audio(Assets.sounds.voiceWorkEnd.url);
VOICE_WORK_END.volume = 0.2;

const VOICE_BREAK_END = new Audio(Assets.sounds.voiceBreakEnd.url);
VOICE_BREAK_END.volume = 0.5;

export function useChimeOnPomodoroPhaseChange() {
  const [hasMounted, setHasMounted] = useState(false);
  const intervalTimerBreakType = useSelector(
    (state: RootReducerType) => state.userV2.preferences.intervalTimerBreakType,
  );
  const pomodoroPhase = usePomodoroPhase();

  useEffect(() => {
    if (!hasMounted) {
      // don't play sound on mount
      setHasMounted(true);
      return;
    }
    if (!pomodoroPhase) return;

    try {
      if (pomodoroPhase === PomodoroPhases.BreakEnding) {
        if (intervalTimerBreakType === 'voice') {
          VOICE_BREAK_END.play().catch(e => {
            Logger.info('Custom Timer: VOICE_BREAK_END play() request was interrupted', {
              reason: e,
            });
          });
        } else {
          THREE_CHIMES.play().catch(e => {
            Logger.info('Custom Timer: three_chimes play() request was interrupted', { reason: e });
          });
        }
      }

      if (pomodoroPhase === PomodoroPhases.Break) {
        if (intervalTimerBreakType === 'voice') {
          VOICE_WORK_END.play().catch(e => {
            Logger.info('Custom Timer: VOICE_WORK_END play() request was interrupted', {
              reason: e,
            });
          });
        } else {
          CHIME.play().catch(e => {
            Logger.info('Custom Timer: chime play() request was interrupted', { reason: e });
          });
        }
      }
    } catch (error) {
      Logger.error(new Error('useChimeOnPomodoroPhaseChange(): unable to play sound'), {
        reason: error,
      });
    }
  }, [pomodoroPhase]);
}
