import { Button, ButtonVariants, ButtonVariantStates, Icon, TextSemiBold } from '@Cortex';
import { CouponCode } from '@Payment';
import React, { useState } from 'react';
import Logo from '../../../../../../../../assets/images/brand_logo.svg';

import { EnhancedMembershipPlanType } from '../../../../../../../../types';
import { GiftCard } from '../../../../../GiftCard';
import { Testimonials } from '../Testimonials/Testimonials';
import * as S from './SingleScreenSlideUpPaywallPlanSelection.styles';
import { Checkmark } from '../Checkmark/Checkmark';
import CloseIcon from '../../../../../../../../assets/images/close_icon.svg';
import { OnboardingTestimonials } from '../../../../../../../Onboarding/types';
import { PlanCard } from '../PlanCard/PlanCard';
import { useLocation, useNavigate } from 'react-router-dom';

export interface Props {
  billingInterval?: string;
  onClose: () => void;
  selectedPlan: EnhancedMembershipPlanType | null;
  hasDiscount: boolean;
  discountPercentage: number;
  paywallBenefits: string[];
  paywallTestimonials: OnboardingTestimonials[];
  plans: EnhancedMembershipPlanType[];
  variantState: ButtonVariantStates;
  isExtendedPromo?: boolean;
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  onSubmit: () => void;
}

export const SingleScreenSlideUpPaywallPlanSelectionDisplay: React.FC<Props> = ({
  onClose,
  onPlanSelect,
  selectedPlan,
  paywallBenefits,
  paywallTestimonials,
  plans,
  variantState,
  hasDiscount,
  discountPercentage,
  isExtendedPromo = false,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [couponSectionVisible, setCouponSectionVisible] = useState(false);

  const shouldOverrideCloseButton = Boolean(location.state?.overrideCloseButton);
  const shouldRedirectBack = Boolean(location.state?.redirectBack);

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.HeaderSpacer />
        <S.HeaderIconContainer>
          <Icon size={56} src={Logo} />
        </S.HeaderIconContainer>
        {!shouldOverrideCloseButton ? null : (
          <S.CloseIconContainer
            onClick={shouldRedirectBack ? () => navigate(location.state?.redirectBack) : onClose}
          >
            <S.CloseIcon src={CloseIcon} />
          </S.CloseIconContainer>
        )}
      </S.HeaderWrapper>

      <S.PaymentContainer>
        <S.LeftBoxContainer>
          <S.LeftBoxHeaderIconContainer>
            <Icon size={56} src={Logo} />
          </S.LeftBoxHeaderIconContainer>
          <S.Title size="3rem">
            {selectedPlan?.trialLength ? 'Start listening free' : 'Stay in the zone'}
          </S.Title>
          <S.Checkmarks>
            {paywallBenefits.map(benefit => (
              <Checkmark key={benefit}>{benefit}</Checkmark>
            ))}
          </S.Checkmarks>

          <Testimonials paywallTestimonials={paywallTestimonials} />
        </S.LeftBoxContainer>

        <S.RightBoxContainer>
          <S.CardHeader>
            {plans.map(plan => (
              <PlanCard
                key={plan.id}
                discountPercentage={discountPercentage}
                hasDiscount={hasDiscount}
                isExtendedPromo={isExtendedPromo}
                isSelected={selectedPlan?.id === plan.id}
                plan={plan}
                onClick={() => onPlanSelect(plan)}
              />
            ))}
          </S.CardHeader>
          <S.ButtonContainer>
            <Button
              data-testid="handlePurchase"
              isFullWidth
              keepTextCase
              type="button"
              variant={ButtonVariants.Secondary}
              variantState={variantState}
              onClick={onSubmit}
            >
              {isExtendedPromo && selectedPlan?.trialLength
                ? `Get ${selectedPlan.trialLength} Days Free`
                : selectedPlan?.trialLength
                  ? 'Try for free'
                  : 'Select Plan'}
            </Button>
          </S.ButtonContainer>
          <S.CouponContainer>
            {!couponSectionVisible ? (
              <S.CouponText>
                Have a gift card or coupon code?
                <S.CouponButton
                  data-testid="displayCouponForm"
                  type="button"
                  onClick={() => setCouponSectionVisible(true)}
                >
                  Click here
                </S.CouponButton>
              </S.CouponText>
            ) : null}

            {couponSectionVisible ? (
              <S.CouponGiftCardContainer>
                <S.CouponSection>
                  <S.CouponTextWrapper>
                    <TextSemiBold>Coupon Code</TextSemiBold>
                  </S.CouponTextWrapper>
                  <S.CouponSectionInputWrapper>
                    <CouponCode layout="modern" />
                  </S.CouponSectionInputWrapper>
                </S.CouponSection>

                <S.CouponSection>
                  <S.CouponTextWrapper>
                    <TextSemiBold>Gift Card</TextSemiBold>
                  </S.CouponTextWrapper>
                  <S.CouponSectionInputWrapper>
                    <GiftCard layout="modern" />
                  </S.CouponSectionInputWrapper>
                </S.CouponSection>
              </S.CouponGiftCardContainer>
            ) : null}
          </S.CouponContainer>
        </S.RightBoxContainer>
      </S.PaymentContainer>
    </S.Container>
  );
};
