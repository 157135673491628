import { Panel } from '@Cortex';
import {
  CancelMembership,
  MembershipPanel,
  useIsLifetimeUser,
  useIsTrialUser,
  useIsGiftSubscription,
} from '@Memberships';
import { BillingPanel, UpdateStripePaymentMethodPanel } from '@Payment';
import { ProfileInfo, EditProfile, UpdatePassword, DeleteAccount } from '@User';
import { Elements } from '@stripe/react-stripe-js';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { logEventWithProperties } from '../../../actions/analytics';
import { useIsExpired } from '../../../domains/Memberships/lenses/isExpired';
import { TeamInfo } from '../../../domains/User/Profile/TeamInfo';
import { useModalCloseOnNavigation } from '../../../hooks';
import { useTeamSubscriptionInfo } from '../../../hooks/useHasTeamSubscription';
import { useTeams } from '../../../hooks/useTeams';
import { RootReducerType } from '../../../reducers';
import stripe, { ELEMENT_OPTIONS } from '../../../utils/stripe';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin-bottom: 3rem;
  width: 100%;
`;

const SectionTitle = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
  width: 100%;
`;

const MainTitle = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  width: 100%;
`;

// Temporary implementation of modal within modal
const FloatingWindow = styled.div<{ animationState: TransitionStatus }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out,
    background 0.3s ease-in-out 0.05s;

  ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return css`
          opacity: 0;
          transform: translateY(-0.75rem);
          background: transparent;
        `;
      case 'entered':
        return css`
          opacity: 1;
          transform: translateY(0);
          background: rgba(0, 0, 0, 0.6);
        `;
      case 'exiting':
        return css`
          opacity: 0;
          transform: translateY(0.75rem);
          background: transparent;
        `;
      case 'exited':
        return css`
          opacity: 0;
          transform: translateY(2.75rem);
          background: transparent;
        `;
    }
  }};
`;

export const SplitWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    gap: 0;
    flex-direction: column;
  }
`;
export const SplitSection = styled.div<{ width?: string }>`
  flex-shrink: 0;
  flex-grow: 0;
  width: ${({ width }) => width || 'calc(50% - 1rem)'};
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 100%;
  }
`;

type ProfileScreenType =
  | 'deleteAccount'
  | 'editProfile'
  | 'passwordChange'
  | 'updatePaymentMethod'
  | 'cancelPayment';

const Profile = () => {
  useModalCloseOnNavigation();
  const dispatch = useDispatch();
  const isTrialUser = useIsTrialUser();
  const isLifetimeUser = useIsLifetimeUser();
  const isGiftSubscription = useIsGiftSubscription();
  const { hasTeamSubscription } = useTeamSubscriptionInfo();
  const isExpired = useIsExpired();
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);

  const hasIndividualSubscriptionSection =
    !hasTeamSubscription ||
    (hasTeamSubscription && !isTrialUser && (!isExpired || !legacyMembership?.isCancelled));

  const hasPaymentSection =
    !isTrialUser && !isLifetimeUser && !isGiftSubscription && hasIndividualSubscriptionSection;

  const editProfileFormRef = useRef<HTMLDivElement>(null);
  const changePasswordFormRef = useRef<HTMLDivElement>(null);
  const changePaymentFormRef = useRef<HTMLDivElement>(null);
  const cancelPaymentRef = useRef<HTMLDivElement>(null);
  const deleteAccountFormRef = useRef<HTMLDivElement>(null);

  const [profileScreen, setProfileScreen] = useState<ProfileScreenType | undefined>(undefined);
  const { list: teamsList } = useTeams();

  const handleCancelSubscription = () => {
    dispatch(
      logEventWithProperties({
        event: 'subscription_cancel',
        props: { plan_type: legacyMembership?.title },
      }),
    );
    setProfileScreen('cancelPayment');
  };

  function openPaymentDetails() {
    setProfileScreen('updatePaymentMethod');
  }

  return (
    <>
      <Container>
        <MainTitle>Account</MainTitle>
        {hasIndividualSubscriptionSection && (
          <>
            <SectionTitle>Subscription</SectionTitle>
            <SectionWrapper>
              <Panel padding="0">
                <MembershipPanel onClickUpdatePaymentDetails={openPaymentDetails} />
              </Panel>
            </SectionWrapper>
          </>
        )}

        {teamsList.length ? (
          <>
            <SectionTitle>Team Subscription</SectionTitle>
            <SectionWrapper>
              <Panel padding="0">
                <TeamInfo />
              </Panel>
            </SectionWrapper>
          </>
        ) : null}
        <SplitWrapper>
          <SplitSection>
            <SectionTitle>Profile</SectionTitle>
            <SectionWrapper>
              <Panel>
                <ProfileInfo
                  onEditProfile={() => setProfileScreen('editProfile')}
                  onPasswordChange={() => setProfileScreen('passwordChange')}
                />
              </Panel>
            </SectionWrapper>
          </SplitSection>
          {hasPaymentSection ? (
            <SplitSection>
              <SectionTitle>Payment</SectionTitle>
              <SectionWrapper>
                <Panel>
                  <BillingPanel
                    onClickCancel={handleCancelSubscription}
                    onPaymentMethodChange={openPaymentDetails}
                  />
                </Panel>
              </SectionWrapper>
            </SplitSection>
          ) : null}
        </SplitWrapper>
      </Container>

      <Transition
        in={profileScreen === 'passwordChange'}
        mountOnEnter
        nodeRef={changePasswordFormRef}
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <FloatingWindow ref={changePasswordFormRef} animationState={state}>
              <UpdatePassword onClose={() => setProfileScreen(undefined)} />
            </FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={profileScreen === 'deleteAccount'}
        mountOnEnter
        nodeRef={deleteAccountFormRef}
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <FloatingWindow ref={deleteAccountFormRef} animationState={state}>
              <DeleteAccount onClose={() => setProfileScreen(undefined)} />
            </FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={profileScreen === 'editProfile'}
        mountOnEnter
        nodeRef={editProfileFormRef}
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <FloatingWindow ref={editProfileFormRef} animationState={state}>
              <EditProfile
                onClose={() => setProfileScreen(undefined)}
                onDeleteAccount={() => setProfileScreen('deleteAccount')}
              />
            </FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={profileScreen === 'updatePaymentMethod'}
        mountOnEnter
        nodeRef={changePaymentFormRef}
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <FloatingWindow ref={changePaymentFormRef} animationState={state}>
              <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
                <UpdateStripePaymentMethodPanel onClose={() => setProfileScreen(undefined)} />
              </Elements>
            </FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={profileScreen === 'cancelPayment'}
        mountOnEnter
        nodeRef={changePaymentFormRef}
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <FloatingWindow ref={cancelPaymentRef} animationState={state}>
              <CancelMembership onClose={() => setProfileScreen(undefined)} />
            </FloatingWindow>
          );
        }}
      </Transition>
    </>
  );
};

export default Profile;
