import { PROFILE_ROUTE } from '@User';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { StreaksWidgetLabel } from '../StreaksWidgetLabel';
import { StreaksWidgetModal } from '../StreaksWidgetModal';
import { TrackControls } from '../TrackControls';
import { TrackInformation } from '../TrackInformation';
import { MoreTrackInfo } from '../TrackInformation/components/MoreTrackInfo';
import { VolumeControl } from '../VolumeControl';
import * as S from './AudioControls.styles';

export const AudioControls = () => {
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const location = useLocation();
  const isProfileScreen = location.pathname.match(PROFILE_ROUTE) ? true : false;
  const [trackDetailsExpanded, setTrackDetailsExpanded] = useState(false);
  const [streaksDetailsExpanded, setStreaksDetailsExpanded] = useState(false);

  // hide the player in profile screen if there are no sessions started.
  if (!currentTrack && isProfileScreen) return null;

  function handleSetTrackDetailsExpanded(isExpanded: boolean): void {
    setTrackDetailsExpanded(isExpanded);
    Analytics.logEventWithProperties(ExploreEvents.toggle_track_card_expansion__player, {
      type: isExpanded ? 'close' : 'open',
    } as any);
  }

  return (
    <>
      <S.StyledAudioControlBar isProfileScreen={isProfileScreen}>
        <S.ControlLayout>
          <S.TrackInformationColumn fade={trackDetailsExpanded}>
            <TrackInformation
              isDislikingDisabled={false}
              isFavoritingDisabled={false}
              onClick={() => handleSetTrackDetailsExpanded(true)}
            />
          </S.TrackInformationColumn>

          <S.PlayerControlsColumn>
            <TrackControls />
          </S.PlayerControlsColumn>

          <S.VolumeColumn>
            <S.StreaksWrapper>
              <StreaksWidgetLabel onClick={() => setStreaksDetailsExpanded(true)} />
            </S.StreaksWrapper>
            <VolumeControl />
          </S.VolumeColumn>
        </S.ControlLayout>
      </S.StyledAudioControlBar>

      {currentTrack && trackDetailsExpanded ? (
        <MoreTrackInfo track={currentTrack} onClose={() => handleSetTrackDetailsExpanded(false)} />
      ) : null}
      <StreaksWidgetModal isActive={streaksDetailsExpanded} setActive={setStreaksDetailsExpanded} />
    </>
  );
};
