import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { Analytics } from '../../utils/analytics';

export enum FeatureFlags {
  Onboarding = 'onboarding',
  ExpirationModal = 'expiration-modal',
  MentalStatesWaves = 'mental-states-waves',
  PaywallVariation = 'ab_paywall_variation',
  RecommendedActivities = 'recommended-activities',
}

type FFOptions = {
  setAnalyticsUserProperty: boolean;
};

export function useFeatureFlag<T = boolean>(flag: FeatureFlags, options?: FFOptions): T {
  const flags = useFlags();

  useEffect(() => {
    if (options && options.setAnalyticsUserProperty) {
      Analytics.setUserProperties({
        [`web_${flag}`]: flags[flag],
      });
    }
  }, [flag, flags[flag]]);

  return flags[flag];
}
