import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useRecentTracks = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );

  const args = {
    dynamicMentalStateId: dynamicMentalStateId as string,
    mentalStateId: undefined,
  };

  const getRecentTracks = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getRecentTracks(userId!, dynamicMentalStateId!),
    () => getRecentTracks(userId!, args),
    {
      onError: err => {
        if (isOfflineError(err)) return;
        Logger.error(new Error('Error getting Recent Tracks'), {
          err,
          userId,
          dynamicMentalStateId,
        });
      },
      enabled: Boolean(userId) && Boolean(dynamicMentalStateId),
    },
  );
};
