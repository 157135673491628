import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';
import { isOfflineError } from '../../../utils/isOfflineError';

export const useRecommendedTrack = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const getRecommendedTrack = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getRecommendedTrack(userId!),
    () => getRecommendedTrack(userId!),
    {
      onError: error => {
        if (isOfflineError(error)) return;
        Logger.error(new Error('Error fetching recommended tracks.'), { reason: error });
      },
      cacheTime: 0,
    },
  );
};
