import { Serving, Track } from '@Model';

import { ExploreEvents } from '../../../../../../../../utils/analytics/events';
import * as S from './TracksByGenre.styles';
import ArrowLeftIcon from './assets/arrow_left.svg';
import {
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  TrackInformationCard,
} from '@Music';
import { GenrePlayAllCard } from '../GenrePlayAllCard';
import { getTrackRelatedActivities } from '../../../../../../lenses/getTrackRelatedActivities';

interface Props {
  genreName: string;
  onClickGoBack: () => void;
  onPlayAllClick: () => void;
  shouldRenderPlayAllButton: boolean;
  tracks: ((Track | Serving) & {
    isFavorited: boolean;
    onAddToFavorites: () => void;
    onClickPlay: () => void;
    onClickMore: () => void;
    onRemoveFromFavorites: () => void;
  })[];
}

export const TracksByGenreDisplay = (props: Props) => (
  <S.Container>
    {props.shouldRenderPlayAllButton ? (
      <S.PlayAllContainer>
        <GenrePlayAllCard genreType={props.genreName} onClick={props.onPlayAllClick} />
      </S.PlayAllContainer>
    ) : null}
    <S.Header>
      <S.ImageContainer onClick={props.onClickGoBack}>
        <S.Image src={ArrowLeftIcon} />
      </S.ImageContainer>
      <S.Text>{props.genreName}</S.Text>
    </S.Header>

    {props.tracks?.map(track => (
      <S.TrackCardWrapper key={getTrackId(track)}>
        <TrackInformationCard
          hasMultipleNELs={getTrackHasMultipleNELs(track)}
          imageUrl={getTrackImageUrl(track)}
          isFavorited={track.isFavorited}
          isNewlyCreated={getTrackIsNewlyCreated(track)}
          moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
          neuralEffectLevel={getTrackNeuralEffectLevel(track)}
          relatedActivities={getTrackRelatedActivities(track)}
          subtitle={getTrackGenreName(track)}
          title={getTrackName(track)}
          onAddToFavorites={track.onAddToFavorites}
          onClick={track.onClickPlay}
          onClickMore={track.onClickMore}
          onRemoveFromFavorites={track.onRemoveFromFavorites}
        />
      </S.TrackCardWrapper>
    ))}
  </S.Container>
);
