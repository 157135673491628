import { StreakTypes } from '@User';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useStreaks } from '../../../../../../hooks';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { Events } from '../../../../../../utils/analytics/events';
import { Logger } from '../../../../../../utils/logger';
import { StreaksWidgetModalDisplay } from './StreaksWidgetModal.display';
import { SHARE_TEXT } from './constants';

type Props = {
  isActive: boolean;
  setActive: (isActive: boolean) => void;
};

export const StreaksWidgetModal = (props: Props) => {
  const { isActive, setActive } = props;
  const [isCopied, setIsCopies] = useState(false);
  const { streaks, streakType } = useStreaks();
  const mentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );

  const handleClickOutside = useCallback(() => {
    setActive(false);
  }, [setActive]);

  const handleShare = useCallback(async () => {
    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(SHARE_TEXT(streaks.data.weekly.currentCount));
        Analytics.logEventWithProperties(Events.core_streak_share, {
          core_streak_weekly_count_current: streaks.data.weekly.currentCount,
          mental_state: mentalStateDisplayValue,
        });
        setIsCopies(true);
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy share text to clipboard'), { reason: error });
    } finally {
      setTimeout(() => {
        setIsCopies(false);
      }, 1500);
    }
  }, [streaks.data.weekly.currentCount, setIsCopies, mentalStateDisplayValue]);

  if (streakType === StreakTypes.Hidden) return null;

  return (
    <StreaksWidgetModalDisplay
      isActive={isActive}
      isCopied={isCopied}
      streaks={streaks}
      onOutsideClick={handleClickOutside}
      onShare={handleShare}
    />
  );
};
