import { Icon, CloseButton, Button, ButtonVariantSizes } from '@Cortex';
import { NOOP } from '@Globals';
import { OutsideClickDetector } from '@Utils';
import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { StreaksType } from '../../../../../../types';
import { Assets } from '../../../../../../utils/assets';
import { Variants } from '../../../../../Cortex/components/Button/Button';
import { StreakInfo } from './components/StreakInfo';
import * as S from './StreaksWidgetModal.styles';

interface Props {
  isActive?: boolean;
  isCopied: boolean;
  onOutsideClick?: () => void;
  onShare: () => void;
  streaks: StreaksType;
}

export const StreaksWidgetModalDisplay = ({
  onOutsideClick = NOOP,
  streaks,
  onShare,
  isActive = false,
  isCopied = false,
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const {
    data: { weekly },
  } = streaks;

  return (
    <S.Container data-testid="streaksWidget">
      <OutsideClickDetector onOutsideClick={onOutsideClick}>
        <Transition in={isActive} mountOnEnter nodeRef={menuRef} timeout={0} unmountOnExit>
          {state => {
            return (
              <S.MenuContainer ref={menuRef} animationState={state}>
                <S.ContentWrapper>
                  <S.HeaderWrapper>
                    <S.HeaderText>
                      <Icon size={20} src={Assets.icons.rocket.url} />
                      <div>Streak Stats</div>
                    </S.HeaderText>
                    <CloseButton onClick={onOutsideClick} />
                  </S.HeaderWrapper>

                  <S.StreaksWrapper>
                    <StreakInfo title="CURRENT" value={weekly.currentCount} />
                    <S.VerticalSeparator />
                    <StreakInfo title="LONGEST" value={weekly.longestCount} />
                  </S.StreaksWrapper>
                  <S.ContentSection>
                    <S.DescriptionText>
                      Streaks measured in consecutive weeks listened.
                    </S.DescriptionText>
                    <S.Separator />
                  </S.ContentSection>

                  <S.ContentSection>
                    <S.Share>Share your achievements!</S.Share>
                    <Button
                      data-testid="copyStreaksButton"
                      isFullWidth={true}
                      keepTextCase={true}
                      size={ButtonVariantSizes.Small}
                      variant={Variants.Transparent}
                      onClick={onShare}
                    >
                      <S.ButtonContent>
                        <img alt={Assets.icons.link.alt} src={Assets.icons.link.url} width="18px" />
                        {isCopied ? (
                          <S.ButtonText>Copied!</S.ButtonText>
                        ) : (
                          <S.ButtonText>Copy</S.ButtonText>
                        )}
                      </S.ButtonContent>
                    </Button>
                  </S.ContentSection>
                </S.ContentWrapper>
              </S.MenuContainer>
            );
          }}
        </Transition>
      </OutsideClickDetector>
    </S.Container>
  );
};
