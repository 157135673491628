import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '@Globals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootReducerType } from '../../reducers';
import { getDeviceType } from '../../utils/getDeviceType';
import { NoMissingKeys, useFeatureFlagsState } from '@Utils';
import { setSessionSidebarContent } from '../../actions/ui';
import { ExplorePanelTab, SessionSidebarContent, SessionType } from '../../types';
import { uiSliceActions } from '../../reducers/uiReducer';
import { getDynamicActivityPlayerPath } from '../../utils/getDynamicActivityPlayerPath';
import { useDynamicMentalStates } from '../../api/modules/DynamicMentalStates';

type ShowFavoritesIntentionParams = {
  activityType: 'dynamic' | 'legacy' | null;
  mentalState: string;
};

export function IntentionsShowFavoritesHandler() {
  redirectMobileUsersToDownloadTheApp();

  const params = useShowFavoritesParams();
  useHandlePlayerAction(params);

  // this is rendered as a component so return null to avoid displaying anything
  return null;
}

function redirectMobileUsersToDownloadTheApp(): void {
  const { isMobile, isIos } = getDeviceType();
  if (!isMobile) return;

  window.location.replace(isIos ? IOS_DOWNLOAD_LINK : ANDROID_DOWNLOAD_LINK);
}

function useShowFavoritesParams(): ShowFavoritesIntentionParams {
  const { isSettled: isFeatureFlagsReceived } = useFeatureFlagsState();

  const path = window.location.pathname;

  const activityType = isFeatureFlagsReceived ? 'dynamic' : null;

  const mentalState = getMentalStateFromPath(path);

  return useMemo(
    () => ({
      activityType,
      mentalState,
    }),
    [activityType, mentalState],
  );
}

function getMentalStateFromPath(path: string): string {
  if (path.includes('relax')) {
    return 'relax';
  } else if (path.includes('sleep')) {
    return 'sleep';
  } else if (path.includes('meditate')) {
    return 'meditate';
  } else {
    return 'focus';
  }
}

function useHandlePlayerAction(params: ShowFavoritesIntentionParams): void {
  const { activityType, mentalState } = params;

  const [hasBeenActivated, setHasBeenActivated] = useState(false);
  const { status: appStatus } = useSelector((state: RootReducerType) => state.app);
  const showFavorites = useShowFavorites(activityType);

  return useEffect(() => {
    const shouldActivate = activityType !== null && appStatus === 'ready' && !hasBeenActivated;
    if (!shouldActivate) return;

    setHasBeenActivated(true);

    showFavorites({ activityType, mentalState });
  }, [appStatus, activityType]);
}

function useShowFavorites(activityType: null | 'dynamic' | 'legacy') {
  const startDynamicFavorite = useShowDynamicFavorite();

  return useCallback(
    function showFavorites(params: NoMissingKeys<ShowFavoritesIntentionParams>) {
      switch (activityType) {
        case 'dynamic':
          startDynamicFavorite;
          break;
        default:
          break;
      }
    },
    [activityType, startDynamicFavorite],
  );
}

function useShowDynamicFavorite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: dynamicMentalStates, isFetched } = useDynamicMentalStates();
  const params = useShowFavoritesParams();

  useEffect(() => {
    if (isFetched) {
      const dynamicActivityId =
        dynamicMentalStates?.find(mentalState => mentalState.id === params.mentalState)
          ?.defaultActivity.id || '';

      navigate(getDynamicActivityPlayerPath(dynamicActivityId));
      dispatch(
        setSessionSidebarContent({
          sessionSidebarContent: SessionSidebarContent.ExploreMusic,
          origin: 'deep_link',
        }),
      );
      dispatch(uiSliceActions.setExploreModalTab(ExplorePanelTab.Favorites));
    }
  }, [dispatch, navigate, dynamicMentalStates, isFetched]);
}
