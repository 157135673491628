import { showToast } from '@Utils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import { useCreateFastSubscription } from '../../../../api/modules/CreateFastSubscription';
import { useFastSubscriptionInfo } from '../../../../api/modules/FastSubscriptionData';
import { RootReducerType } from '../../../../reducers';
import { FastSubscriptionModalPresentation } from './FastSubscriptionModal.presentation';
import { getIsImmediateCharge } from './utils/getIsImmediateCharge';

export function FastSubscriptionModal() {
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = modalType === 'fastSubscription';
  const {
    mutate: handleCreateSubscription,
    isLoading: isCreating,
    isError,
    isSuccess,
  } = useCreateFastSubscription();
  const { data, isLoading } = useFastSubscriptionInfo(isOpen);

  useEffect(() => {
    const isFailed = isOpen && isError;
    if (isFailed) {
      onClose();
      navigate('/payment');
      showToast('Something went wrong, contact support if the error continues', { type: 'error' });
    }
  }, [isError, isOpen, navigate]);

  useEffect(() => {
    const isPerformed = isOpen && isSuccess;
    if (isPerformed) {
      onClose();
    }
  }, [isSuccess, isOpen]);

  const onClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const onNavigateToPaywall = useCallback(() => {
    onClose();
    navigate('/payment');
  }, [navigate, onClose]);

  const primaryButtonAction = useCallback(() => {
    handleCreateSubscription();
  }, [navigate, onClose, handleCreateSubscription]);

  const isImmediateCharge = getIsImmediateCharge(data?.lastSubscriptionInfo?.currentPeriodEnd);
  const isFullPaywallAvailable = isImmediateCharge;

  const title = isImmediateCharge ? 'Renew your membership' : 'Keep your access';
  const ctaText = isImmediateCharge ? 'RENEW MY MEMBERSHIP' : 'KEEP MY ACCESS';

  return (
    <FastSubscriptionModalPresentation
      ctaText={ctaText}
      data={data}
      isCreating={isCreating}
      isFullPaywallAvailable={isFullPaywallAvailable}
      isLoading={isLoading}
      isOpen={isOpen}
      primaryButtonAction={primaryButtonAction}
      title={title}
      onClose={onClose}
      onNavigateToPaywall={onNavigateToPaywall}
    />
  );
}
