import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20rem;
  width: 100%;
`;

export const MainTitle = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const MainDescription = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Regular;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
`;

export const EntryDate = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const EntryFeatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  width: 100%;
`;

export const EntryFeature = styled.div`
  width: 50%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const EntryTitle = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 1.25rem;
  font-weight: 700;
  margin-left: 2rem;
`;

export const EntryDescription = styled.div`
  color: #fff;
  display: flex;
  font-family: TTNormsPro-Regular;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 2.9rem;
`;

export const BackgroundImage = styled.img`
  position: relative;
  bottom: 10rem;
  width: 100%;
`;
