import { useFeatureFlagsState, useFeatureFlag, FeatureFlags } from '@Utils';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { homeScreenLogEvent, logEventWithProperties } from '../../actions/analytics';
import { clearPostOnboardingRedirectData } from '../../domains/Onboarding/utils/clearPostOnboardingRedirectData';

import { RootReducerType } from '../../reducers';

import { useDynamicMentalStates } from '../../api/modules/DynamicMentalStates';
import { DynamicMentalState } from '@Model';
import {
  ONBOARDING_MENTAL_STATE_ID_KEY,
  ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY,
} from '../../domains/Onboarding/constants';
import { ANIMATION_TIMING_MS } from './constants';
import { HomePresentation } from './Home.presentation';
import { CardHoverState } from './Home.types';
import { getRecommendedActivitiesTitle } from './lib/getRecommendedActivitiesTitle';

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const [selectedState, setSelectedState] = useState<string>('');
  const [hoverState, setHoverState] = useState<CardHoverState>('none');
  const recommendedActivitiesData = useSelector(
    (state: RootReducerType) => state.recommendedActivities,
  );
  const lastPlayedDynamicActivities = useSelector(
    (state: RootReducerType) => state.user.lastPlayedDynamicActivities,
  );
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const currentSessionDynamicActivityId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.id,
  );

  const { data: dynamicMentalStates, isFetched: isFetchedDynamicActivities } =
    useDynamicMentalStates();

  const { isSettled: isFeatureFlagsReceived } = useFeatureFlagsState();

  const isEnabledRecommendedActivities = useFeatureFlag(FeatureFlags.RecommendedActivities, {
    setAnalyticsUserProperty: true,
  });

  const navigate = useNavigate();

  const navigateToActivity = useCallback(
    (activityId: string) => () => {
      navigate(`/player/${activityId}`);
    },
    [navigate],
  );

  useEffect(() => {
    // TODO: move to redux or come up with a better solution.
    const onboardingMentalStateId = sessionStorage.getItem(ONBOARDING_MENTAL_STATE_ID_KEY);
    const onboardingDynamicActivityId = sessionStorage.getItem(ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY);
    if ((!onboardingMentalStateId && !onboardingDynamicActivityId) || !isFeatureFlagsReceived) {
      return;
    }

    if (onboardingDynamicActivityId) {
      navigateToActivity(onboardingDynamicActivityId)();
      clearPostOnboardingRedirectData();
      return;
    }

    if (isFetchedDynamicActivities && dynamicMentalStates) {
      const mentalState = dynamicMentalStates.find(
        mentalState => mentalState.id === onboardingMentalStateId,
      );
      if (mentalState) {
        navigateToDynamicSessionPlayer(mentalState, { isFirstSession: true })();
        clearPostOnboardingRedirectData();
      }
    }
  }, [
    isFeatureFlagsReceived,
    dynamicMentalStates,
    isFetchedDynamicActivities,
    modalType,
    navigateToActivity,
  ]);

  useEffect(() => {
    dispatch(homeScreenLogEvent('home_screen_view'));
  }, [dispatch]);

  const navigateToDynamicSessionPlayer = useCallback(
    (mentalState: DynamicMentalState, params?: { isFirstSession?: boolean }) => () => {
      const defaultActivityId = params?.isFirstSession
        ? mentalState.yourFirstSessionActivity.id
        : mentalState.defaultActivity.id;
      const activity = lastPlayedDynamicActivities[mentalState.id] || defaultActivityId;

      setSelectedState(mentalState.id); // just for visual fade out effect
      setTimeout(() => navigate(`/player/${activity}`), ANIMATION_TIMING_MS);
      dispatch(homeScreenLogEvent(`home_screen_start_${mentalState.id}`));
    },
    [navigate, dispatch, lastPlayedDynamicActivities, homeScreenLogEvent],
  );

  const handleOpenProfileWidget = () => {
    dispatch(logEventWithProperties({ event: 'profile_open_menu' }));
  };

  const onSetHoverState = useCallback(
    (state: CardHoverState) => () => {
      setHoverState(state);
    },
    [setHoverState],
  );

  const handleMiniPlayerClick = () => {
    navigate(`/player/${currentSessionDynamicActivityId}`);
  };

  const recommendedActivitiesTitle = getRecommendedActivitiesTitle();

  const isDisplayedRecommendedActivities =
    isEnabledRecommendedActivities &&
    recommendedActivitiesData.status === 'idle' &&
    Boolean(recommendedActivitiesData.results.length);

  return (
    <HomePresentation
      currentTrack={currentTrack}
      dynamicMentalStates={dynamicMentalStates || []}
      handleOpenProfileWidget={handleOpenProfileWidget}
      hoverState={hoverState}
      isDisplayedRecommendedActivities={isDisplayedRecommendedActivities}
      isLoading={!isFetchedDynamicActivities}
      navigateToDynamicSessionPlayer={navigateToDynamicSessionPlayer}
      recommendedActivitiesData={recommendedActivitiesData}
      recommendedActivitiesTitle={recommendedActivitiesTitle}
      selectedState={selectedState}
      onClickMiniPlayer={handleMiniPlayerClick}
      onClickRecommendedActivity={navigateToActivity}
      onSetHoverState={onSetHoverState}
    />
  );
};
