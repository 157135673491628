import { TabBar, TextBold } from '@Cortex';
import { musicActions } from '@Music';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';

import * as S from './ExploreMusic.styles';
import { Favorites } from './components/Favorites';
import { Featured } from './components/Featured';
import { Recent } from './components/Recent';
import { TracksByGenres } from './components/TracksByGenres/TracksByGenres';
import { RootReducerType } from '../../../../reducers';
import { ExplorePanelTab } from '../../../../types';
import { Serving, Track } from '@Model';
import { TrackInfoModal } from '../TrackInfoModal/TrackInfoModal';

const TAB_ITEMS = Object.values(ExplorePanelTab);

type Props = {
  mentalState: string;
  onClose: () => void;
};

export function ExploreMusic(props: Props) {
  const preSelectedTab = useSelector((state: RootReducerType) => state.ui.explorePanelTab);
  const [currentPane, setCurrentPane] = useState<ExplorePanelTab>(ExplorePanelTab.Featured);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const featuredRef = useRef<HTMLDivElement>(null);
  const favoritesRef = useRef<HTMLDivElement>(null);
  const recentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [previewTrack, setPreviewTrack] = useState<Track | Serving | null>(null);

  useEffect(() => {
    if (preSelectedTab) {
      const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === preSelectedTab);

      if (activeIndex >= 0) {
        setActiveTabIndex(activeIndex);
      }
      setCurrentPane(preSelectedTab);
    }

    return () => {
      dispatch(musicActions.setGenreFilters([]));
      dispatch(musicActions.setActivityFilters([]));
      dispatch(musicActions.setNeuralEffectFilters([]));
    };
  }, []);

  const handleTabItemClick = (pane: ExplorePanelTab) => {
    const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === pane);

    if (activeIndex >= 0) {
      setActiveTabIndex(activeIndex);
    }

    setCurrentPane(pane);
  };

  const handleTrackClickMore = (track: Track | Serving) => {
    setIsMoreInfoOpened(true);
    setPreviewTrack(track);
  };

  return (
    <>
      <S.Container data-testid="ExploreMusic">
        <S.HeadingContainer>
          <TextBold size={32}>{`Explore ${props.mentalState}`}</TextBold>
        </S.HeadingContainer>

        <S.TabBarContainer>
          <TabBar activeTabIndex={activeTabIndex} items={TAB_ITEMS} onSelect={handleTabItemClick} />
        </S.TabBarContainer>

        <Transition
          in={currentPane === ExplorePanelTab.Featured}
          mountOnEnter
          nodeRef={featuredRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FadeInWrapper ref={featuredRef} animationState={animationState}>
              <Featured onTrackClickMore={handleTrackClickMore} />
            </S.FadeInWrapper>
          )}
        </Transition>

        <Transition
          in={currentPane === ExplorePanelTab.Genres}
          mountOnEnter
          timeout={0}
          unmountOnExit
        >
          <TracksByGenres onTrackClickMore={handleTrackClickMore} />
        </Transition>

        <Transition
          in={currentPane === ExplorePanelTab.Favorites}
          mountOnEnter
          nodeRef={favoritesRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FadeInWrapper ref={favoritesRef} animationState={animationState}>
              <Favorites mentalState={props.mentalState} onTrackClickMore={handleTrackClickMore} />
            </S.FadeInWrapper>
          )}
        </Transition>

        <Transition
          in={currentPane === ExplorePanelTab.Recent}
          mountOnEnter
          nodeRef={recentRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FadeInWrapper ref={recentRef} animationState={animationState}>
              <Recent onTrackClickMore={handleTrackClickMore} />
            </S.FadeInWrapper>
          )}
        </Transition>
      </S.Container>
      <TrackInfoModal
        isOpen={isMoreInfoOpened}
        track={previewTrack}
        onClose={() => setIsMoreInfoOpened(false)}
      />
    </>
  );
}
