import { Icon } from '@Cortex';
import { DynamicMentalState, Serving, Track } from '@Model';
import { ProfileWidget } from '@User';
import { getTrackGenreName, getTrackImageUrl, getTrackName } from '@Music';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { useState } from 'react';
import BrandLogo from '../../assets/images/brand_logo.svg';
import LoadingIcon from '../../components/icons/LoadingIcon';
import DynamicMentalStateCard from '../../domains/Cortex/DynamicMentalStateCard';
import { ActivityCard } from '../../domains/Music/components/ActivityCard/ActivityCard';
import { JumpBackInModal } from '../../domains/Session/components/JumpBackInModal';
import { MiniPlayer } from '../../domains/Session/components/MiniPlayer/MiniPlayer';
import { RecommendedActivitiesReducerType } from '../../reducers/recommendedActivities';
import { MentalStatesWavesData } from './abTestData';
import * as S from './Home.styles';
import { CardHoverState } from './Home.types';

type Props = {
  hoverState: CardHoverState | string;
  selectedState: string;
  isLoading: boolean;
  isDisplayedRecommendedActivities: boolean;
  handleOpenProfileWidget: () => void;
  recommendedActivitiesData: RecommendedActivitiesReducerType;
  navigateToDynamicSessionPlayer: (mentalState: DynamicMentalState) => () => void;
  dynamicMentalStates: DynamicMentalState[];
  recommendedActivitiesTitle: string;
  onboardingIndex?: number;
  currentTrack?: Track | Serving | null;
  onSetHoverState: (state: CardHoverState) => () => void;
  onClickRecommendedActivity: (activityId: string) => () => void;
  onClickMiniPlayer: () => void;
};

export const HomePresentation = (props: Props) => {
  const {
    hoverState,
    selectedState,
    dynamicMentalStates,
    isLoading,
    isDisplayedRecommendedActivities,
    recommendedActivitiesTitle,
    currentTrack,
    handleOpenProfileWidget,
    navigateToDynamicSessionPlayer,
    onSetHoverState,
    onClickRecommendedActivity,
    onClickMiniPlayer,
  } = props;

  const isUsingWavesImages = useFeatureFlag(FeatureFlags.MentalStatesWaves, {
    setAnalyticsUserProperty: true,
  });

  const [jumpBackInStatus, setJumpBackInStatus] = useState<'hidden' | 'minimized' | 'fullscreen'>(
    'minimized',
  );

  return (
    <S.Container>
      <S.Wrapper
        isJumpBackInVisible={jumpBackInStatus === 'fullscreen'}
        selectedState={selectedState}
      >
        <S.Header>
          <S.IconContainer />
          <S.IconContainer>
            <Icon size={67} src={BrandLogo} />
          </S.IconContainer>
          <S.UserLogoContainer>
            <ProfileWidget onOpen={handleOpenProfileWidget} />
          </S.UserLogoContainer>
        </S.Header>
        {isLoading ? (
          <S.Body>
            <LoadingIcon />
          </S.Body>
        ) : null}
        {!isLoading ? (
          <S.BodyDynamic>
            {isDisplayedRecommendedActivities && (
              <>
                <S.TitleWrapper>
                  <S.Title>{recommendedActivitiesTitle}</S.Title>
                </S.TitleWrapper>
                <S.RecommendedActivitiesWrapper>
                  {props.recommendedActivitiesData.results.map(activity => (
                    <ActivityCard
                      key={activity.id}
                      dynamicActivity={activity}
                      onClick={onClickRecommendedActivity(activity.id)}
                    />
                  ))}
                </S.RecommendedActivitiesWrapper>
                <S.TitleWrapper>
                  <S.Title>CHOOSE A MENTAL STATE</S.Title>
                </S.TitleWrapper>
              </>
            )}
            <S.MentalStatesWrapper>
              {(dynamicMentalStates || [])?.map((mentalState, i) => (
                <DynamicMentalStateCard
                  key={mentalState.id}
                  color={mentalState.primaryColor}
                  data-testid={`${mentalState.id}Card`}
                  description={
                    isUsingWavesImages
                      ? MentalStatesWavesData[mentalState.id].description
                      : undefined
                  }
                  imageUrl={
                    isUsingWavesImages
                      ? MentalStatesWavesData[mentalState.id].primary
                      : mentalState.imageUrl
                  }
                  imageUrlSecondary={
                    isUsingWavesImages
                      ? MentalStatesWavesData[mentalState.id].hover
                      : mentalState.imageUrlSecondary
                  }
                  isActive={hoverState === mentalState.id}
                  isFullColumnHeight={i === 0}
                  isUsingWavesImages={isUsingWavesImages}
                  mentalState={mentalState.displayValue}
                  onClick={navigateToDynamicSessionPlayer(mentalState)}
                  onMouseEnter={onSetHoverState(mentalState.id)}
                  onMouseLeave={onSetHoverState('none')}
                />
              ))}
            </S.MentalStatesWrapper>
            {currentTrack ? (
              <S.MiniPlayerWrapper>
                <MiniPlayer
                  trackGenre={getTrackGenreName(currentTrack)}
                  trackImageUrl={getTrackImageUrl(currentTrack)}
                  trackName={getTrackName(currentTrack)}
                  onClick={onClickMiniPlayer}
                />
              </S.MiniPlayerWrapper>
            ) : null}
          </S.BodyDynamic>
        ) : null}
      </S.Wrapper>

      <S.JumpBackInContainer>
        <JumpBackInModal
          onClose={() => setJumpBackInStatus('minimized')}
          onOpen={() => setJumpBackInStatus('fullscreen')}
        />
      </S.JumpBackInContainer>
    </S.Container>
  );
};
