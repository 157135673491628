import { WhatsNewEntry } from './types';

export const WHATS_NEW_ENTRIES: WhatsNewEntry[] = [
  {
    id: '2024-05-10',
    shouldAlert: false,
    timestamp: 1715355305000,
    features: [
      {
        title: 'Jump Back In',
        description:
          'Easily access your recent sessions and tracks by clicking the button at the bottom of the Home Screen.',
      },
    ],
  },
  {
    id: '2024-04-26',
    shouldAlert: true,
    timestamp: 1714145933000,
    features: [
      {
        title: 'Meditation Mental State',
        description: 'Access Unguided and Guided meditation content directly from your Home Screen',
      },
      {
        title: 'New Activities',
        description: 'Light Work, Guided Meditation, Unwind, Destress, and more!',
      },
      {
        title: 'Play All Favorites',
        description:
          'Play all your Favorited tracks in any Mental State with our new shuffle feature in Explore',
      },
      {
        title: 'Genre & Mood Mixes',
        description:
          'Discover more from your favorite genres and moods with one-click starts in Explore',
      },
      {
        title: 'Custom Mixes',
        description:
          'Choosing a track from Explore starts a Custom Mix that will play similar tracks based on your choice',
      },
      {
        title: 'Related Activities',
        description:
          'Select a currently playing track to view other Activities where it is included. Click on any Activity to start it!',
      },
    ],
  },
];
