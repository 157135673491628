import {
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackName,
  getTrackNeuralEffectLevel,
} from '@Music';
import { showToast } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { Analytics } from '../../../utils/analytics';
import { Logger } from '../../../utils/logger';
import { trackFavoriteTrackEvent } from '../../Analytics/coreAnalytics';
import { MentalStates } from '../../Analytics/coreAnalytics.types';
import { addToFavorites } from '../actions';
import { getTrackVariationId } from '../lenses';
import { getTrackDynamicMentalStateDisplayValue } from '../lenses/getTrackDynamicMentalStateDisplayValue';
import { musicActions } from '../reducer';
import { uiSliceActions } from '../../../reducers/uiReducer';

export function* addToFavoritesSaga(action: Action) {
  if (addToFavorites.match(action)) {
    try {
      const currentTrackTimeStamp: number = yield select(
        (state: RootReducerType) => state.sessionManager.currentTrackTimeStamp,
      );
      const dynamicActivityDisplayValue: string = yield select(
        (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.displayValue,
      );
      yield put(musicActions.addToFavorites(action.payload.track));

      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );

      const { favorites }: RootReducerType['music'] = yield select(
        (state: RootReducerType) => state.music,
      );

      if (favorites.length === 1) {
        yield put(uiSliceActions.setShouldShowFavoriteModal(true));
      } else {
        showToast('Added to your library');
      }

      const response: SuccessResponseType = yield requestSaga(
        RequestMethods.PUT,
        `/users/${info?.id}/favorite?version=3`,
        {
          variationId: getTrackVariationId(action.payload.track),
        },
        2,
      );

      if (response.status === 200) {
        try {
          const id = getTrackId(action.payload.track);
          const genreName = getTrackGenreName(action.payload.track);
          const mentalStateDisplayValue = getTrackDynamicMentalStateDisplayValue(
            action.payload.track,
          );
          const complexityDisplayValue = getTrackComplexity(action.payload.track);
          const neuralEffectLevelDisplayValue = getTrackNeuralEffectLevel(action.payload.track);
          const name = getTrackName(action.payload.track);

          Analytics.logEventWithProperties('session_track_favorite', {
            id,
            name,
          } as any);

          trackFavoriteTrackEvent({
            activity: dynamicActivityDisplayValue,
            complexity: complexityDisplayValue,
            trackGenre: genreName,
            mentalState: mentalStateDisplayValue as MentalStates,
            origin: action.payload.origin,
            trackNel: neuralEffectLevelDisplayValue,
            trackName: name,
            trackSeekPosition: currentTrackTimeStamp >= 0 ? currentTrackTimeStamp : undefined,
          });
        } catch (error) {
          Logger.error(new Error('addToFavoritesSaga(): unable to track favorite'), {
            reason: error,
          });
        }
      } else {
        yield put(musicActions.removeFromFavorites(action.payload.track));
      }
    } catch (error) {
      yield put(musicActions.addToFavorites(action.payload.track));
      Logger.error(error);
    }
  }
}
