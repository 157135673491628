import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

export const CancelWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  > div {
    font-size: 0.9rem;
  }
`;

export const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-style: normal;
  font-size: 0.875rem;
  align-items: center;
  color: #d4d2ea;
  margin-bottom: 0.5rem;
  letter-spacing: 0.1rem;
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;

  align-items: center;
  color: #ffffff;
`;

export const PaymentButton = styled.div<{ hasBottomMargin?: boolean }>`
  ${props => (props.hasBottomMargin ? 'margin-bottom: 1rem;' : null)}
  width: 100%;
`;
