import { Panel } from '@Cortex';
import { ExploreMusic } from '@Music';
import { FavoriteModal, Preferences, SessionNELTip, SessionProTip, TimeMode } from '@Session';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import {
  setSessionSidebarContent,
  setShouldShowNELTipsModal,
  setShouldShowProTipsModal,
} from '../../actions/ui';
import CrossIcon from '../../assets/images/close_icon.svg';
import {
  ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY,
  ONBOARDING_DELAYED_TEST_TIMER,
} from '../../domains/Onboarding/constants';
import { QuizToast } from '../../domains/PersonalizationQuiz';
import { RootReducerType } from '../../reducers';
import { uiSliceActions } from '../../reducers/uiReducer';
import { SessionSidebarContent } from '../../types';
import { FocusSectionDynamic } from './components/FocusSectionDynamic';
import * as S from './Session.styles';
import { SessionHeader } from './components/SessionHeader';
import { SESSION_ANIMATION_DURATION_MS } from './utils/constants';
import { getBackgroundImage } from './utils/getBackgroundImage';
import { processDynamicMentalStateColor } from './utils/processDynamicMentalStateColor';
import { dynamicReplaceTracksWithUpdatedPreferences } from '../../actions/sessionManager';
import { logEvent } from '../../actions/analytics';
import { ActivitySelection } from '../../domains/Session/components/ActivitySelection';

export const Session = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const currentBackgroundImageUrl = currentTrack ? getBackgroundImage(currentTrack) : null;

  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

  const [isProTipVisible, setProTipVisible] = useState(false);
  const [isNELTipVisible, setNELTipVisible] = useState(false);

  const sessionDynamicMentalStateColor = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.primaryColor,
  );

  const mentalStateColor = useMemo(() => {
    if (!sessionDynamicMentalStateColor) return 'rgba(254, 91, 131, 0.1)'; // fallback to focus hardcoded color

    return processDynamicMentalStateColor(sessionDynamicMentalStateColor, 0.1);
  }, [sessionDynamicMentalStateColor]);

  const sessionDynamicMentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );

  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);

  const sessionSidebarContent = useSelector(
    (state: RootReducerType) => state.ui.sessionSidebarContent,
  );
  const shouldShowProTipsModal = useSelector(
    (state: RootReducerType) => state.ui.shouldShowProTipsModal,
  );

  const shouldShowNELTipsModal = useSelector(
    (state: RootReducerType) => state.ui.shouldShowNELTipsModal,
  );

  const shouldShowFocusPersonalizationQuiz =
    useSelector((state: RootReducerType) => state.ui.shouldShowFocusPersonalizationQuiz) &&
    sessionDynamicMentalStateDisplayValue === 'focus'; // displayValue as a temp solution

  useEffect(() => {
    if (isNELTipVisible) {
      dispatch(logEvent('player_htd_impression'));
    }
  }, [isNELTipVisible]);

  useEffect(() => {
    let isCancelled = false;
    if (!currentBackgroundImageUrl) {
      setBackgroundImage(null);
      return;
    }
    const img = new Image();
    img.src = currentBackgroundImageUrl;
    img.onload = function () {
      if (!isCancelled && img.src === currentBackgroundImageUrl) {
        setBackgroundImage(currentBackgroundImageUrl);
      }
    };
    return () => {
      isCancelled = true;
    };
  }, [currentBackgroundImageUrl]);

  useEffect(() => {
    if (location.state && location.state.actions && location.state.actions.length) {
      location.state.actions.forEach((action: any) => {
        dispatch(action);
      });
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    if (!modalType && shouldShowProTipsModal) {
      // timeout is used because the parent component is transitioning in
      const timeoutId = setTimeout(() => {
        setProTipVisible(true);
      }, SESSION_ANIMATION_DURATION_MS);

      return () => clearTimeout(timeoutId);
    }
  }, [shouldShowProTipsModal, modalType]);

  useEffect(() => {
    if (!modalType && shouldShowNELTipsModal) {
      const timeout =
        sessionStorage.getItem(ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY) === 'true'
          ? ONBOARDING_DELAYED_TEST_TIMER
          : SESSION_ANIMATION_DURATION_MS;
      sessionStorage.removeItem(ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY);

      // timeout is used because the parent component is transitioning in
      const timeoutId = setTimeout(() => {
        setNELTipVisible(true);
      }, timeout);

      return () => {
        clearTimeout(timeoutId);
        setNELTipVisible(false);
        dispatch(setShouldShowNELTipsModal(false));
      };
    }
  }, [shouldShowNELTipsModal, modalType, dispatch]);

  const handleProTipClose = () => {
    dispatch(setShouldShowProTipsModal(false));
    setProTipVisible(false);
  };

  const handleNELTipClose = () => {
    dispatch(setShouldShowNELTipsModal(false));
    setNELTipVisible(false);
    dispatch(logEvent('player_htd_dismiss'));
  };

  const handleTurbochargeMe = () => {
    dispatch(logEvent('turbocharge_me_click'));
    dispatch(dynamicReplaceTracksWithUpdatedPreferences({ neuralEffectLevels: ['High'] }));
  };

  const handleQuizToastClose = () => {
    dispatch(uiSliceActions.setShouldShowFocusPersonalizationQuiz(false));
  };

  const closeSidebarComponent = useCallback(() => {
    dispatch(setSessionSidebarContent({ sessionSidebarContent: null, origin: undefined }));
    dispatch(uiSliceActions.setExploreModalTab(null));
  }, [dispatch]);

  const setSessionSidebar = (contentType: SessionSidebarContent) => {
    if (isNELTipVisible && contentType === SessionSidebarContent.Preferences) {
      dispatch(setShouldShowNELTipsModal(false));
      setNELTipVisible(false);
    }
    dispatch(setSessionSidebarContent({ sessionSidebarContent: contentType, origin: 'player' }));
  };

  return (
    <>
      <Transition in={isProTipVisible} mountOnEnter timeout={0} unmountOnExit>
        <S.SessionProTipWrapper>
          <SessionProTip onClose={handleProTipClose} />
        </S.SessionProTipWrapper>
      </Transition>

      <Transition in={shouldShowFocusPersonalizationQuiz} mountOnEnter timeout={0} unmountOnExit>
        <S.SessionProTipWrapper>
          <QuizToast
            shouldShowButtonToOpenPreferences={
              sessionSidebarContent === SessionSidebarContent.ExploreMusic ||
              sessionSidebarContent === SessionSidebarContent.TimerSettings
            }
            onClickOpenPreferences={() => setSessionSidebar(SessionSidebarContent.Preferences)}
            onClose={handleQuizToastClose}
          />
        </S.SessionProTipWrapper>
      </Transition>

      <Transition in={isNELTipVisible} mountOnEnter timeout={0} unmountOnExit>
        <S.SessionNELTipWrapper>
          <SessionNELTip onClose={handleNELTipClose} onTurbochargeMeClick={handleTurbochargeMe} />
        </S.SessionNELTipWrapper>
      </Transition>
      <S.PageWrapper backgroundImageUrl={backgroundImage}>
        <S.Overlay backgroundColor={mentalStateColor} />
        <S.PageContent>
          <FavoriteModal />

          <S.BackgroundWrapper>
            <S.SessionWrapper>
              <FocusSectionDynamic />
            </S.SessionWrapper>
          </S.BackgroundWrapper>

          <SessionHeader
            onClickExploreMusic={() => setSessionSidebar(SessionSidebarContent.ExploreMusic)}
            onClickPreferences={() => setSessionSidebar(SessionSidebarContent.Preferences)}
            onClickTimerSettings={() => setSessionSidebar(SessionSidebarContent.TimerSettings)}
          />
        </S.PageContent>
      </S.PageWrapper>
      {sessionSidebarContent && sessionSidebarContent === SessionSidebarContent.Preferences ? (
        <S.PreferencesContentContainer isCentered>
          <Panel borderRadius="1.25rem" padding="0" style={S.GradientContainerStyleTransparent}>
            <Preferences onClose={closeSidebarComponent} />
          </Panel>
        </S.PreferencesContentContainer>
      ) : null}

      {sessionSidebarContent && sessionSidebarContent === SessionSidebarContent.Activity ? (
        <S.PreferencesContentContainer isCentered>
          <Panel borderRadius="1.25rem" padding="0" style={S.GradientContainerStyleTransparent}>
            <ActivitySelection onClose={closeSidebarComponent} />
          </Panel>
        </S.PreferencesContentContainer>
      ) : null}

      {(sessionSidebarContent && sessionSidebarContent === SessionSidebarContent.ExploreMusic) ||
      sessionSidebarContent === SessionSidebarContent.TimerSettings ? (
        <S.SidebarContentContainer>
          <Panel style={S.GradientContainerStyle}>
            <S.CloseButtonContainer>
              <S.CloseButton
                data-testid="closeButton"
                src={CrossIcon}
                onClick={closeSidebarComponent}
              />
            </S.CloseButtonContainer>

            {sessionSidebarContent === SessionSidebarContent.ExploreMusic ? (
              <ExploreMusic
                mentalState={sessionDynamicMentalStateDisplayValue || ''}
                onClose={closeSidebarComponent}
              />
            ) : null}
            {sessionSidebarContent === SessionSidebarContent.TimerSettings ? (
              <TimeMode onClose={closeSidebarComponent} />
            ) : null}
          </Panel>
        </S.SidebarContentContainer>
      ) : null}
      <Outlet />
    </>
  );
};
