export const getRecommendedActivitiesTitle = (): string => {
  const currentHour = new Date().getHours();

  if (currentHour >= 4 && currentHour < 12) {
    return 'MORNING ACTIVITIES FOR YOU';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'AFTERNOON ACTIVITIES FOR YOU';
  } else {
    return 'EVENING ACTIVITIES FOR YOU';
  }
};
