import styled from 'styled-components';
import InformationIconSVG from '../../../../assets/images/information_icon.svg?react';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-radius: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    overflow: hidden auto;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    height: calc(100vh - 4rem - 2rem); // deduct Paper padding and parent padding
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const HeadingContainer = styled.div`
  margin-bottom: 1rem;
`;

export const HeadingContainerWithIcon = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const HeaderTitle = styled.h2`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 4px;
  padding: 0;
`;

export const HeaderDescription = styled.p`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const InformationIcon = styled(InformationIconSVG)`
  margin-left: 0.5rem;
`;

export const Panel = styled.div<{ hasBorders?: boolean }>`
  ${props => (props.hasBorders ? 'border-left: solid 1px rgba(255, 255, 255, 0.20);' : null)}
  ${props => (props.hasBorders ? 'border-right: solid 1px rgba(255, 255, 255, 0.20);' : null)}
  padding: 0 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  overflow: hidden auto;
  width: 50%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    border-left: none;
    border-right: none;
    overflow: unset;
    padding-bottom: 0;
    width: 100%;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
`;
