import { CoreAnalytics } from '@Analytics';
import { ButtonVariantStates } from '@Cortex';
import { REFER_A_FRIEND_LINK } from '@Globals';
import { useState } from 'react';

import { Analytics } from '../../../utils/analytics';
import { Logger } from '../../../utils/logger';
import { ReferDisplay } from './Refer.display';

export const Refer = () => {
  const [buttonState, setButtonState] = useState({
    text: 'COPY REFERRAL LINK',
    state: ButtonVariantStates.Idle,
  });

  const handleCopyLink = async () => {
    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(REFER_A_FRIEND_LINK as string);
        Analytics.logEvent('profile_refer_a_friend_copy_link');
        CoreAnalytics.trackReferralLinkCopied();
        setButtonState({ text: 'COPIED', state: ButtonVariantStates.Success });
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy refer link to clipboard'), { reason: error });
      setButtonState({ text: 'ERROR', state: ButtonVariantStates.Error });
    } finally {
      setTimeout(() => {
        setButtonState({ text: 'COPY REFERRAL LINK', state: ButtonVariantStates.Idle });
      }, 1500);
    }
  };

  return <ReferDisplay buttonState={buttonState} onButtonClick={handleCopyLink} />;
};
