import { Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { Genres, NeuralEffectLevelSelection, PredefinedGenres } from '@Music';
import { useUser } from '@User';
import { useOutsideClickRef, showToast } from '@Utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { dynamicReplaceTracksWithUpdatedPreferences } from '../../../../actions/sessionManager';
import { DA_PREFIXES } from '../../../../constants';
import { RootReducerType } from '../../../../reducers';
import { MentalStates } from '../../../../types';
import { Analytics } from '../../../../utils/analytics';
import { Events } from '../../../../utils/analytics/events';
import {
  trackFilterPreferencesOpened,
  trackFilterPreferencesChanged,
} from '../../../Analytics/coreAnalytics';
import { FilterTypes } from '../../../Analytics/coreAnalytics.types';
import { useDynamicSession } from '../../lenses/useDynamicSession';
import * as S from './Preferences.styles';
import { NEL_LEARN_MORE_LINK } from './constants';
import { DynamicActivity } from '@Model';
import { NELInfo } from './components/NELInfo';

interface Props {
  onClose: () => void;
}

export function Preferences(props: Props) {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useDynamicSession();
  const outsideClickRef = useOutsideClickRef({ onOutsideClick: props.onClose });
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );

  const stateSelectedGenres = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates].genreNames
    : [];
  const stateSelectedNELs = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates].neuralEffectLevels
    : [];

  const [localSelectedGenres, setLocalGenres] = useState(stateSelectedGenres);
  const [localSelectedNELs, setLocalNELs] = useState(stateSelectedNELs);

  const isSimilarDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(
    DA_PREFIXES.SIMILAR,
  );
  const isFavoritesDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(
    DA_PREFIXES.FAVORITES,
  );
  const isGenresDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(
    DA_PREFIXES.GENRE_NAMES,
  );

  const isMoodDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(DA_PREFIXES.MOOD);

  useEffect(() => {
    Analytics.logEventWithProperties(Events.preferences_opened, {});
    if (session?.mentalState) {
      trackFilterPreferencesOpened({
        filterType: FilterTypes.Consolidated,
        mentalState: session.mentalState.id as MentalStates,
      });
    }
  }, []);

  const handleAnalytics = (
    newState: {
      localSelectedGenres: string[];
      localSelectedNELs: string[];
      localSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
    },
    oldState: {
      stateSelectedGenres: string[];
      stateSelectedNELs: string[];
      stateSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
    },
  ) => {
    // track genres changes
    let genresChanged = null;
    let nelChanged = null;
    if (
      JSON.stringify(newState.localSelectedGenres) !== JSON.stringify(oldState.stateSelectedGenres)
    ) {
      const newGenre = [...newState.localSelectedGenres].sort().join(',');
      const oldGenre = [...oldState.stateSelectedGenres].sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_genre_filters_changed, {
        old_filter: oldGenre,
        new_filter: newGenre,
      } as any);
      genresChanged = {
        newFilterGenre: newGenre,
        oldFilterGenre: oldGenre,
      };
    }

    // track NEL changes
    if (JSON.stringify(newState.localSelectedNELs) !== JSON.stringify(oldState.stateSelectedNELs)) {
      const oldNel = oldState.stateSelectedNELs.slice().sort().join(',');
      const newNel = newState.localSelectedNELs.slice().sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_nel_filters_changed, {
        old_filter: oldNel,
        new_filter: newNel,
      } as any);
      nelChanged = {
        newFilterNel: newNel,
        oldFilterNel: oldNel,
      };
    }

    if (
      newState.localSelectedActivity?.displayValue !== oldState.stateSelectedActivity?.displayValue
    ) {
      // track activity changes
      Analytics.logEventWithProperties(Events.preferences_activity_filters_changed, {
        old_filter: oldState.stateSelectedActivity?.displayValue,
        new_filter: newState.localSelectedActivity?.displayValue,
      } as any);
    }

    if (session && (nelChanged || genresChanged)) {
      trackFilterPreferencesChanged({
        filterType: FilterTypes.Consolidated,
        mentalState: session?.mentalState.id as MentalStates,
        ...nelChanged,
        ...genresChanged,
      });
    }
  };

  const onNelReadMoreClick = useCallback(() => {
    window.open(NEL_LEARN_MORE_LINK, '_blank');
    Analytics.logEventWithProperties(Events.learn_more_button_clicked, {
      learn_more_button_location: 'nel_filter',
    });
  }, []);

  const handleGenreSelect = (genreName: string) => {
    if (localSelectedGenres.includes(genreName)) {
      setLocalGenres(localSelectedGenres.filter(item => item !== genreName));
    } else {
      setLocalGenres([...localSelectedGenres, genreName]);
    }
  };

  const handleNELSelect = (NEL: string) => {
    if (localSelectedNELs.includes(NEL)) {
      setLocalNELs(localSelectedNELs.filter(item => item !== NEL));
    } else {
      setLocalNELs([...localSelectedNELs, NEL]);
    }
  };

  const handleApplyChanges = () => {
    let payload: {
      genreNames?: string[];
      neuralEffectLevels?: string[];
    } = {};

    if (JSON.stringify(stateSelectedGenres) !== JSON.stringify(localSelectedGenres)) {
      payload.genreNames = localSelectedGenres;
    }
    if (JSON.stringify(stateSelectedNELs) !== JSON.stringify(localSelectedNELs)) {
      payload.neuralEffectLevels = localSelectedNELs;
    }
    dispatch(dynamicReplaceTracksWithUpdatedPreferences(payload));
    handleAnalytics(
      {
        localSelectedGenres,
        localSelectedNELs,
        localSelectedActivity: sessionDynamicActivity!,
      },
      {
        stateSelectedActivity: sessionDynamicActivity!,
        stateSelectedGenres,
        stateSelectedNELs,
      },
    );

    props.onClose();
    showToast('Your preferences have been updated!');
  };

  const isAnythingChanged =
    JSON.stringify(localSelectedGenres) !== JSON.stringify(stateSelectedGenres) ||
    JSON.stringify(localSelectedNELs) !== JSON.stringify(stateSelectedNELs);

  return (
    <S.Wrapper ref={outsideClickRef} data-testid="preferencesPanel">
      <S.Container>
        <S.Panel hasBorders>
          <S.HeadingContainer>
            <S.HeaderTitle>Genre</S.HeaderTitle>
            <S.HeaderDescription>
              Select the genres you would like to hear while listening.
            </S.HeaderDescription>
          </S.HeadingContainer>
          <S.SectionWrapper>
            {isSimilarDynamicActivity ||
            isFavoritesDynamicActivity ||
            isGenresDynamicActivity ||
            isMoodDynamicActivity ? (
              <PredefinedGenres />
            ) : (
              <Genres
                activityId={sessionDynamicActivity?.id}
                selected={localSelectedGenres}
                onSelect={handleGenreSelect}
              />
            )}
          </S.SectionWrapper>
        </S.Panel>
        <S.Panel>
          <S.HeadingContainer>
            <S.HeadingContainerWithIcon>
              <S.HeaderTitle>Neural Effect Level</S.HeaderTitle>
              <S.InformationIcon className="nel-information-tooltip" />
              <Tooltip
                anchorSelect=".nel-information-tooltip"
                clickable
                style={{
                  backgroundColor: 'black',
                  opacity: 0.9,
                  width: '20rem',
                  borderRadius: '8px',
                  padding: '1.5rem',
                  zIndex: 10,
                }}
              >
                <NELInfo onClick={onNelReadMoreClick} />
              </Tooltip>
            </S.HeadingContainerWithIcon>
            <S.HeaderDescription>
              Select the neural effect level(s) you’d like us to incorporate into your session.
            </S.HeaderDescription>
          </S.HeadingContainer>
          <S.SectionWrapper>
            <NeuralEffectLevelSelection selected={localSelectedNELs} onSelect={handleNELSelect} />
          </S.SectionWrapper>
        </S.Panel>
      </S.Container>
      <S.Bottom>
        <Button
          data-testid="preferences-discard-changes"
          size={ButtonVariantSizes.Small}
          style={{ marginRight: 10, fontSize: '12px', textTransform: 'uppercase' }}
          variant={ButtonVariants.Base}
          onClick={props.onClose}
        >
          cancel
        </Button>
        <Button
          data-testid="preferences-apply-changes"
          disabled={!isAnythingChanged}
          size={ButtonVariantSizes.Small}
          style={{ fontSize: '12px', textTransform: 'uppercase', minWidth: '10rem' }}
          variant={ButtonVariants.Secondary}
          onClick={handleApplyChanges}
        >
          apply
        </Button>
      </S.Bottom>
    </S.Wrapper>
  );
}
