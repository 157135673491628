import { useEffect } from 'react';
import { Assets } from '../../../../utils/assets';
import { useWhatsNew } from '../../lib/useWhatsNew';
import { WhatsNewEntry } from '../../types';
import * as S from './WhatsNew.styles';

export function WhatsNew() {
  const { entries, markAsRead } = useWhatsNew();

  useEffect(() => {
    markAsRead();
  });

  return (
    <S.Container>
      <S.MainTitle>What's New</S.MainTitle>
      <S.MainDescription>
        Exciting updates for an even better listening experience!
      </S.MainDescription>
      {entries.map(Entry)}
      <S.BackgroundImage src={Assets.images.whatsNew.url} />
    </S.Container>
  );
}

function Entry(entry: WhatsNewEntry) {
  return (
    <>
      <S.EntryDate>{getLocalizedDateFromTimestamp(entry.timestamp)}</S.EntryDate>
      <S.EntryFeatures>{entry.features.map(Feature)}</S.EntryFeatures>
    </>
  );
}

function Feature(feature: WhatsNewEntry['features'][0]) {
  return (
    <S.EntryFeature>
      <S.EntryTitle>• {feature.title}</S.EntryTitle>
      <S.EntryDescription>{feature.description}</S.EntryDescription>
    </S.EntryFeature>
  );
}

function getLocalizedDateFromTimestamp(timestamp: number) {
  return new Date(timestamp).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}
