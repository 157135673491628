import { takeEvery, select, put, call } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';

import * as recentSessionsActions from '../actions/recentSessions';
import { RootReducerType } from '../reducers';

import { RecentSessionType } from '../domains/Session/components/JumpBackInModal/types';
import { requestSaga } from './httpRequest';
import { RequestMethods } from '../api/client/types';
import { TimerDisplayTypes } from '../domains/Timer/constants';
import { recentSessionsSliceActions } from '../reducers/recentSessions';
import { Logger } from '../utils/logger';

function* saveRecentSessionSaga(action: Action) {
  if (recentSessionsActions.saveRecentSession.match(action)) {
    yield put(recentSessionsSliceActions.fetchRecentSessions());

    try {
      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );
      const sessionManager: RootReducerType['sessionManager'] = yield select(
        (state: RootReducerType) => state.sessionManager,
      );
      const timer: RootReducerType['timer'] = yield select((state: RootReducerType) => state.timer);

      let timerSpecification: RecentSessionType['timerSpecification'] = {
        type: 'infinite',
      };

      if (
        sessionManager.sessionPlayType === 'NORMAL' &&
        timer.displayType === TimerDisplayTypes.Pomodoro
      ) {
        const { focusTime, breakTime } = timer.pomodoroSettings.intervals;
        const focusInSeconds = parseInt(focusTime, 10) * 60;
        const breakInSeconds = parseInt(focusTime, 10) * 60;

        timerSpecification = {
          type: 'interval',
          durations: [
            { displayValue: `${focusTime} min`, valueInSeconds: focusInSeconds },
            { displayValue: `${breakTime} min`, valueInSeconds: breakInSeconds },
          ],
        };
      } else if (sessionManager.sessionPlayType === 'TIMER') {
        timerSpecification = {
          type: 'countdown',
          durations: [
            {
              displayValue: sessionManager.timerLengthDisplayValue!,
              valueInSeconds: sessionManager.timerLength || 0,
            },
          ],
        };
      }

      const recentSessionBody: {
        dynamicActivityId: string;
        timerSpecification: RecentSessionType['timerSpecification'];
      } = {
        dynamicActivityId: sessionManager.sessionDynamicActivity?.id!,
        timerSpecification,
      };

      const recentSession = (yield call(
        requestSaga,
        RequestMethods.POST,
        `/users/${userInfo?.id}/sessions/recent?platform=web`,
        recentSessionBody,
        3,
      )) as {
        result: RecentSessionType[];
      };

      yield put(recentSessionsSliceActions.fetchRecentSessionsSuccess(recentSession.result));
    } catch (error) {
      yield put(recentSessionsSliceActions.fetchRecentSessionsSuccess([]));
      Logger.error(error);
    }
  }
}

function* fetchRecentSessionSaga(action: Action) {
  if (recentSessionsActions.fetchRecentSession.match(action)) {
    yield put(recentSessionsSliceActions.fetchRecentSessions());

    try {
      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );

      const recentSession = (yield call(
        requestSaga,
        RequestMethods.GET,
        `/users/${userInfo?.id}/sessions/recent?platform=web`,
        undefined,
        3,
      )) as {
        result: RecentSessionType[];
      };

      yield put(recentSessionsSliceActions.fetchRecentSessionsSuccess(recentSession.result));
    } catch (error) {
      yield put(recentSessionsSliceActions.fetchRecentSessionsSuccess([]));
      Logger.error(error);
    }
  }
}

export function* watchRecentSessionSaga() {
  yield takeEvery(recentSessionsActions.saveRecentSession.type, saveRecentSessionSaga);
  yield takeEvery(recentSessionsActions.fetchRecentSession.type, fetchRecentSessionSaga);
}
