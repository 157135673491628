import { isAudioPlayerRepeatEnabled } from '@AudioPlayer';
import { DynamicActivity, Serving, Track } from '@Model';
import {
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  getTrackVariationId,
  getTrackVariationUrl,
} from '@Music';
import { timerActions } from '@Timer';
import { getUser } from '@User';
import React, { createRef, Dispatch } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { throttle } from 'throttle-debounce';

import * as analyticsActions from '../../actions/analytics';
import * as currentTrackActions from '../../actions/currentTrack';
import * as sessionManagerActions from '../../actions/sessionManager';
import * as userActions from '../../actions/user';
import { DYNAMIC_PLAYER_ACTIVITY_PATH } from '../../constants';
import { trackTrackStartEvent } from '../../domains/Analytics/coreAnalytics';
import { CoreAnalyticsEventTypes, MentalStates } from '../../domains/Analytics/coreAnalytics.types';
import { TimerMode } from '../../domains/Session/components/TimerSettings/hooks/useTrackTimerModeChange';
import { getDynamicSession } from '../../domains/Session/lenses/useDynamicSession';
import { TimerDisplayTypes } from '../../domains/Timer/constants';
import { RootReducerType } from '../../reducers';
import { sessionManagerSliceActions } from '../../reducers/sessionManager';
import {
  AnalyticsSessionEvents,
  FetchMoreTracksState,
  SessionPlayStatus,
  AnalyticsEvents,
  CreateDynamicSession,
} from '../../types';
import { Analytics } from '../../utils/analytics';
import { getTimerMode } from '../../utils/getTimerMode';
import { Logger } from '../../utils/logger';
import {
  createListeningMinutesForPaywall,
  createTrackListeningMinutes,
} from './createTrackListeningMinutes';
import { logAudioPlayerError } from './lib/logAudioPlayerError';

const FETCH_MORE_TRACKS_THRESHOLD = 3;
const GET_STREAKS_DELAY = 4000;

interface AudioPlayerProps {
  activeGenres: string[];
  activeNeuralEffectLevels: string[];
  createDynamicSession(data: CreateDynamicSession): void;
  currentRoute: string;
  currentTrack: Track | Serving | null;
  dynamicActivity: DynamicActivity | null;
  endSession(): void;
  fetchMoreTracks(): void;
  fetchTrackStatus: FetchMoreTracksState;
  isRepeatEnabled: boolean;
  onTimerFinish(): void;
  pauseTrack(value: number): void;
  previousTrack(): void;
  playerVolume: number;
  resumeTrack(value: number): void;
  logTrackStartEvent(trackId: string, trackVariationId: string): void;
  getStreaks(): void;
  sessionLogEvent(event: AnalyticsSessionEvents): void;
  sessionPlayStatus: SessionPlayStatus;
  setCurrentTrack(track: Track | Serving): void;
  setCurrentTrackTimeStamp(value: number): void;
  setSessionPlayStatus(status: SessionPlayStatus): void;
  setDeepLinkReferrer(status: boolean): void;
  isDeepLinkReferrer?: boolean;
  skipTrack(params: {
    timestampAtSkip: number;
    shouldHonorRepeat?: boolean;
    shouldTrackSkip?: boolean;
  }): void;
  startSession(): void;
  startTimer: () => void;
  pauseTimer: () => void;
  timerFinished: boolean | undefined;
  trackEnded(): void;
  queue: (Track | Serving)[];
  queueHistory: (Track | Serving)[];
  timerMode: TimerMode;
  shouldAttemptAutoPlay: boolean;
  navigate: NavigateFunction;
  state?: { preventAutoSessionCreation?: boolean; startedFrom?: string };
  userMembership: RootReducerType['user']['membership'];
}

/**
 * The motivation for this component is to enable multiple player components to access the same audio state.
 * This component serves as a single source of truth for the various player components we may use throughout the app.
 */

class AudioPlayerDynamic extends React.Component<AudioPlayerProps> {
  private audioPlayer = createRef<HTMLMediaElement>();

  // Used to load inital track
  private audio: HTMLAudioElement | undefined;

  private checkpointIntervalId: number | undefined;
  private offlineIntervalId: number | undefined;
  private counterIntervalId: number | undefined;
  private currentTrack3sPlaybackLogged: boolean = false;

  private SPACEBAR = ' ';

  componentDidMount() {
    try {
      // Define player events to be used in player components throughout the app
      window.addEventListener('resume', this.handleResume);
      window.addEventListener('pause', this.handlePause);
      window.addEventListener('previous', this.handlePrevious);
      window.addEventListener('skip', this.handleSkip);
      window.addEventListener('skip-keep-play-state', this.handleSkip);
      window.addEventListener('session-end', this.handleEndSession);
      window.addEventListener('playlist-update', this.handlePlaylistUpdate);
      window.addEventListener('beforeunload', this.onBeforeUnload);

      //Start session when the first track has loaded - we pass this to our HTML ref component later
      this.audio = new Audio();
      this.audio.addEventListener('canplaythrough', this.handleTrackLoaded);

      this.audioPlayer.current?.addEventListener('error', this.handleStreamError, true);
      this.audioPlayer.current?.addEventListener('ended', this.handleTrackEnded);

      //Set persisted volume
      if (this.audioPlayer.current) {
        this.audioPlayer.current.volume = this.props.playerVolume;
      }

      document.addEventListener('keydown', this.handleKeyDown, false);

      if (Boolean(matchPath(DYNAMIC_PLAYER_ACTIVITY_PATH, this.props.currentRoute))) {
        this.initializeSession();
      }

      // media session handlers
      if ('mediaSession' in navigator) {
        navigator.mediaSession.setActionHandler('previoustrack', () => {
          this.handlePrevious();
        });

        navigator.mediaSession.setActionHandler('nexttrack', () => {
          this.handleSkip();
        });
      }
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to mount',
      });
    }
  }

  componentDidUpdate(prevProps: AudioPlayerProps) {
    try {
      const {
        currentTrack,
        fetchMoreTracks,
        fetchTrackStatus,
        playerVolume,
        queue,
        sessionPlayStatus,
        setSessionPlayStatus,
        timerFinished,
        activeNeuralEffectLevels,
        activeGenres,
        dynamicActivity,
      } = this.props;

      if (prevProps.playerVolume !== playerVolume) {
        this.audioPlayer.current!.volume = playerVolume;
      }

      if (sessionPlayStatus === 'RESUMING') {
        this.resumeAudio();
        setSessionPlayStatus('PLAYING');
        this.props.startTimer();
        return;
      }

      if (sessionPlayStatus === 'PLAYING') {
        this.counterIntervalStart();
        if (timerFinished) {
          this.handleTimerDone();
          this.props.onTimerFinish();
        }
      } else {
        this.counterIntervalEnd();
      }

      if (sessionPlayStatus === 'EMPTY' && this.audioPlayer.current && !currentTrack) {
        this.pauseAudio();
      }

      if (sessionPlayStatus === 'EMPTY' || sessionPlayStatus === 'UPDATING') {
        if (currentTrack && this.audioPlayer.current) {
          this.audio!.src = getTrackVariationUrl(currentTrack);

          // Preload next track
          if (queue.length) {
            const audio = new Audio();
            audio.src = getTrackVariationUrl(queue[0]);
          }
        }
      }

      // sends analytics event on each track start
      if (
        getTrackId(currentTrack) &&
        getTrackId(prevProps.currentTrack) !== getTrackId(currentTrack)
      ) {
        trackTrackStartEvent({
          activity: dynamicActivity?.displayValue || 'error',
          complexity: getTrackComplexity(currentTrack),
          trackGenre: getTrackGenreName(currentTrack),
          filterNel: activeNeuralEffectLevels.join(','),
          filterGenre: activeGenres.join(','),
          mentalState: dynamicActivity?.mentalState.id || 'error',
          trackNel: getTrackNeuralEffectLevel(currentTrack),
          timerMode: this.props.timerMode,
          trackName: getTrackName(currentTrack),
        });
        this.props.logTrackStartEvent(getTrackId(currentTrack), getTrackVariationId(currentTrack));
        this.currentTrack3sPlaybackLogged = false;
        setTimeout(() => {
          this.props.getStreaks();
        }, GET_STREAKS_DELAY);
      }

      if (
        prevProps.queue.length !== queue.length &&
        queue.length < FETCH_MORE_TRACKS_THRESHOLD &&
        fetchTrackStatus === 'idle' &&
        sessionPlayStatus !== 'EMPTY'
      ) {
        fetchMoreTracks();
      }

      const basePlayerRouteMatch = matchPath(DYNAMIC_PLAYER_ACTIVITY_PATH, this.props.currentRoute);

      // we should not reinitialize the session when route changes when e.g. there's active deepWork session and user navigates from homepage to player's deepWork activity.
      // so to check it, we check:
      // 1. if there was active dynamic activity from the redux
      // 2. we are on player's page with activity in the URL
      // 3. previous (i.e. redux) activity matches URL's activity
      const shouldPersistCurrentSession = Boolean(
        prevProps.dynamicActivity?.id &&
          basePlayerRouteMatch?.params.activityId &&
          prevProps.dynamicActivity?.id === basePlayerRouteMatch?.params.activityId,
      );

      // checks if URL has changes and if we are on /player/:activityId route
      const didActivityRouteChange =
        prevProps.currentRoute !== this.props.currentRoute && Boolean(basePlayerRouteMatch);

      if (didActivityRouteChange && !shouldPersistCurrentSession) {
        this.pauseAudio();
        this.initializeSession();
      }
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to update component',
      });
    }
  }

  componentWillUnmount() {
    try {
      // clear timerinterval
      this.counterIntervalEnd();

      // Clean up event listeners
      window.removeEventListener('playlist-update', this.handlePlaylistUpdate);
      window.removeEventListener('session-end', this.handleEndSession);
      window.removeEventListener('resume', this.handleResume);
      window.removeEventListener('pause', this.handlePause);
      window.removeEventListener('skip', this.handleSkip);
      window.removeEventListener('skip-keep-play-state', this.handleSkip);
      document.removeEventListener('keydown', this.handleKeyDown, false);
      navigator.mediaSession.setActionHandler('previoustrack', null);
      navigator.mediaSession.setActionHandler('nexttrack', null);

      this.audio?.removeEventListener('canplaythrough', this.handleTrackLoaded);
      this.audioPlayer.current?.removeEventListener('ended', this.handleTrackEnded);
      this.audioPlayer.current?.removeEventListener('error', this.handleStreamError);
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to unmount',
      });
    }
  }

  onBeforeUnload = () => {
    this.props.setSessionPlayStatus('STOPPED');
  };

  initializeSession = () => {
    try {
      const match = matchPath(DYNAMIC_PLAYER_ACTIVITY_PATH, this.props.currentRoute);
      if (!match || !match.params.activityId) return;
      if (this.props.state?.preventAutoSessionCreation) return;

      this.props.createDynamicSession({
        sessionDynamicActivityId: match.params.activityId,
        startedFrom: this.props.state?.startedFrom,
        navigate: this.props.navigate,
      });
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run ensureAudioPlays()',
      });
    }
  };

  counterIntervalStart = () => {
    const logger = (eventName: AnalyticsEvents, eventData: any) => {
      Analytics.logEventWithProperties(eventName, eventData);
    };

    const trackListeningMinutes = createTrackListeningMinutes({
      audioElement: this.audioPlayer.current,
      cohort: 'new library new service',
      getCurrentTrackMetaData: this.getCurrentTrackMetaData,
      logger,
      shouldIncludeDebugInformation: true,
    });

    const listeningMinutesForPaywall = createListeningMinutesForPaywall({
      audioElement: this.audioPlayer.current,
      navigate: this.props.navigate,
      userMembership: this.props.userMembership,
      currentRoute: this.props.currentRoute,
    });

    if (!this.counterIntervalId) {
      this.counterIntervalId = window.setInterval(() => {
        trackListeningMinutes();
        listeningMinutesForPaywall();
        this.updateIntervalTimer();
      }, 1000);

      this.updateIntervalTimer();
    }
  };

  counterIntervalEnd = () => {
    if (this.counterIntervalId) {
      clearInterval(this.counterIntervalId);
      this.counterIntervalId = undefined; //weirdly need this, or it won't clear ID.
    }
  };

  updateIntervalTimer = () => {
    const { setCurrentTrackTimeStamp, sessionPlayStatus } = this.props;

    // some events still come in while songs are transitioning, so we only want to see the ones
    // where the src in the current audio player matches the desired ones in props, and when we
    // are playing!

    // sometimes url is encoded on props.currentTrack sometimes not, we need to fix that
    // but for now we need to check both.
    const audioPlayerSrc = this.audioPlayer.current?.src;
    const decodedAudioPlayerSrc = decodeURIComponent(audioPlayerSrc || '');
    const trackVariationUrl = getTrackVariationUrl(this.props.currentTrack);
    const decodedTrackVariationUrl = decodeURIComponent(trackVariationUrl);

    const isPlaying = sessionPlayStatus === 'PLAYING';
    const isEventForCurrentSong =
      audioPlayerSrc === trackVariationUrl || decodedAudioPlayerSrc === decodedTrackVariationUrl;

    if (isPlaying && isEventForCurrentSong) {
      setCurrentTrackTimeStamp(this.audioPlayer.current?.currentTime || 0);
    }

    if (
      !this.currentTrack3sPlaybackLogged &&
      this.audioPlayer.current &&
      this.audioPlayer.current.currentTime >= 3
    ) {
      Analytics.logEventWithProperties(CoreAnalyticsEventTypes.Playback3SecondsSuccess, {
        trackName: getTrackName(this.props.currentTrack),
        dynamicActivity:
          this.props.dynamicActivity?.displayValue || 'No dynamic activity display value',
      });
      this.currentTrack3sPlaybackLogged = true;
    }
  };

  handleTrackLoaded = () => {
    try {
      const {
        currentTrack,
        sessionPlayStatus,
        setCurrentTrack,
        setSessionPlayStatus,
        setDeepLinkReferrer,
        startSession,
      } = this.props;
      // status will be EMPTY when starting a new session.
      if (currentTrack && sessionPlayStatus === 'EMPTY') {
        setCurrentTrack(currentTrack);
        this.audioPlayer.current!.src = getTrackVariationUrl(currentTrack);
        if (this.props.shouldAttemptAutoPlay) {
          const onSuccessfulPlayCallback = () => {
            startSession();
            this.props.startTimer();
          };
          this.resumeAudio(onSuccessfulPlayCallback);
        } else {
          setSessionPlayStatus('STOPPED');
        }
      }

      // status wil be UPDATING when a user changes their activity or
      // filters genres in the middle of a session.
      if (sessionPlayStatus === 'UPDATING' && currentTrack) {
        this.pauseAudio();

        // Made up numbers and bad setTimeout. Would be cool
        // if we added a callback to this.pauseAudio. The problem is
        // I want to wait until fadeOut is complete to begin new track.
        setTimeout(() => {
          this.audioPlayer.current!.src = getTrackVariationUrl(currentTrack);
          if (this.props.isDeepLinkReferrer) {
            setCurrentTrack(currentTrack);
            setSessionPlayStatus('STOPPED');
            setDeepLinkReferrer(false);
            this.props.pauseTimer();
            return;
          }
          this.resumeAudio();
          setCurrentTrack(currentTrack);
          setSessionPlayStatus('PLAYING');
          this.props.startTimer();
        }, 100);
      }
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to mount',
      });
    }
  };

  handleEndSession = () => {
    if (this.audioPlayer.current && this.audioPlayer.current.src) {
      this.audioPlayer.current.currentTime = 0;
    }

    this.props.endSession();
  };

  checkpointIntervalEnd = () => {
    this.checkpointIntervalId && clearInterval(this.checkpointIntervalId);
  };

  restartTrackFromOffline = () => {
    const { sessionPlayStatus, currentTrack } = this.props;

    this.offlineIntervalEnd();
    if (sessionPlayStatus === 'STOPPED' && currentTrack) {
      this.audioPlayer.current!.src = getTrackVariationUrl(currentTrack);
      this.handlePlay();
    }
  };

  offlineIntervalStart = () => {
    const { currentTrack } = this.props;

    if (!this.offlineIntervalId) {
      this.offlineIntervalId = window.setInterval(() => {
        this.audio = new Audio();
        this.audio.src = getTrackVariationUrl(currentTrack) || '';
        this.audio.addEventListener('canplaythrough', this.restartTrackFromOffline);
      }, 3000);
    }
  };

  offlineIntervalEnd = () => {
    this.offlineIntervalId && clearInterval(this.offlineIntervalId);
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const { sessionPlayStatus } = this.props;
    switch (event.key) {
      case this.SPACEBAR:
        // when the event is bubbled up from Input field, ignore the space bar press
        if ((event.target as Element).tagName !== 'INPUT') {
          event.preventDefault();

          if (sessionPlayStatus === 'PLAYING') {
            this.handlePause();
          } else if (sessionPlayStatus === 'PAUSED' || sessionPlayStatus === 'STOPPED') {
            this.handleResume();
          }
        }
        break;
      default:
        break;
    }
  };

  handleTrackEnded = () => {
    try {
      const { queue, isRepeatEnabled, skipTrack, trackEnded } = this.props;
      trackEnded();

      const shouldAdvanceToNextSong = Boolean(!isRepeatEnabled && queue.length);
      if (shouldAdvanceToNextSong) {
        this.audioPlayer.current!.src = getTrackVariationUrl(queue[0]);
      }

      this.audioPlayer.current!.play().catch(e => {
        logAudioPlayerError({
          error: e,
          message: 'The play() request was interrupted',
          level: 'info',
        });
      });
      skipTrack({ timestampAtSkip: 0, shouldHonorRepeat: true, shouldTrackSkip: false });
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handleTrackEnded()',
      });
    }
  };

  handleStreamError = (e: ErrorEvent) => {
    try {
      const { setSessionPlayStatus } = this.props;
      const debugData = {
        playerErrorCode: this.audioPlayer.current?.error?.code,
        playerErrorMessage: this.audioPlayer.current?.error?.message,
        audioErrorCode: this.audio?.error?.code,
        audioErrorMessage: this.audio?.error?.message,
        currentTrack: this.props.currentTrack,
        bufferedLength: this.audioPlayer.current?.buffered.length,
        currentSrc: this.audioPlayer.current?.currentSrc,
        currentTime: this.audioPlayer.current?.currentTime,
        ended: this.audioPlayer.current?.ended,
        networkState: this.audioPlayer.current?.networkState,
        paused: this.audioPlayer.current?.paused,
        playbackRate: this.audioPlayer.current?.playbackRate,
        readyState: this.audioPlayer.current?.readyState,
      };
      if (navigator.onLine) {
        const isFileUnavailable = debugData.audioErrorCode === 4;
        if (isFileUnavailable) {
          logAudioPlayerError({
            error: e,
            message: `[Critical] file not found on ${getTrackName(this.props.currentTrack)}`,
            extra: debugData,
          });
        } else {
          logAudioPlayerError({
            error: e,
            message: `stream error on ${getTrackName(this.props.currentTrack)}`,
            extra: debugData,
          });
        }
      } else {
        Logger.info('User went offline while streaming music.', debugData);
      }
      setSessionPlayStatus('STOPPED');
      this.offlineIntervalStart();
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handleStreamError()',
      });
    }
  };

  handlePlay = () => {
    try {
      const { setSessionPlayStatus } = this.props;

      setSessionPlayStatus('PLAYING');
      this.props.startTimer();
      this.resumeAudio();
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handlePlay()',
      });
    }
  };

  handleResume = () => {
    try {
      // set session manager state to PLAYING
      const trackProgress = this.audioPlayer.current?.currentTime ?? 0;

      this.props.resumeTrack(trackProgress);
      this.props.startTimer();
      this.resumeAudio();
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handleResume()',
      });
    }
  };

  handleTimerDone = () => {
    this.handlePause();
  };

  handlePause = () => {
    try {
      // set session manager state to PAUSED
      const trackProgress = this.audioPlayer.current?.currentTime ?? 0;

      this.props.pauseTrack(trackProgress);
      this.props.pauseTimer();

      this.pauseAudio();
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handlePause()',
      });
    }
  };

  handlePrevious = () => {
    try {
      const { currentTrack, queueHistory, previousTrack } = this.props;

      if (currentTrack && this.audioPlayer.current) {
        this.audioPlayer.current.pause();

        if (queueHistory.length && this.audioPlayer.current) {
          this.audioPlayer.current.src = getTrackVariationUrl(queueHistory[0]);
          this.handlePlay();
        }

        previousTrack();
      }
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handlePrevious()',
      });
    }
  };

  handleSkip = (e?: Event) => {
    try {
      const { currentTrack, queue, skipTrack, sessionPlayStatus } = this.props;
      const isPaused = sessionPlayStatus === 'PAUSED';

      if (currentTrack && this.audioPlayer.current) {
        const trackProgress = this.audioPlayer.current?.currentTime ?? 0;

        this.audioPlayer.current.pause();

        if (queue.length && this.audioPlayer.current) {
          this.audioPlayer.current.src = getTrackVariationUrl(queue[0]);
          if (e?.type !== 'skip-keep-play-state' || !isPaused) {
            this.handlePlay();
          }
        }

        skipTrack({ timestampAtSkip: trackProgress });
      }
    } catch (e) {
      logAudioPlayerError({
        error: e,
        message: 'failed to run handleSkip()',
      });
    }
  };

  handlePlaylistUpdate = () => {
    if (this.props.sessionPlayStatus === 'PLAYING') {
      this.pauseAudio();
    }
  };

  resumeAudio = (callback?: () => void) => {
    const { currentTrack, dynamicActivity } = this.props;

    if (!this.audioPlayer.current) return;

    Analytics.logEventWithProperties(CoreAnalyticsEventTypes.PlaybackStartAttempt, {
      trackName: getTrackName(currentTrack),
      dynamicActivity: dynamicActivity?.displayValue || 'No dynamic activity display value',
    });

    this.audioPlayer.current
      .play()
      .then(() => {
        if (callback) {
          callback();
        }
        Analytics.logEventWithProperties(CoreAnalyticsEventTypes.PlaybackSuccess, {
          trackName: getTrackName(currentTrack),
          dynamicActivity: dynamicActivity?.displayValue || 'No dynamic activity display value',
        });
      })
      .catch(error => {
        this.handlePause();

        Analytics.logEventWithProperties(CoreAnalyticsEventTypes.PlaybackFailure, {
          trackName: getTrackName(currentTrack),
          errorMessage: error,
          dynamicActivity: dynamicActivity?.displayValue || 'No dynamic activity display value',
        });
      });
  };

  pauseAudio = () => {
    if (this.audioPlayer.current) {
      this.audioPlayer.current.pause();
    }
  };

  getCurrentTrackMetaData = (): { [key: string]: string | boolean } => {
    const { currentTrack, dynamicActivity } = this.props;
    if (!currentTrack) return {};

    try {
      return {
        activity: dynamicActivity?.displayValue || 'error',
        complexity: getTrackComplexity(currentTrack),
        genre: getTrackGenreName(currentTrack) || 'error',
        isNewlyCreated: getTrackIsNewlyCreated(currentTrack),
        mentalState: dynamicActivity?.mentalState.displayValue || 'error',
        name: getTrackName(currentTrack),
        neuralEffectLevel: getTrackNeuralEffectLevel(currentTrack),
      };
    } catch (error) {
      return {};
    }
  };

  render() {
    return (
      <>
        <audio ref={this.audioPlayer} preload="auto" />
      </>
    );
  }
}

const mapStateToProps = (state: RootReducerType) => {
  const { sessionManager, ui, timer } = state;
  const session = getDynamicSession(state);
  const user = getUser(state);
  const timerMode = getTimerMode(
    sessionManager.sessionPlayType,
    timer.displayType === TimerDisplayTypes.Pomodoro,
  );

  return {
    activeGenres: session
      ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus].genreNames
      : [],
    activeNeuralEffectLevels: session
      ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus].neuralEffectLevels
      : [],
    currentTrack: state.music.currentTrack || null,
    dynamicActivity: sessionManager.sessionDynamicActivity,
    isDeepLinkReferrer: sessionManager.isDeepLinkReferrer,
    isRepeatEnabled: isAudioPlayerRepeatEnabled(state),
    fetchTrackStatus: sessionManager.fetchTrackStatus,
    playerVolume: ui.playerVolume,
    queue: state.music.queue,
    queueHistory: state.music.queueHistory,
    sessionPlayStatus: sessionManager.sessionPlayStatus,
    timerFinished: sessionManager.timerFinished,
    timerMode: timerMode,
    userMembership: state.user.membership,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createDynamicSession: (data: CreateDynamicSession) =>
      dispatch(sessionManagerActions.createDynamicSession(data)),
    endSession: () => dispatch(sessionManagerActions.endSession()),
    fetchMoreTracks: () => dispatch(sessionManagerActions.DYNAMIC_fetchMoreTracks({ version: 3 })),
    onTimerFinish: () => dispatch(sessionManagerActions.timerFinished()),
    pauseTrack: (value: number) => dispatch(sessionManagerActions.pauseTrack(value)),
    pauseTimer: () => dispatch(timerActions.pause()),
    previousTrack: () => dispatch(sessionManagerActions.previousTrack()),
    startTimer: () => dispatch(timerActions.start()),
    resumeTrack: (value: number) => dispatch(sessionManagerActions.resumeTrack(value)),
    sessionLogEvent: (event: AnalyticsSessionEvents) =>
      dispatch(analyticsActions.sessionLogEvent(event)),
    setCurrentTrack: (track: Track) => dispatch(currentTrackActions.setTrack(track)),
    setCurrentTrackTimeStamp: (value: number) =>
      dispatch(sessionManagerActions.setCurrentTrackTimeStamp(value)),
    setSessionPlayStatus: (status: SessionPlayStatus) =>
      dispatch(sessionManagerSliceActions.setSessionManagerPlayStatus(status)),
    setDeepLinkReferrer: (status: boolean) =>
      dispatch(sessionManagerSliceActions.setDeepLinkReferrer(status)),
    skipTrack: (params: {
      timestampAtSkip: number;
      shouldHonorRepeat?: boolean;
      shouldTrackSkip?: boolean;
    }) => dispatch(sessionManagerActions.skipTrack(params)),
    startSession: () => dispatch(sessionManagerActions.startSession()),
    trackEnded: () => dispatch(sessionManagerActions.trackEnded()),
    logTrackStartEvent: (trackId: string, trackVariationId: string) =>
      dispatch(analyticsActions.logTrackStartEvent({ trackId, trackVariationId })),
    getStreaks: throttle(1000 * 60 * 60, () => dispatch(userActions.getStreaks())), // only run every hour
  };
};

const WrappedPlayer = (props: Omit<AudioPlayerProps, 'navigate' | 'state'>) => {
  const navigate = useNavigate();
  const location = useLocation();

  return <AudioPlayerDynamic navigate={navigate} state={location.state} {...props} />;
};

const ConnectedAudioPlayer = connect(mapStateToProps, mapDispatchToProps)(WrappedPlayer);

export default ConnectedAudioPlayer;
