import React from 'react';
import * as S from './TimerInformation.styles';
import { TextBold, Text, FontColors } from '@Cortex';

const TimerInformation = () => {
  return (
    <S.Container>
      <S.Header>
        <TextBold size={20}>Timer Information</TextBold>
      </S.Header>
      <S.Content>
        <S.Section>
          <S.InfiniteIcon />
          <S.Information>
            <TextBold size={16}>Infinite Play</TextBold>
            <S.DescriptionText>
              Music plays continuously, with a timer that counts upwards.
            </S.DescriptionText>
          </S.Information>
        </S.Section>
        <S.Section>
          <S.TimerIcon />
          <S.Information>
            <TextBold size={16}>Set Timer</TextBold>
            <S.DescriptionText>
              Select a preset duration or set your own in minutes or hours. The music stops
              automatically when the timer counts down to zero.
            </S.DescriptionText>
          </S.Information>
        </S.Section>
        <S.Section>
          <S.IntervalIcon />
          <S.Information>
            <TextBold size={16}>Set Interval</TextBold>
            <S.DescriptionText>
              Alternate between work and rest periods with preset or custom intervals. A countdown
              timer tracks each phase. Choose a voiceover or chime to signal when to switch.
            </S.DescriptionText>
          </S.Information>
        </S.Section>
      </S.Content>
    </S.Container>
  );
};

export default TimerInformation;
