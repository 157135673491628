import { ReactNode } from 'react';

import CloseIcon from '../../../assets/images/close_icon.svg';
import * as S from './ProfileFormContainer.styles';

interface Props {
  title: string;
  description?: string;
  headerImage?: string;
  onClose: () => void;
  errorMessage?: string | null;
  keepTextCase?: boolean;
  maxWidth?: string;
  children: ReactNode | undefined;
}

export const ProfileFormContainer: React.FC<Props> = ({
  title,
  description,
  headerImage,
  onClose,
  children,
  errorMessage = null,
  keepTextCase,
  maxWidth,
}) => {
  return (
    <S.Container maxWidth={maxWidth}>
      <S.Header>
        <S.ImageButton type="button" onClick={onClose}>
          <S.Close src={CloseIcon} />
        </S.ImageButton>

        <S.Image src={headerImage} />

        <S.TextContainer>
          <S.Title keepTextCase={keepTextCase}>{title}</S.Title>

          {description ? <S.Description>{description}</S.Description> : null}
        </S.TextContainer>
      </S.Header>
      {children}
      <S.ErrorBox>
        <S.ErrorText>{errorMessage}</S.ErrorText>
      </S.ErrorBox>
    </S.Container>
  );
};
