import { Icon, MenuItemWithFill } from '@Cortex';
import { useMenuItems } from '@User';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import BrandLogo from '../assets/images/brand_logo.svg';

import CrossIcon from '../assets/images/close_icon.svg';
import { RootReducerType } from '../reducers';
import { getDynamicActivityPlayerPath } from '../utils/getDynamicActivityPlayerPath';

const ProfileContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  display: flex;
  width: 100%;
  position: relative;
  gap: 6rem;
  padding: 0 3rem 0;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    gap: 4rem;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 7rem 3rem 0;
    flex-direction: column;
    height: auto;
    gap: 3rem;
  }
`;
export const CloseIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const CloseButton = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 100%;
  transition: background 0.2s ease-in-out;
  z-index: 1;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    position: absolute;
  }
`;

export const LogoContainer = styled.div`
  position: fixed;
  z-index: 1;
  margin: 1.5rem;
  padding-top: 7px;
  top: 0;
  left: 0;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    position: absolute;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 740px;
  width: 75%;
  margin-top: 7rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
    max-width: initial;
    margin-top: 0;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  width: 260px;
  margin-top: 7rem;
  position: relative;
  flex-shrink: 0;
  > div {
    position: fixed;
    width: inherit;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
    min-width: auto;
    margin-top: 0;
    > div {
      position: relative;
    }
  }
`;

const MenuItemContainer = styled.div``;

export const ProfileScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems = useMenuItems();

  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const dynamicActivityId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.id,
  );
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);

  const handleClose = useCallback(() => {
    window.scrollTo(0, 0);
    if (!currentTrack) {
      return navigate(`/`);
    }
    if (dynamicActivityId) {
      navigate(getDynamicActivityPlayerPath(dynamicActivityId));
    }
  }, [sessionMentalStateId, currentTrack]);

  return (
    <>
      <ProfileContainer>
        <LogoContainer>
          <Icon size={56} src={BrandLogo} />
        </LogoContainer>
        <CloseButton data-testid="profileCloseButton" onClick={handleClose}>
          <CloseIcon alt="Close" src={CrossIcon} />
        </CloseButton>

        <MenuContainer>
          <Menu>
            {menuItems.map((item, index) => {
              const isLast = index === menuItems.length - 1;

              const menuItem = (
                <MenuItemWithFill
                  key={item.label}
                  hasTopDivider={item.hasTopDivider}
                  icon={item.icon}
                  isActive={location.pathname === item.urlPath}
                  testid={item.testid}
                  onClick={item.onClick}
                >
                  {item.href ? (
                    <a href={item.href} rel="noopener noreferrer" target="_blank">
                      {item.label}
                    </a>
                  ) : (
                    item.label
                  )}
                </MenuItemWithFill>
              );

              return isLast ? (
                menuItem
              ) : (
                <MenuItemContainer key={item.label}>{menuItem}</MenuItemContainer>
              );
            })}
          </Menu>
        </MenuContainer>

        <Content>
          <Outlet />
        </Content>
      </ProfileContainer>
    </>
  );
};
