import { useMemo } from 'react';

import { useNewlyReleasedTracks } from '../../../../../../api/modules/NewlyReleasedTracks';
import { useRecommendedTrack } from '../../../../../../api/modules/RecommendedTrack';
import { RecommendedTrack } from '../../../RecommendedTrack';
import { NewTracks } from '../NewTracks';
import * as S from './Featured.styles';
import { Serving, Track } from '@Model';

type Props = {
  onTrackClickMore: (track: Track | Serving) => void;
};

export const Featured = (props: Props) => {
  const {
    error: newTracksError,
    data: newTracksData,
    isFetchedAfterMount: isNewTracksFetched,
    isRefetching: isRefetchingNewTracks,
  } = useNewlyReleasedTracks();

  const {
    isRefetching: isRefetchingRecommended,
    data: recommendedTracksData,
    isFetchedAfterMount: isRecommendedFetched,
  } = useRecommendedTrack();

  const isDataReady =
    isNewTracksFetched &&
    isRecommendedFetched &&
    !isRefetchingRecommended &&
    !isRefetchingNewTracks;

  const newTracks = useMemo(() => newTracksData || [], [newTracksData]);
  const recommendedTracks = useMemo(() => {
    if (newTracks.length) {
      return (recommendedTracksData || []).slice(0, 1);
    }

    return recommendedTracksData || [];
  }, [recommendedTracksData, newTracks]);

  if (!isDataReady) {
    return <S.Loading />;
  }

  return (
    <>
      <RecommendedTrack tracks={recommendedTracks} onTrackClickMore={props.onTrackClickMore} />
      <NewTracks
        error={newTracksError}
        tracks={newTracks}
        onTrackClickMore={props.onTrackClickMore}
      />
    </>
  );
};
