import { TextSemiBold } from '@Cortex';
import styled, { keyframes } from 'styled-components';
import { ANIMATION_TIMING_MS, HEADER_HEIGHT, HEADER_PADDING } from './constants';
import { JUMP_BACK_IN_HEIGHT } from '../../domains/Session/components/JumpBackInModal/constants';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow: auto;
  }
`;

export const Wrapper = styled.div<{ selectedState: string; isJumpBackInVisible: boolean }>`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  transition:
    opacity ${ANIMATION_TIMING_MS}ms,
    transform 0.5s,
    filter 0.5s;
  // turns the opacity down during transition when a mental state is selected
  opacity: ${({ selectedState }) => (selectedState !== '' ? 0 : 1)};
  transform: ${({ isJumpBackInVisible }) => (isJumpBackInVisible ? 'scale(0.9)' : 'scale(1)')};
  filter: ${({ isJumpBackInVisible }) =>
    isJumpBackInVisible ? 'brightness(0.5)' : 'brightness(1)'};
  transform-origin: center;
  will-change: transform;
`;

export const Header = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  display: flex;
  padding: ${HEADER_PADDING}rem;
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const UserLogoContainer = styled(IconContainer)`
  justify-content: flex-end;
`;

export const Body = styled.div`
  animation: ${fadeInAnimation} 0.3s ease-out forwards;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    flex-direction: row;
    padding-bottom: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  }
`;

export const BodyDynamic = styled.div`
  animation: ${fadeInAnimation} 0.3s ease-out forwards;
  flex: 1;
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT + HEADER_PADDING * 2}rem);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RecommendedActivitiesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  max-width: min(80vw, 1120px);
  width: 100%;
  padding: 12px;
  margin-bottom: 0.8rem;
  > div {
    width: calc(25% - 18px);
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
    max-width: unset;
    > div {
      width: 100%;
    }
  }
`;

export const MentalStatesWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template: repeat(4, 1fr) / 1fr;

  > * {
    justify-self: stretch;
    align-self: stretch;
    height: auto;
    width: auto;
    max-height: unset;
    max-width: unset;
    min-height: 120px;
    p {
      font-size: 1.5rem;
      text-transform: capitalize;
      pointer-events: none;
    }
  }

  > :first-child {
    grid-area: 1 / 1;
  }
  > :not(:first-child) {
    grid-column: 1;
  }

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    max-width: min(80vw, 1120px);
    max-height: min(80vh, 700px);
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
    :first-child {
      grid-area: 1 / 1 / 4 / 2;
    }
    :not(:first-child) {
      grid-column: 2;
    }
  }
`;

export const JumpBackInContainer = styled.div`
  position: relative;
  height: ${JUMP_BACK_IN_HEIGHT};
`;

export const TitleWrapper = styled.div`
  max-width: min(80vw, 1120px);
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  opacity: 0.5;
  padding: 0 12px;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    max-width: unset;
  }
`;

export const Title = styled(TextSemiBold)`
  margin-right: 5px;
`;

export const MiniPlayerWrapper = styled.div`
  width: 100%;
  padding: 0 12px;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    max-width: min(80vw, 1120px);
  }
`;
