import { initializeAmplitudeSDK } from '@Analytics';
import isPropValid from '@emotion/is-prop-valid';
import {
  BRAZE_API_ENDPOINT,
  BRAZE_API_KEY,
  BROWSER_ENVIRONMENT,
  FACEBOOK_APP_ID,
  FACEBOOK_PIXEL_ID,
  GA_TRACKING_ID,
  SENTRY_DSN_KEY,
} from '@Globals';
import * as Braze from '@braze/web-sdk';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';
import GlobalStyles, { GlobalFontStyles, HelmetFontPreload } from './styles/global';
import theme from './styles/theme';
import { getBasicPageTitle } from './utils/getBasicPageTitle';
import { Logger } from './utils/logger';

console.log(`%cVersion #${import.meta.env.BROWSER_APP_VERSION}`, 'color: #937ae6');

declare global {
  const AppleID: any;
  const FB: any;
  const ga: any;
  const google: typeof import('google-one-tap');
}

try {
  Sentry.init({
    dsn: SENTRY_DSN_KEY,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/5
      'Object Not Found Matching Id', // this comes from a microsoft crawler, we can ignore
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
    ],
    integrations: [new Integrations.BrowserTracing()],
    release: `web-app@${import.meta.env.BROWSER_APP_VERSION}`,
    tracesSampleRate: BROWSER_ENVIRONMENT === 'production' ? 0.1 : 1.0,
    normalizeDepth: 10,
    denyUrls: [
      /// https://docs.sentry.io/clients/javascript/tips/
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
} catch (e) {
  // nothing to do here if sentry is blocked, we will never know!
}

initializeAmplitudeSDK();

try {
  Braze.initialize(BRAZE_API_KEY, {
    baseUrl: BRAZE_API_ENDPOINT,
    allowUserSuppliedJavascript: true,
  });
} catch (e) {
  Logger.error(new Error('Failed to initialize braze sdk.'), { reason: e });
}

try {
  ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, {
    autoConfig: false,
    debug: BROWSER_ENVIRONMENT === 'production' ? false : true,
  });
} catch (e) {
  Logger.error(new Error('Failed to initialize facebook sdk.'), { reason: e });
}

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyleSheetManager shouldForwardProp={isPropValid}>
          <ThemeProvider theme={theme}>
            <React.StrictMode>
              <Helmet link={HelmetFontPreload} title={getBasicPageTitle()}>
                <style>{GlobalFontStyles}</style>
                <script>
                  {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','https://cdn.brain.fm/js/${BROWSER_ENVIRONMENT}.js','ga');

                ga('create', '${GA_TRACKING_ID}', 'auto');
                ga('send', 'pageview');`}
                </script>
                <script
                  nonce="BT4ZbEUB"
                  src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=${FACEBOOK_APP_ID}`}
                ></script>
              </Helmet>
              <GlobalStyles />
              <BrowserRouter>
                <App />
                <ToastContainer />
              </BrowserRouter>
            </React.StrictMode>
          </ThemeProvider>
        </StyleSheetManager>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
