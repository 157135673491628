import { InputField, Text, FontFamilies, FontColors, TextSemiBold } from '@Cortex';
import { Assets } from '../../../../../../../../utils/assets';
import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  background: #0d0c11;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
`;

export const StartContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  gap: 0.5rem;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const StartInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    justify-content: flex-start;
  }
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const YearlyPriceTextContainer = styled.div`
  flex: 1;
  position: relative;
  margin-left: 6px;
  margin-bottom: 0.5rem;
`;

export const YearlyPriceText = styled(Text)`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${FontColors.WhiteTransparent};
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 300px;
`;

export const BelowCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;
`;

export const ErrorWrapper = styled.div`
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0.07);
  padding: 0.2rem;
  border-radius: 1rem;
`;

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    max-width: 750px;
    margin: 0 auto;
  }
`;

export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const PaymentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1250px;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorMessage = styled.p`
  margin: 0.5rem;
  font-size: 0.75rem;
  color: red;
  text-align: center;
`;

export const NameInputLabel = styled.label<{ isError?: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.9rem;
  color: ${({ isError = false }) => (isError ? 'red' : '#fff')};
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
`;
export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const CardBox = styled.div`
  background: url(${Assets.images.circleBlurTopBg.url}), #0d0c11;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 1.5rem;
  padding: 2.5rem 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  max-width: 612px;
  width: 100%;
  flex: 1;

  &::after {
    position: absolute;
    content: '';
    width: 200%;
    height: 200%;
    background-image: url(${Assets.images.allGradientCircles.url});
    background-size: contain;
    background-repeat: no-repeat;
    top: -30%;
    left: -50%;
    z-index: -1;
    isolation: isolate;
  }
`;

export const TrialInfo = styled.div`
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  font-family: ${FontFamilies.Bold};
`;
export const PlanDescription = styled.div`
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  font-family: ${FontFamilies.Regular};
  margin-bottom: 1rem;
`;

export const NameInput = styled(InputField)<{ isFocused: boolean }>`
  background: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  margin: 0;
  height: 3.325rem;
  background: #0d0c11;
  transition:
    box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;

  ${({ isFocused }) => {
    return isFocused
      ? css`
          box-shadow: 0 0 0 0.5px #ffffff;
          border: 1px solid #ffffff;
        `
      : css`
          border: 1px solid rgba(255, 255, 255, 0.2);
        `;
  }}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    font-size: 1rem;
  }
`;

export const NameInputContainer = styled.div`
  border-radius: 0.75rem;
  margin-bottom: 1rem;
`;

export const PoweredByImage = styled.img`
  position: absolute;
  width: 120px;
  right: 0;
  top: 0;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    position: relative;
    align-self: flex-end;
    margin-bottom: 1rem;
  }
`;

export const CouponContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  display: flex;
  flex-direction: column;
`;

export const CouponGiftCardContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const CouponSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const CouponSectionInputWrapper = styled.div`
  flex: 3;
`;

export const CouponTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const CouponText = styled(TextSemiBold)`
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
`;

export const CouponButton = styled.button`
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  margin-left: 8px;
  font-family: ${FontFamilies.Regular};
`;

export const SubscriptionInfoText = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: ${FontFamilies.Regular};
  color: rgba(255, 255, 255, 0.5);
  max-width: 612px;
  width: 100%;
  align-self: center;
`;
