import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div<{ animationState: TransitionStatus }>`
  position: absolute;
  z-index: 999;
  right: 2rem;
  bottom: 8.5rem;
  padding: 1rem 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(0.5rem)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(0.5rem)`;
    }
  }};

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.5;
      case 'exited':
        return 0;
    }
  }};
`;

export const ContentWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  min-width: 380px;
  padding-bottom: 1.5rem;

  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(34px);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  padding: 1.5rem;
`;
export const ContentSection = styled.div`
  padding: 0 1.5rem;
`;

export const HeaderText = styled.div`
  display: flex;
  gap: 0.4rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  align-items: center;
`;
export const DescriptionText = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1.5rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
`;

export const Share = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  text-align: center;
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const StreaksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Separator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  margin: 1.5rem 0;
`;

export const VerticalSeparator = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.2);
`;
