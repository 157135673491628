import * as S from './styles';
import { RootReducerType } from '../../../../../../../../reducers';
import { useSelector } from 'react-redux';

export type Props = {
  genreType: string;
  onClick: () => void;
};

export const GENRES_PLAY_ALL_TEST_ID = 'genres-play-all';

export const GenrePlayAllCard = ({ genreType, onClick }: Props) => {
  const mentalState = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState,
  );

  return (
    <S.Container data-testid={GENRES_PLAY_ALL_TEST_ID} onClick={onClick}>
      <S.InnerContainer>
        <S.TextContainer>
          <S.Title>Play All</S.Title>
          <S.Description>
            Start a listening session that will shuffle all{' '}
            <S.DescriptionBold>{genreType}</S.DescriptionBold>{' '}
            <S.DescriptionBold>{mentalState?.displayValue}</S.DescriptionBold> tracks
          </S.Description>
          <S.Image src={mentalState?.playImageUrl} />
        </S.TextContainer>
      </S.InnerContainer>
    </S.Container>
  );
};
