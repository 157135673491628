export const Queries = {
  getDynamicMentalStates: ['getDynamicMentalStates'],
  getFastSubscriptionInfo: ['getFastSubscriptionInfo'],
  getDynamicActivitiesByMentaStateId: (mentalStateId: string) => [
    'getDynamicMentalStates',
    mentalStateId,
  ],
  getGenreNamesByMentalState: (mentalStateId: string) => ['genreNamesByMentalState', mentalStateId],
  getGenreNamesByDynamicMentalState: (dynamicMentalStateId: string) => [
    'genreNamesByDynamicMentalState',
    dynamicMentalStateId,
  ],
  getMoodsByDynamicMentalState: (dynamicMentalStateId: string) => [
    'moodsByDynamicMentalState',
    dynamicMentalStateId,
  ],
  getGenreNamesByActivity: (activityId: string) => ['genreNamesByActivity', activityId],
  getSimilarTracks: (trackId: string) => ['similarTracks', trackId],
  getRecentTracks: (userId: string, dynamicMentalStateId: string) => [
    'recentTrack',
    userId,
    dynamicMentalStateId,
  ],
  getTracksByGenre: (genreName: string, dynamicMentalStateId: string) => [
    'tracksByGenre',
    genreName,
    dynamicMentalStateId,
  ],
  getGiftCardInfo: (cardCode: string) => ['getGiftCardInfo', cardCode],
  getPromotions: ['getPromotions'],
  getUserTeams: (userId: string) => [userId, 'teams'],
  getTeamDataById: (teamId: string) => ['teams', teamId],
  getPublicTeamData: (teamId: string) => ['public', 'teams', teamId],
  getRecommendedTrack: (userId: string) => ['recommendedTrack', userId],
  getNewlyReleasedTracks: (mentalStateId: string) => ['newlyReleasedTracks', mentalStateId],
  postRedeemGiftCard: (cardCode: string) => ['postRedeemGiftCard', cardCode],
  postJoinTeam: (teamId: string) => ['postJoinTeam', teamId],
  postVerifyEmail: ['postVerifyEmail'],
  postEnableSubscriptionAutoRenew: ['postEnableSubscriptionAutoRenew'],
  postCreateFastSubscription: ['postCreateFastSubscription'],
  resendVerificationEmail: ['resendVerificationEmail'],
};
