import { useCallback, useState } from 'react';
import { WhatsNew } from '../types';
import { WHATS_NEW_ENTRIES } from '../entries';
import { LAST_SEEN_WHATS_NEW_ENTRY_ID_LOCAL_STORAGE_KEY } from '../constants';

export function useWhatsNew(): WhatsNew {
  const [hasNewUpdates, setHasNewUpdates] = useState(getHasNewUpdates());

  const markAsRead = useCallback(() => {
    markAsReadInStorage();
    setHasNewUpdates(false);
  }, []);

  return {
    hasNewUpdates,
    markAsRead,
    entries: WHATS_NEW_ENTRIES,
  };
}

function getHasNewUpdates(): boolean {
  const mostRecentEntry = WHATS_NEW_ENTRIES[0];
  const mostRecentlySeenEntryId = localStorage.getItem(
    LAST_SEEN_WHATS_NEW_ENTRY_ID_LOCAL_STORAGE_KEY,
  );

  const hasNotSeenMostRecentEntry = mostRecentlySeenEntryId !== mostRecentEntry.id;
  const shouldAlertForMostRecentEntry = mostRecentEntry.shouldAlert;
  return hasNotSeenMostRecentEntry && shouldAlertForMostRecentEntry;
}

export function markAsReadInStorage(): void {
  const mostRecentEntryId = WHATS_NEW_ENTRIES[0].id;
  localStorage.setItem(LAST_SEEN_WHATS_NEW_ENTRY_ID_LOCAL_STORAGE_KEY, mostRecentEntryId);
}
