import { ReactNode, useState } from 'react';

import * as S from './FullTooltip.styles';

export type Props = {
  icon: ReactNode;
  children: ReactNode;
};

export function FullTooltip({ icon, children }: Props) {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <S.Container>
      <S.Icon onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)}>
        {icon}
      </S.Icon>
      <S.Tooltip visible={showToolTip}>{children}</S.Tooltip>
    </S.Container>
  );
}
