import { useGetAvailableStripeCouponPromotion } from '@Memberships';
import { useSearchParams } from 'react-router-dom';
import { useIsExtendedPromoAvailable } from '../../PaywallModal/hooks/useIsExtendedPromoAvailable';
import {
  PROMO_QUERY_PARAM_KEY,
  DEFAULT_EXTENDED_YEARLY_TRIAL_PERIOD,
  DEFAULT_TRIAL_LENGTH,
} from '../constants';
import { useEligibleForTrial } from './useEligibleForTrial';

export const useGetTrialLengths = () => {
  const isEligibleForTrial = useEligibleForTrial();
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const [params] = useSearchParams();
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();
  const extendedYearlyTrialPeriodInDays = Number(
    params.get(PROMO_QUERY_PARAM_KEY) || DEFAULT_EXTENDED_YEARLY_TRIAL_PERIOD,
  );

  if (!isEligibleForTrial) {
    // bypassing multiple trial protection for users who came from extended promo link
    if (isExtendedPromoAvailable) {
      return {
        ...DEFAULT_TRIAL_LENGTH,
        yearly: extendedYearlyTrialPeriodInDays,
      };
    }

    return {
      yearly: 0,
      monthly: 0,
    };
  }

  if (availablePromotion) {
    // no extended trial available for promo
    return DEFAULT_TRIAL_LENGTH;
  }

  return {
    ...DEFAULT_TRIAL_LENGTH,
    // extended only for yearly plans
    yearly: isExtendedPromoAvailable
      ? extendedYearlyTrialPeriodInDays
      : DEFAULT_TRIAL_LENGTH.yearly,
  };
};
