import {
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackName,
  getTrackNeuralEffectLevel,
} from '@Music';
import { showToast } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga } from '../../../sagas/httpRequest';
import { Analytics } from '../../../utils/analytics';
import { Logger } from '../../../utils/logger';
import { trackDislikeTrackEvent } from '../../Analytics/coreAnalytics';
import { MentalStates } from '../../Analytics/coreAnalytics.types';
import { addToDislikes } from '../actions';
import { getTrackDynamicMentalStateDisplayValue } from '../lenses/getTrackDynamicMentalStateDisplayValue';
import { musicActions } from '../reducer';

type Response = {
  status: number;
  result: { trackId: string }[];
};

export function* addToDislikesSaga(action: Action) {
  if (addToDislikes.match(action)) {
    try {
      yield put(musicActions.addToDislikes(action.payload.track));
      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );
      const dynamicActivityDisplayValue: string = yield select(
        (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.displayValue,
      );

      showToast("We'll skip this track and never play it again!");

      const response = (yield requestSaga(
        RequestMethods.POST,
        `/users/${info?.id}/dislikes?version=3`,
        {
          trackId: getTrackId(action.payload.track),
        },
        2,
      )) as unknown as Response;

      if (response.status === 200) {
        try {
          const id = getTrackId(action.payload.track);
          const genreName = getTrackGenreName(action.payload.track);
          const mentalStateDisplayValue = getTrackDynamicMentalStateDisplayValue(
            action.payload.track,
          );
          const complexityDisplayValue = getTrackComplexity(action.payload.track);
          const neuralEffectLevelDisplayValue = getTrackNeuralEffectLevel(action.payload.track);
          const name = getTrackName(action.payload.track);

          Analytics.logEventWithProperties('dislike_track', {
            id,
            name,
            genre: genreName || 'error',
            activity: dynamicActivityDisplayValue || 'error',
            mentalState: mentalStateDisplayValue || 'error',
            complexity: complexityDisplayValue,
          } as any);
          trackDislikeTrackEvent({
            activity: dynamicActivityDisplayValue,
            complexity: complexityDisplayValue,
            trackGenre: genreName,
            mentalState: mentalStateDisplayValue as MentalStates,
            trackNel: neuralEffectLevelDisplayValue,
            trackName: name,
          });
        } catch (error) {
          Logger.error(new Error('addToDislikesSaga(): unable to track'), {
            reason: error,
          });
        }
      } else {
        yield put(musicActions.removeFromDislikes(getTrackId(action.payload.track)));
      }
    } catch (error) {
      yield put(musicActions.removeFromDislikes(getTrackId(action.payload.track)));
      Logger.error(error);
    }
  }
}
