import { useEffect } from 'react';

import * as S from './GreenPulse.styles';
import lottie from 'lottie-web';
import greenPulse from '../assets/green-pulse.json';

export const GreenPulse = () => {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById('green-pulse') as HTMLElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: greenPulse,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });

    const onComplete = () => {
      animation.setSpeed(0.5);
    };

    animation.addEventListener('complete', onComplete);

    return () => {
      animation.removeEventListener('complete', onComplete);
      animation.destroy();
    };
  }, []);

  return <S.GreenPulse id="green-pulse" />;
};
