import styled from 'styled-components';

export const HeaderIconLabel = styled.span`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  bottom: -14px;
  opacity: 0;
  transform: scale(0.4);
  transition: all 0.3s ease-in-out;
`;

export const Container = styled.div<{ isActive: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover > ${HeaderIconLabel} {
    bottom: -20px;
    opacity: ${({ isActive }) => (isActive ? 0 : 1)};
    transform: scale(1);
  }
  padding: 8px;
  border-radius: 100%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }

  ${({ isActive }) => (isActive ? 'background: rgba(255, 255, 255, 0.1)' : undefined)}
`;
