import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: static;
  display: flex;
`;

export const Icon = styled.div`
  display: flex;
`;

export const Tooltip = styled.div<{ visible: boolean; width?: number }>`
  position: absolute;
  top: 5%;
  right: 0;
  left: 0;
  transform: none;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  z-index: ${({ visible }) => (visible ? '100' : '-1')};
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  padding: 2rem;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  backdrop-filter: blur(36px);
  -webkit-backdrop-filter: blur(36px);
`;
