import { Button, ButtonVariants, FormInput } from '@Cortex';
import { NOOP } from '@Globals';
import React, { ChangeEvent, forwardRef, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';

import * as S from './Cancellation.styles';
import { RadioButton } from './components/RadioButton';
import { ProfileFormContainer } from '../../../../../shared/ProfileFormContainer';
import FeedbackHeader from '../assets/feedback_header.svg';
import { OTHER_REASON, PAID_SUBSCRIBER_SURVERY, TRIAL_SUBSCRIPBER_SURVERY } from './constants';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../../reducers';

interface Props {
  onClose?: () => void;
  onContinue?: (value: string) => void;
  animationState: TransitionStatus;
}
// seven days in milliseconds
const SEVEN_DAYS_MS = 1000 * 60 * 60 * 24 * 7;

const isSubscriberPaying = (membershipInfo: RootReducerType['user']['membership']) => {
  if (!membershipInfo) return false;

  const createdAt = membershipInfo.createdAt;
  const now = Date.now();
  const trialPeriod = new Date(createdAt).getTime() + SEVEN_DAYS_MS;

  return now > trialPeriod;
};

export const Cancellation = forwardRef<HTMLDivElement, Props>(
  ({ onClose = NOOP, onContinue = NOOP, animationState }, ref) => {
    const [cancellationReason, setCancellationReason] = useState<string | null>(null);
    const [customReason, setCustomReason] = useState<string | null>(null);
    const membershipInfo = useSelector((state: RootReducerType) => state.user.membership);

    const handleChange = (text: string) => {
      setCancellationReason(text);
    };
    const handleChangeCustomReason = (evt: ChangeEvent<HTMLInputElement>) => {
      setCustomReason(evt.target.value);
    };

    return (
      <S.Container ref={ref} animationState={animationState}>
        <ProfileFormContainer
          description="Please help us understand how we can improve."
          headerImage={FeedbackHeader}
          keepTextCase
          maxWidth="32rem"
          title="Reason for Cancellation"
          onClose={onClose}
        >
          <form
            onSubmit={evt => {
              if (cancellationReason) {
                evt.preventDefault();

                if (cancellationReason === OTHER_REASON) {
                  onContinue(customReason || cancellationReason);
                  return;
                }

                onContinue(cancellationReason);
              }
            }}
          >
            <S.Body>
              {isSubscriberPaying(membershipInfo)
                ? PAID_SUBSCRIBER_SURVERY.map((text, i) => {
                    const first = i === 0;
                    const last = i === PAID_SUBSCRIBER_SURVERY.length - 1;
                    const variant = first ? 'first' : last ? 'last' : 'default';

                    return (
                      <RadioButton
                        key={`paid_${text}${i}`}
                        selected={cancellationReason === text}
                        testid={`cancelReason${i}`}
                        text={text}
                        variant={variant}
                        onSelect={handleChange}
                      />
                    );
                  })
                : TRIAL_SUBSCRIPBER_SURVERY.map((text, i) => {
                    const first = i === 0;
                    const last = i === PAID_SUBSCRIBER_SURVERY.length - 1;
                    const variant = first ? 'first' : last ? 'last' : 'default';

                    return (
                      <RadioButton
                        key={`trial_${text}${i}`}
                        selected={cancellationReason === text}
                        testid={`cancelReason${i}`}
                        text={text}
                        variant={variant}
                        onSelect={handleChange}
                      />
                    );
                  })}
            </S.Body>
            {cancellationReason === OTHER_REASON ? (
              <S.CancellationReason>
                <FormInput label="Tell us more" onChange={handleChangeCustomReason} />
              </S.CancellationReason>
            ) : null}
            <S.Footer>
              <S.ButtonWrapper>
                <Button
                  isFullWidth
                  keepTextCase
                  type="button"
                  variant={ButtonVariants.Secondary}
                  onClick={onClose}
                >
                  TRY IT AGAIN
                </Button>
              </S.ButtonWrapper>
              <S.ButtonWrapper>
                <Button
                  data-testid="confirmCancelSubscriptionButton"
                  disabled={cancellationReason === null}
                  isFullWidth
                  keepTextCase
                  type="submit"
                  variant={ButtonVariants.Base}
                >
                  PROCEED WITH CANCELLATION
                </Button>
              </S.ButtonWrapper>
            </S.Footer>
          </form>
        </ProfileFormContainer>
      </S.Container>
    );
  },
);
