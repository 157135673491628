import { AnalyticsEvents } from '../../types';

// TODO convert to enum
export type ModalType =
  | 'appMessage'
  | 'paywall'
  | 'verifyEmail'
  | 'profile'
  | 'song'
  | 'timer'
  | 'updateStripePaymentMethod'
  | 'upgradeStripeSubscription'
  | 'subscriptionSuccess'
  | 'renewSubscription'
  | 'firstSession'
  | 'fastSubscription'
  | null;
export interface UiStateType {
  isReferAFriendDismissed: boolean;
  isPlayerAnimationEnabled: boolean;
  modalType: ModalType;
  playerVolume: number;
  sessionFinished: boolean;
  sessionSidebarContent: SessionSidebarContent | null;
  sessionSidebarContentOrigin?: string;
  explorePanelTab: ExplorePanelTab | null;
  shouldShowFocusPersonalizationQuiz: boolean;
  shouldShowProTipsModal: boolean;
  shouldShowNELTipsModal: boolean;
  shouldShowFavoriteModal: boolean;
  successModal: SuccessModalType;
}

export enum SessionSidebarContent {
  Activity = 'Activity',
  ExploreMusic = 'ExploreMusic',
  Preferences = 'Preferences',
  TimerSettings = 'TimerSettings',
}

export enum ExplorePanelTab {
  Featured = 'Featured',
  Genres = 'Genres',
  Favorites = 'Favorites',
  Recent = 'Recent',
}

export type SuccessModalType = {
  iconSrc?: string | null;
  isOpen: boolean;
  isDismissable?: boolean;
  title?: string | null;
  description?: string | null | string[];
  actions?: SuccessModalTypeAction[];
};

export type SuccessModalTypeAction = {
  analyticsEvent?: AnalyticsEvents;
  action: any;
  navigateTo?: string;
  navigateState?: {
    [key: string]: any;
  };
  text: string;
  type: 'primary' | 'secondary';
};
