import { Serving, Track } from '@Model';
import {
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  TrackInformationCard,
} from '@Music';

import * as S from './RecommendedTrack.styles';
import { getTrackRelatedActivities } from '../../lenses/getTrackRelatedActivities';

interface Props {
  tracks: ((Track | Serving) & {
    isFavorited: boolean;
    onAddToFavorites: () => void;
    onClickPlay: () => void;
    onClickMore: () => void;
    onRemoveFromFavorites: () => void;
  })[];
  testId?: string;
  onClick: (value: Serving) => void;
}

export const RecommendedTrackDisplay = ({ tracks }: Props) => {
  if (!tracks.length) return null;

  return (
    <S.Container data-testid="recommendedTrackCard">
      <S.Title size="1.5rem">recommended for you</S.Title>

      {tracks.map(track => (
        <S.CardContainer key={getTrackId(track)}>
          <TrackInformationCard
            hasMultipleNELs={getTrackHasMultipleNELs(track)}
            imageUrl={getTrackImageUrl(track)}
            isFavorited={track.isFavorited}
            isNewlyCreated={getTrackIsNewlyCreated(track)}
            moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
            neuralEffectLevel={getTrackNeuralEffectLevel(track)}
            relatedActivities={getTrackRelatedActivities(track)}
            subtitle={getTrackGenreName(track)}
            title={getTrackName(track)}
            onAddToFavorites={track.onAddToFavorites}
            onClick={track.onClickPlay}
            onClickMore={track.onClickMore}
            onRemoveFromFavorites={track.onRemoveFromFavorites}
          />
        </S.CardContainer>
      ))}
    </S.Container>
  );
};
