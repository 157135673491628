import { Icon } from '@Cortex';
import { HTMLAttributes } from 'react';

import { Assets } from '../../../../utils/assets';
import * as S from './ProfileButton.styles';
import { GreenPulse } from '../../../../components/session/components/SessionHeader/components/GreenPulse';

interface Props extends HTMLAttributes<HTMLDivElement> {
  hasNotification?: boolean;
  isActive: boolean;
}

export const ProfileButton = ({ isActive, ...props }: Props) => {
  return (
    <S.Container isActive={isActive} {...props} data-testid="profile-button">
      {props.hasNotification ? <GreenPulse /> : null}
      <Icon isSquare size={32} src={Assets.icons.profile.url} />
      <S.HeaderIconLabel>profile</S.HeaderIconLabel>
    </S.Container>
  );
};
