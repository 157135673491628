import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Image = styled.img`
  border-radius: 12px;
  height: 96px;
  margin-right: 24px;
  width: 96px;
  transition: transform 0.15s ease-in-out;
  transform-origin: center;
  object-fit: cover;
  aspect-ratio: 1 / 1;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    border-radius: 1rem;
    height: 64px;
    width: 64px;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    border-radius: 0.75rem;
    margin-right: 1rem;
    height: 40px;
    width: 40px;
  }
`;

export const InformationContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const NeuralEffectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-bottom: 0.25rem;
  }
`;

export const NeuralEffectLevelIcon = styled.div`
  margin-right: 0.5rem;
`;

export const NeuralEffectText = styled.div`
  color: white;
  font-family: TTNormsPro-Regular;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 0.75rem;
  }
`;

export const PillContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-bottom: 0.25rem;
  }
`;

export const GenrePill = styled.div`
  display: flex;
  height: 1.25rem;
  padding: 0.3125rem 0.75rem 0.1875rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 0.5rem;
`;

export const DetailPill = styled.div`
  display: flex;
  height: 1.25rem;
  padding: 0.3125rem 0.75rem 0.1875rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7px);
`;

export const PillText = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-family: TTNormsPro-Regular;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const TrackLabel = styled.div`
  font-size: 9px;
  letter-spacing: 1px;
  left: 4px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  padding: 1px 3px;
  position: relative;
  text-transform: uppercase;
`;

export const Title = styled.div`
  align-items: center;
  color: white;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  transform-origin: center;
  transition: transform 0.15s ease-in-out;
  width: fit-content;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
`;

export const TrackActions = styled.div`
  display: flex;
  gap: 1.25rem;
`;

export const TrackActionButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  opacity: 0.75;
  cursor: pointer;
  margin-right: 1rem;
  outline: none;
  width: fit-content;
`;
