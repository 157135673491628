import { Icon } from '@Cortex';
import { ActivitySelector } from '@Session';
import { ProfileWidget } from '@User';
import { useNavigate } from 'react-router-dom';

import ArrowLeftIcon from '../../../../assets/images/arrow_left_icon.svg';
import { Assets } from '../../../../utils/assets';
import { Widget } from './components/Widget';
import * as S from './SessionHeader.styles';
import { trackPlayerArrowNavigateBack } from '../../../../domains/Analytics/coreAnalytics';

type Props = {
  onClickExploreMusic?: () => void;
  onClickPreferences?: () => void;
  onClickTimerSettings?: () => void;
};

export const SessionHeader = (props: Props) => {
  const navigate = useNavigate();

  const handleBackArrowClick = () => {
    trackPlayerArrowNavigateBack();
    navigate('/', { replace: true });
  };

  return (
    <S.Header>
      <S.LeftContainer>
        <S.BackButtonContainer>
          <S.HeaderIconContainer onClick={handleBackArrowClick}>
            <Icon isSquare size={24} src={ArrowLeftIcon} />
            <S.HeaderIconLabel>home</S.HeaderIconLabel>
          </S.HeaderIconContainer>
        </S.BackButtonContainer>
        <S.MobileLogoContainer>
          <Icon size={24} src={ArrowLeftIcon} onClick={handleBackArrowClick} />
        </S.MobileLogoContainer>

        <ActivitySelector />
      </S.LeftContainer>

      <S.RightContainer>
        <S.CallToActionWidgetContainer>
          <Widget />
        </S.CallToActionWidgetContainer>
        <S.HeaderIconContainer data-testid="exploreMusicButton" onClick={props.onClickExploreMusic}>
          <Icon isSquare size={32} src={Assets.icons.explore.url} />
          <S.HeaderIconLabel>explore</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        <S.HeaderIconContainer data-testid="preferencesButton" onClick={props.onClickPreferences}>
          <Icon isSquare size={32} src={Assets.icons.musicPreferences.url} />
          <S.HeaderIconLabel>preferences</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        <S.HeaderIconContainer
          data-testid="timerSettingsButton"
          onClick={props.onClickTimerSettings}
        >
          <Icon isSquare size={32} src={Assets.icons.timerSettings.url} />
          <S.HeaderIconLabel>timer</S.HeaderIconLabel>
        </S.HeaderIconContainer>

        <ProfileWidget />
      </S.RightContainer>
    </S.Header>
  );
};
