import { EffectLevels, Track, Serving } from '@Model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { getTrackId, getTrackVariationId } from '../lenses';
import { migrate } from './migrations';

export type MusicState = {
  activityFilters: string[];
  currentTrack: Track | Serving | null;
  dislikedTrackIds: string[];
  favorites: (Track | Serving)[];
  genreFilters: string[];
  neuralEffectFilters: EffectLevels[];
  queue: (Track | Serving)[];
  queueHistory: (Track | Serving)[];
  search: {
    state: 'idle' | 'loading' | 'error';
    results: Track[] | Serving[] | null;
    error: string | null;
  };
  sortByFilters: string[];
};

export const initialState: MusicState = {
  activityFilters: [],
  currentTrack: null,
  dislikedTrackIds: [],
  favorites: [],
  genreFilters: [],
  neuralEffectFilters: [],
  queue: [],
  queueHistory: [],
  sortByFilters: [],
  search: {
    state: 'idle',
    results: null,
    error: null,
  },
};

const { actions: musicActions, reducer: musicReducer } = createSlice({
  name: 'Music',
  initialState,
  reducers: {
    addToFavorites(state, action: PayloadAction<Track | Serving>) {
      if (
        state.favorites.some(
          track => getTrackVariationId(track) === getTrackVariationId(action.payload),
        )
      )
        return state;

      return {
        ...state,
        favorites: [action.payload, ...state.favorites],
      };
    },

    addToDislikes(state, action: PayloadAction<Track | Serving>) {
      return {
        ...state,
        dislikedTrackIds: [getTrackId(action.payload), ...state.dislikedTrackIds],
      };
    },

    appendQueue(state, action: PayloadAction<Serving[]>) {
      return {
        ...state,
        queue: [...state.queue, ...action.payload],
      };
    },

    prependQueue(state, action: PayloadAction<Track[] | Serving[]>) {
      const [nextTrack = null, ...queue] = action.payload;

      return {
        ...state,
        currentTrack: nextTrack,
        queue: [...queue, ...state.queue],
      };
    },

    playNextTrack(state) {
      const [nextTrack = null, ...queue] = state.queue;

      return {
        ...state,
        currentTrack: nextTrack,
        queue: queue || [],
        queueHistory: state.currentTrack
          ? [state.currentTrack, ...state.queueHistory]
          : state.queueHistory,
      };
    },

    playPreviousTrack(state) {
      if (!state.queueHistory.length) return state;

      const [currentTrack, ...queueHistory] = state.queueHistory;

      return {
        ...state,
        currentTrack,
        queue: state.currentTrack ? [state.currentTrack, ...state.queue] : state.queue,
        queueHistory,
      };
    },

    receiveDislikes(state, action: PayloadAction<{ dislikedTrackIds: string[] }>) {
      return {
        ...state,
        dislikedTrackIds: action.payload.dislikedTrackIds,
      };
    },

    receiveFavorites(state, action: PayloadAction<MusicState['favorites']>) {
      return {
        ...state,
        favorites: action.payload,
      };
    },

    removeFromFavorites(state, action: PayloadAction<Track | Serving>) {
      if (!state.favorites.some(track => getTrackId(track) === getTrackId(action.payload)))
        return state;

      return {
        ...state,
        favorites: state.favorites.filter(
          track => getTrackId(track) !== getTrackId(action.payload),
        ),
      };
    },

    removeFromDislikes(state, action: PayloadAction<string>) {
      return {
        ...state,
        dislikedTrackIds: state.dislikedTrackIds.filter(id => id !== action.payload),
      };
    },

    receiveQueue(state, action: PayloadAction<Serving[]>) {
      const [nextTrack = null, ...queue] = action.payload;

      return {
        ...state,
        currentTrack: nextTrack,
        queue: queue,
        queueHistory: [],
      };
    },

    setSearchState(state, action: PayloadAction<Partial<MusicState['search']>>) {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    },

    setActivityFilters(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        activityFilters: action.payload,
      };
    },

    setSortByFilters(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        sortByFilters: action.payload,
      };
    },

    setNeuralEffectFilters(state, action: PayloadAction<EffectLevels[]>) {
      return {
        ...state,
        neuralEffectFilters: action.payload,
      };
    },

    setGenreFilters(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        genreFilters: action.payload,
      };
    },

    resetState() {
      return initialState;
    },
  },
});

const persistedMusicReducer = persistReducer(
  {
    key: 'domains/Music/reducer',
    migrate,
    storage,
    version: 1,
    whitelist: [],
  },
  musicReducer,
);

export { musicActions, musicReducer, persistedMusicReducer };
