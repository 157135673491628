import { Text } from '@Cortex';
import styled from 'styled-components';

export const redAura = {
  outer: `linear-gradient(313.03deg, #F34675 34.15%, rgba(243, 70, 117, 0) 92.55%)`,
  inner: `linear-gradient(232.01deg, rgba(255, 0, 31, 0.8) -3.6%, rgba(207, 61, 87, 0.8) 68.92%)`,
};

export const normalAura = {
  outer:
    'linear-gradient(187.11deg, rgba(208, 26, 234, 0.8) 19.55%, rgba(44, 168, 234, 0.8) 87.99%)',
  inner:
    'linear-gradient(240.5deg, rgba(208, 26, 234, 0.8) 16.31%, rgba(44, 168, 234, 0.8) 67.25%)',
};

// the z-indexes ensure that the aura effect does not overlap the header texts!

export const Container = styled.div`
  border-radius: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  width: 100%;

  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

export const Header = styled.div`
  display: flex;
  font-family: TTNormsPro-Regular;
  margin-bottom: 1.5rem;
  align-items: center;
`;

export const HeaderIcon = styled.div`
  margin-right: 0.6rem;
  position: relative;
  z-index: 1; // see note at top
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1.25rem;
  width: auto;
  align-items: center;
  z-index: 5;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
`;
export const HeaderMainInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderAdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
export const HeaderTextSubtitle = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 1.4rem;
  padding-left: 1.2rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    border: none;
    margin-left: 0;
    padding-left: 0;
  }
`;
export const HeaderBadge = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-weight: 700;
  font-size: 0.7rem;
  background-color: white;
  border-radius: 16px;
  color: black;
  padding: 5px 8px 4px 8px;
`;

export const Logo = styled.img`
  width: clamp(80px, 18vw, 140px);
`;

export const Tag = styled(Text)`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  background: #f34675;
  border-radius: 2.75rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
`;

export const HeaderIconAura = styled.div`
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: -1; // see note at top
`;

export const Children = styled.div`
  text-transform: initial;
  z-index: 2; // see note at top
`;
