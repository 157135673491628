import { FontColors } from '@Cortex';
import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 4rem;
  overflow-y: auto;
  max-height: calc(100vh - 10rem);
  width: calc(100vw - 30rem);
  max-width: 60rem;
  padding-right: 0.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    flex-direction: column;
    width: calc(100vw - 20rem);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    width: calc(100vw - 10rem);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 1rem;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const TrackInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
`;

export const SimilarTracks = styled.div`
  overflow-y: auto;
  padding-right: 0.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    overflow: visible;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const SimilarTracksTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: 700;
  color: ${FontColors.White};
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

export const ActivitiesSection = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8px;
`;

export const TrackInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

export const TrackImageContainer = styled.div`
  position: relative;
`;

export const TrackImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 0.5rem;
  box-shadow: 0px 12px 28px 0px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  aspect-ratio: 1 / 1;
`;

export const NewTag = styled.div`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: white;
  backdrop-filter: blur(10px);
  padding: 4px 4px 3px 4px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 10px;
  color: ${FontColors.Black};
  font-weight: 700;
  letter-spacing: 1px;
  margin: auto;
`;

export const TrackDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TrackDetailsTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: 700;
  color: ${FontColors.White};
  font-size: 2rem;
  line-height: 36px;
  letter-spacing: -0.32px;
`;

export const TrackDetailsNeuralEffect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  color: ${FontColors.White};
`;

export const TrackDetailsGenreAndBPM = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
`;

export const TrackActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-top: 2rem;
  justify-content: space-around;
`;

export const TrackAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
`;

export const TrackActionButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  opacity: 0.75;
  cursor: pointer;
  outline: none;
  width: fit-content;
`;

export const Divider = styled.div`
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin-top: 2rem;
`;

export const TabBarContainer = styled.div`
  display: flex;
`;

export const TabItem = styled.div<{ isActive: boolean }>`
  flex: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${props => (props.isActive ? FontColors.White : FontColors.WhiteTransparent)};
  font-weight: ${props => (props.isActive ? '500' : '400')};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${props => (props.isActive ? '#ffff' : 'transparent')};
  }
`;

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Image = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const FadeInWrapper = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  animation: fadeInDown 0.3s ease-in-out;
  display: ${props => (props.isActive ? 'block' : 'none')};

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const GradientContainerStyleTransparent = {
  border: '1px solid rgba(255, 255, 255, 0.2)',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.01) 100%)',
  boxShadow: '0px -8px 24px 0px rgba(0, 0, 0, 0.30)',
};
