import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  TrackInformationCard,
} from '@Music';
import { getTrackVariationId } from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useRecentTracks } from '../../../../../../api/modules/RecentTracks';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { removeFromFavorites, addToFavorites } from '../../../../actions';
import * as S from './Recent.styles';
import { getTrackRelatedActivities } from '../../../../lenses/getTrackRelatedActivities';
import { uiSliceActions } from '../../../../../../reducers/uiReducer';

type TrackDictionary = {
  [key: string]: Track | Serving;
};

type Props = {
  onTrackClickMore: (track: Track | Serving) => void;
};

export function Recent(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recent = useRecentTracks();
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);

  if (recent.isLoading) {
    return <S.Loading />;
  }

  if (recent.error) {
    return <TextBold>{recent.error.message}</TextBold>;
  }

  const tracks: Serving[] = [...(recent.data || [])];

  const recentTrackVariationsDictionary = tracks.reduce((acc, track: Serving) => {
    acc[track.trackVariation.id] = track;
    return acc;
  }, {} as TrackDictionary);

  function handleVariationClickPlay(track: Track | Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromRecents, {
      trackId: getTrackId(track),
      trackVariationId: getTrackVariationId(track),
      trackName: getTrackName(track),
    } as any);

    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Recents,
      trackName: getTrackName(track),
    });
  }

  return (
    <>
      <S.Title>recently played</S.Title>
      <S.CardsContainer>
        {Object.values(recentTrackVariationsDictionary).map(track => {
          const isFavorited = favorites.some(favorite => {
            return getTrackId(favorite) === getTrackId(track);
          });

          const trackId = getTrackId(track);

          return (
            <S.CardContainer
              key={getTrackVariationId(track) || trackId}
              data-testid="recentTrackItem"
            >
              <TrackInformationCard
                hasMultipleNELs={getTrackHasMultipleNELs(track)}
                imageUrl={getTrackImageUrl(track)}
                isFavorited={isFavorited}
                isNewlyCreated={getTrackIsNewlyCreated(track)}
                moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
                neuralEffectLevel={getTrackNeuralEffectLevel(track)}
                relatedActivities={getTrackRelatedActivities(track)}
                subtitle={getTrackGenreName(track)}
                title={getTrackName(track)}
                onAddToFavorites={() => {
                  dispatch(addToFavorites({ origin: TrackActionOrigins.Recents, track }));
                }}
                onClick={() => handleVariationClickPlay(track)}
                onClickMore={() => props.onTrackClickMore(track)}
                onRemoveFromFavorites={() =>
                  dispatch(
                    removeFromFavorites({
                      track,
                    }),
                  )
                }
              />
            </S.CardContainer>
          );
        })}
      </S.CardsContainer>
    </>
  );
}
