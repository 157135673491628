import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  SuccessModalType,
  ModalType,
  UiStateType,
  SessionSidebarContent,
  ExplorePanelTab,
} from '../types';

const initialState: UiStateType = {
  isPlayerAnimationEnabled: true,
  isReferAFriendDismissed: false,
  playerVolume: 0.8,
  modalType: null,
  sessionFinished: false,
  sessionSidebarContent: null,
  explorePanelTab: null,
  successModal: {
    iconSrc: undefined,
    isDismissable: true,
    isOpen: false,
    title: null,
    description: null,
    actions: [],
  },
  shouldShowProTipsModal: false,
  shouldShowNELTipsModal: false,
  shouldShowFocusPersonalizationQuiz: false,
  shouldShowFavoriteModal: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // Sets the modal to display
    setModalType: (state, action: PayloadAction<ModalType | null>) => {
      state.modalType = action.payload;
      return state;
    },

    setSessionFinished: (state, action: PayloadAction<boolean>) => {
      state.sessionFinished = action.payload;
      return state;
    },

    setVolume: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        playerVolume: action.payload,
      };
    },

    setSuccessModal: (state, action: PayloadAction<Partial<SuccessModalType>>) => {
      state.successModal = {
        ...state.successModal,
        ...action.payload,
      };
    },

    setShouldShowProTipsModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowProTipsModal = action.payload;
    },

    setShouldShowNELTipsModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowNELTipsModal = action.payload;
    },

    setShouldShowFocusPersonalizationQuiz: (state, action: PayloadAction<boolean>) => {
      state.shouldShowFocusPersonalizationQuiz = action.payload;
    },

    setShouldShowFavoriteModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowFavoriteModal = action.payload;
    },

    setSessionSidebarContent: (
      state,
      action: PayloadAction<{
        sessionSidebarContent: SessionSidebarContent | null;
        origin?: string;
      }>,
    ) => {
      state.sessionSidebarContent = action.payload.sessionSidebarContent;
      state.sessionSidebarContentOrigin = action.payload.origin;
    },

    setExploreModalTab: (state, action: PayloadAction<ExplorePanelTab | null>) => {
      state.explorePanelTab = action.payload;
    },

    setPlayerAnimationState: (state, action: PayloadAction<boolean>) => {
      state.isPlayerAnimationEnabled = action.payload;
    },

    setIsReferAFriendDismissed: (state, action: PayloadAction<boolean>) => {
      state.isReferAFriendDismissed = action.payload;
    },
  },
});

export const uiSliceActions = uiSlice.actions;
export default uiSlice.reducer;

const persistedUIReducer = persistReducer(
  {
    key: 'UIReducer',
    storage,
    whitelist: ['playerVolume', 'isPlayerAnimationEnabled', 'isReferAFriendDismissed'],
  },
  uiSlice.reducer,
);
export { persistedUIReducer };
