import { SuccessModal } from '@Cortex';
import { RedemptionCodeForwarder, useRedirectUserToPaymentOnPromotion } from '@Memberships';
import { AudioControlBar, DeepLinkRedirector, useIntegrateWithMediaSessionAPI } from '@Music';
import { Onboarding } from '@Onboarding';
import { UpgradeStripeSubscriptionModal, PaywallModal, VerifyEmailModal } from '@Payment';
import { useChimeOnPomodoroPhaseChange, useEnableTimer } from '@Timer';
import { PROFILE_ROUTE, Refer, Settings, userActions } from '@User';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, Routes, Navigate, useNavigationType } from 'react-router-dom';
import styled from 'styled-components';
import * as Braze from '@braze/web-sdk';

import * as appActions from '../actions/app';
import * as uiActions from '../actions/ui';
import AppLoading from '../components/appLoading';
import AudioPlayerDynamic from '../components/audioPlayer/audioPlayerDynamic';
import { Session } from '../components/session';
import { DYNAMIC_PLAYER_ACTIVITY_PATH } from '../constants';
import { IntentionsHandler } from '../domains/Intentions/IntentionsHandler';
import { IntentionsPlayTrackHandler } from '../domains/Intentions/IntentionsPlayTrackHandler';
import { FastSubscriptionModal } from '../domains/Payment/components/FastSubscriptionModal';
import { RenewSubscriptionModal } from '../domains/Payment/components/RenewSubscriptionModal';
import { SubscriptionSuccessModal } from '../domains/Payment/components/SubscriptionSuccessModal';
import { featureFlagsState } from '../domains/Utils/featureFlagsState';
import { useHandlePageTitle } from '../hooks/useHandlePageTitle';
import Profile from '../modals/profileModal/profile';
import { RootReducerType } from '../reducers';
import { Analytics } from '../utils/analytics';
import { setModalElement } from '../utils/setModalElement';
import JoinTeamHandler from './JoinTeamHandler';
import NotFound from './NotFound';
import { ProfileScreen } from './Profile';
import { Home } from './Home';
import JoinTeam from './JoinTeam';
import { OnboardingPayment } from './signupPayment';

import { FirstSessionModal } from '../domains/Onboarding/FirstSessionModal';
import { IntentionsShowFavoritesHandler } from '../domains/Intentions/IntentionsShowFavoritesHandler';
import { WhatsNew } from '../domains/WhatsNew/components/WhatsNew/WhatsNew';
import { fetchRecentSession } from '../actions/recentSessions';
import { fetchRecommendedActivities } from '../actions/recommendedActivities';
import { Activation } from '../domains/Activation/Activation.container';

const Wrapper = styled.div`
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

// Parent container that fades in the pages
// All page animations can be applied here
const AnimatedContainer = styled.div`
  width: inherit;
  height: inherit;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.6s;
`;

setModalElement();

function Main() {
  useRedirectUserToPaymentOnPromotion();
  const { status } = useSelector((state: RootReducerType) => state.app);
  const { info } = useSelector((state: RootReducerType) => state.user);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigationType = useNavigationType();

  // tmp until DA is fully rollout
  useEffect(() => {
    Analytics.setUserProperties({
      // @ts-ignore
      web_production_dynamic_activities: true,
    });
  }, []);

  useEffect(() => {
    dispatch(appActions.init());
    Braze.subscribeToInAppMessage(inAppMessage => {
      if (inAppMessage.extras.noRender && inAppMessage.extras.noRender === 'true') {
        if (inAppMessage.extras.webAction === 'renewSubscription') {
          dispatch(uiActions.setModal('renewSubscription'));
        }
        return;
      }
      Braze.showInAppMessage(inAppMessage);
    });

    Braze.openSession();
  }, [dispatch]);

  useEffect(() => {
    if (info?.id) {
      // sometimes user info hasn't finished loading here so we need to check.
      dispatch(userActions.getSessionPreferences());
      dispatch(userActions.getUserPreferences());
      dispatch(fetchRecentSession());
      dispatch(fetchRecommendedActivities());
    }
  }, [dispatch, info?.id]);

  if (status === 'loading') {
    return <AppLoading />;
  }

  if (status === 'idle') {
    return <div />;
  }

  return (
    <>
      <Wrapper>
        <Routes>
          <Route element={<Navigate replace to="/" />} path="/signup" />
          <Route element={<Navigate replace to="/" />} path="/signin" />

          <Route
            element={
              <WithAnimatedTransition>
                <Home />
              </WithAnimatedTransition>
            }
            index
          />

          <Route element={<Session />} path={DYNAMIC_PLAYER_ACTIVITY_PATH}>
            <Route element={<AudioControlBar />} index />
          </Route>
          <Route
            element={
              <WithAnimatedTransition>
                <ProfileScreen />
              </WithAnimatedTransition>
            }
            path={PROFILE_ROUTE}
          >
            <Route element={<Profile />} path={`account`} />
            <Route element={<Settings />} path={`settings`} />
            <Route element={<Refer />} path={`refer`} />
            <Route element={<WhatsNew />} path={`whats-new`} />
          </Route>
          <Route
            element={
              <WithAnimatedTransition>
                <Onboarding />
              </WithAnimatedTransition>
            }
            path="welcome"
          />
          <Route
            element={
              <WithAnimatedTransition>
                <OnboardingPayment />
              </WithAnimatedTransition>
            }
            path="payment"
          />

          {/* TODO- this is duplicated in app and they must change in sync */}
          <Route
            element={<Navigate replace={true} to="/user/account" />}
            path="/intentions/account"
          />
          <Route element={<DeepLinkRedirector />} path="/intentions/justAddedMusic" />
          <Route element={<Navigate replace={true} to="/" />} path="/intentions/home" />
          <Route element={<RedemptionCodeForwarder />} path="intentions/redeem/:redemptionCode" />
          <Route element={<IntentionsPlayTrackHandler />} path="/intentions/share" />
          <Route element={<OnboardingPayment />} path="/intentions/pay" />
          <Route element={<IntentionsHandler />} path="/intentions/:token" />
          <Route element={<IntentionsShowFavoritesHandler />} path="/intentions/focusFavorites" />
          <Route
            element={<Navigate replace={true} to="/user/settings" />}
            path="/intentions/settings"
          />
          <Route
            element={<JoinTeamHandler isAuthenticated={true} />}
            path="/intentions/join/:teamId"
          />
          <Route element={<JoinTeamHandler isAuthenticated={true} />} path="/join/:teamId" />
          <Route element={<JoinTeam />} path="/joined/:teamId" />
          <Route element={<NotFound isAuthenticated={true} />} path="*" />
          <Route element={<Activation />} path="/activate" />
        </Routes>
      </Wrapper>

      <AudioPlayerDynamic
        currentRoute={location.pathname}
        shouldAttemptAutoPlay={navigationType === 'PUSH'}
      />

      <PaywallModal />
      <VerifyEmailModal />
      <UpgradeStripeSubscriptionModal />
      <SubscriptionSuccessModal />
      <RenewSubscriptionModal />
      <FastSubscriptionModal />
      <SuccessModal />
      <FirstSessionModal />
      <GlobalHooks />
    </>
  );
}

export default Main;

function GlobalHooks() {
  useIdentifyUserToLaunchDarkly();
  useChimeOnPomodoroPhaseChange();
  useEnableTimer();
  useIntegrateWithMediaSessionAPI();
  useHandlePageTitle();
  return null;
}

function useIdentifyUserToLaunchDarkly() {
  const client = useLDClient();
  const { info } = useSelector((state: RootReducerType) => state.user);

  useEffect(() => {
    if (client && info?.id) {
      client.identify(
        {
          key: info.id,
          email: info.email,
        },
        undefined,
        err => {
          if (err) {
            featureFlagsState.setState({ error: err, isSettled: true });
          } else {
            featureFlagsState.setState({ isReceived: true, isSettled: true });
          }
        },
      );
    }
  }, [client, info?.id, info?.email]);
}

function WithAnimatedTransition(props: { children: ReactNode }) {
  const location = useLocation();

  return <AnimatedContainer key={location.key}>{props.children}</AnimatedContainer>;
}
