import { NOOP } from '@Globals';
import { OutsideClickDetector } from '@Utils';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { ProfileButton } from './ProfileButton';
import * as S from './ProfileWidget.styles';
import { MenuItemIcons, MenuItemWithFill } from '@Cortex';

export type MenuItemType = {
  testid?: string;
  urlPath?: string | null;
  label: string;
  hasTopDivider?: boolean;
  onClick?: () => void;
  href?: string;
};

export interface Props {
  hasNotification?: boolean;
  isActive?: boolean;
  menuItems?: (MenuItemType & { icon: MenuItemIcons })[];
  onClick?: () => void;
  onOutsideClick?: () => void;
}

export const ProfileWidgetDisplay = ({
  hasNotification = false,
  isActive = false,
  menuItems = [],
  onClick = NOOP,
  onOutsideClick = NOOP,
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container>
      <OutsideClickDetector onOutsideClick={onOutsideClick}>
        <ProfileButton hasNotification={hasNotification} isActive={isActive} onClick={onClick} />

        <Transition in={isActive} mountOnEnter nodeRef={menuRef} timeout={0} unmountOnExit>
          {state => {
            return (
              <S.MenuContainer ref={menuRef} animationState={state}>
                <S.MenuItemContainer>
                  {menuItems.map((item, index) => {
                    return (
                      <MenuItemWithFill
                        key={item.label}
                        hasTopDivider={item.hasTopDivider}
                        icon={item.icon}
                        isActive={location.pathname === item.urlPath}
                        testid={item.testid}
                        onClick={item.onClick}
                      >
                        {item.href ? (
                          <a href={item.href} rel="noopener noreferrer" target="_blank">
                            {item.label}
                          </a>
                        ) : (
                          item.label
                        )}
                      </MenuItemWithFill>
                    );
                  })}
                </S.MenuItemContainer>
              </S.MenuContainer>
            );
          }}
        </Transition>
      </OutsideClickDetector>
    </S.Container>
  );
};
