import { TransitionStatus } from 'react-transition-group';
import { Assets } from '../../../utils/assets';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div<{ animationState: TransitionStatus }>`
  position: absolute;
  z-index: 999;
  right: 0;
  padding: 1rem 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(-0.5rem)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(-0.5rem)`;
    }
  }};

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.5;
      case 'exited':
        return 0;
    }
  }};
`;

export const MenuItemContainer = styled.div`
  width: 15.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #0d0c11;
  background-image: url(${Assets.images.blurBg.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  border-radius: 1.25rem;
  padding: 0.5rem;
`;

export const MenuItem = styled.div`
  flex-direction: column;
  padding: 0.5rem 1rem;
  margin: 0.125rem 0;
  cursor: pointer;
  border-radius: 0.5rem;
  white-space: nowrap;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(34px);
  }
`;

export const Divider = styled.div`
  margin: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const MenuText = styled.a`
  color: white;
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
`;
