import { Button, ButtonVariantSizes, Panel, Icon } from '@Cortex';
import ReactModal from 'react-modal';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { Assets } from '../../../../utils/assets';
import * as S from './FavoriteModal.styles';
import { Variants } from '../../../../domains/Cortex/components/Button/Button';
import CrossIcon from '../../../../assets/images/close_icon.svg';

export type Props = {
  isOpen: boolean;
  isCopied: boolean;
  onClose: () => void;
  onShare: () => void;
  title: string;
};

export function FavoriteModalDisplay(props: Props) {
  const { title, isCopied, onShare } = props;
  return (
    <ReactModal closeTimeoutMS={CLOSE_TIMEOUT_MS} isOpen={props.isOpen} style={baseStyles}>
      <S.Overlay>
        <S.ModalWrapper>
          <Panel
            padding="2rem 0"
            style={{
              background:
                'linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'visible',
            }}
          >
            <S.ModalContent>
              <S.CloseButtonContainer>
                <S.CloseButton
                  data-cy="favoriteModalCloseButton"
                  data-testid="favoriteModalCloseButton"
                  src={CrossIcon}
                  onClick={props.onClose}
                />
              </S.CloseButtonContainer>
              <S.Image
                alt={Assets.images.heartConfetti.alt}
                src={Assets.images.heartConfetti.url}
              />
              <S.AuraImage alt={Assets.images.purpleAura.alt} src={Assets.images.purpleAura.url} />
              <S.TextContainer>{title && <S.Title>{title}</S.Title>}</S.TextContainer>
              <S.Description>
                Find your favorite tracks anytime in the
                <Icon
                  isSquare
                  size={16}
                  src={Assets.icons.explore.url}
                  style={{ marginLeft: '0.3rem' }}
                />
                <S.DescriptionBold>Explore</S.DescriptionBold>section.
              </S.Description>
              <S.ShareText>Share it with your friends.</S.ShareText>
              <S.ButtonWrapper>
                <Button
                  data-testid="copyFavoritesButton"
                  isFullWidth={true}
                  keepTextCase={true}
                  size={ButtonVariantSizes.Small}
                  variant={Variants.Transparent}
                  onClick={onShare}
                >
                  <S.ButtonContent>
                    <img alt={Assets.icons.link.alt} src={Assets.icons.link.url} width="18px" />
                    {isCopied ? (
                      <S.ButtonText>Copied!</S.ButtonText>
                    ) : (
                      <S.ButtonText>Copy</S.ButtonText>
                    )}
                  </S.ButtonContent>
                </Button>
              </S.ButtonWrapper>
            </S.ModalContent>
          </Panel>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
