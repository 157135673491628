import { createSelector } from '@reduxjs/toolkit';
import { RootReducerType } from 'src/reducers';
import { getTrackDynamicMentalStateId } from './getTrackDynamicMentalStateId';

const getMusicFavorites = (state: RootReducerType) => state.music.favorites;
const getSessionDynamicActivity = (state: RootReducerType) =>
  state.sessionManager.sessionDynamicActivity;

export const getDynamicFavorites = createSelector(
  [getMusicFavorites, getSessionDynamicActivity],
  (favorites, sessionDynamicActivity) => {
    return favorites.filter(track => {
      return getTrackDynamicMentalStateId(track) === sessionDynamicActivity?.mentalState.id;
    });
  },
);
